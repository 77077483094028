import React from 'react';
import { useTranslation } from 'react-i18next';
import './pagination.css';
import { ButtonWithIcon } from '../button';
import { ArrowLeft, ArrowRight } from '../../assets/icons/icons-arrow';

export const Pagination = ({
  perPage,
  setPerPage,
  count,
  startIndex,
  endIndex,
  setStartIndex,
  setEndIndex,
  sticky,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`o-pagination ${sticky ? 'o-pagination__sticky' : ''}`}>
      <div>
        <ButtonWithIcon
          className={perPage === 10 ? 'active' : ''}
          onClick={() => {
            setPerPage(10);
            setStartIndex(1);
            setEndIndex(10);
          }}
        >
          10
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={count <= 10}
          className={perPage === 25 ? 'active' : ''}
          onClick={() => {
            setPerPage(25);
            setStartIndex(1);
            setEndIndex(25);
          }}
        >
          25
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={count <= 25}
          className={perPage === 50 ? 'active' : ''}
          onClick={() => {
            setPerPage(50);
            setStartIndex(1);
            setEndIndex(50);
          }}
        >
          50
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={count <= 50}
          className={perPage === 100 ? 'active' : ''}
          onClick={() => {
            setPerPage(100);
            setStartIndex(1);
            setEndIndex(100);
          }}
        >
          100
        </ButtonWithIcon>
        <span className="ml-2 hidden-mobile">{t('global:perPage')}</span>
      </div>
      <div>
        <span className="mr-2">
          {startIndex}-{endIndex > perPage ? endIndex : endIndex > count ? count : perPage}{' '}
          {t('global:of')} {count}
        </span>
        <ButtonWithIcon
          disabled={startIndex - perPage < 0}
          onClick={() => {
            if (endIndex - startIndex + 1 === perPage) {
              setStartIndex(startIndex - perPage);
              setEndIndex(endIndex - perPage);
            } else {
              setEndIndex(startIndex - 1);
              setStartIndex(startIndex - perPage);
            }
          }}
        >
          <ArrowLeft />
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={count - endIndex <= 0}
          onClick={() => {
            if (count - endIndex > perPage) {
              setStartIndex(startIndex + perPage);
              setEndIndex(endIndex + perPage);
            } else {
              setStartIndex(endIndex + 1);
              setEndIndex(count);
            }
          }}
        >
          <ArrowRight />
        </ButtonWithIcon>
      </div>
    </div>
  );
};
