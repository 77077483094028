import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../../components/dropdown';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateClientMembershipReq } from '../../../../../api/client/clientmembership';
import { getPackageTypeReq } from '../../../../../api/packageType/getPackageType';

const ConfirmationUpdateClientMembership = ({ userID, membership, updateClientMembershipModal, setUpdateClientMembershipModal, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [membershipTypeListData, setMembershipTypeListData] = useState([]);
  const [membershipTypeList, setMembershipTypeList] = useState([]);
  const [initialValue, setInitialValue] = useState();
  const [membershipTypeChoosen, setMembershipTypeChoosen] = useState();
  const [billingTypeChoosen, setBillingTypeChoosen] = useState();
  const [billingTypeList, setBillingTypeList] = useState([]);

  useEffect(() => {
    //for dropdowns
    if (updateClientMembershipModal) {
      setLoading(true);
      getPackageTypeReq().then((res) => {
        setMembershipTypeListData(res);
        let membershipTypes = [];
        res.map((item) => membershipTypes.push({ label: item.membershipTypeId + ' - ' + item.name, value: item.membershipTypeId }));
        setMembershipTypeList(membershipTypes);
        setMembershipTypeChoosen({ label: membership?.type?.membershipTypeId + ' - ' + membership?.type?.name, value: membership?.type?.membershipTypeId });

        let billingTypes = [];
        membership?.type?.billing_types?.map((item) => billingTypes.push({ label: item.billing_type + ' - ' + item.price + ' €', value: item.billing_type }));
        setBillingTypeList(billingTypes);

        setBillingTypeChoosen({ label: membership?.billing_type, value: membership?.billing_type });
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [updateClientMembershipModal]);

  const updateClientMembershipHandler = async (data) => {
    setLoading(true);
    let formData = {
      membershipTypeId : data.membershipTypeId.value,
      billing_type : data.billing_type.value,
      consents: {
          cgu: true,
          legals: true,
          treezor_privacy: true,
          treezor_contract: true,
          cgu_card: true,
          info_email: true
      }
    }
    let status = await updateClientMembershipReq(userID, formData);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const membershipTypeChange = (membershipTypeSelected) => {
    setMembershipTypeChoosen(membershipTypeSelected);
    refreshBillingTypeList(membershipTypeSelected);
  }

  // On récupère les types de fréquence de facturation possible
  const refreshBillingTypeList = (membershipTypeChoosen) => {
    if (membershipTypeChoosen) {
      let membership = membershipTypeListData.find((forfait) => {
        return forfait?.membershipTypeId === membershipTypeChoosen.value;
      });
      let billingTypes = [];
      setBillingTypeChoosen(null);
      if (membership.billing_types) {
        membership.billing_types.map((item) => billingTypes.push({ label: item.billing_type + ' - ' + item.price + ' €', value: item.billing_type }));
        setBillingTypeList(billingTypes);
      }
    } else {
      setBillingTypeList([]);
    }
  }

  const close = () => {
    setUpdateClientMembershipModal(false);
  }

  const UpdateClientMembershipInitialValues = {
    membershipTypeId: membershipTypeChoosen,
    billing_type: billingTypeChoosen,
  };
  const UpdateClientMembershipSchema = Yup.object().shape({
    membershipTypeId: Yup.object().required('Requis'),
    billing_type: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updateClientMembershipModal} toggle={setUpdateClientMembershipModal}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateClientMembershipInitialValues}
      validationSchema={UpdateClientMembershipSchema}
      onSubmit={(values) => {
        console.log('Update payout limits' + values);
        updateClientMembershipHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification du type de forfait du client </div>
          {loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> :
          <Field
            name="membershipTypeId"
            className="mt-2"
            label="Forfait *"
            placeholder="Forfait *"
            options={membershipTypeList}
            component={Dropdown}
            onChange={membershipTypeChange}
          />}
          {loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> :
          <Field
            name="billing_type"
            className="mt-2"
            label="Fréquence de facturation *"
            placeholder="Fréquence de facturation *"
            options={billingTypeList}
            component={Dropdown}
          />}
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateClientMembership;
