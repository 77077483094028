import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PackageFeatureSchema, PackageFeatureInitialValues } from '../packageFeature.utils';
import { detailPackageFeatureReq } from '../../../../api/packageFeature/detailPackageFeature';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../assets/icons/loading.svg';

const ShowPackageFeature = ({ showModal, setShowModal, selectedID, config }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [status, setStatus] = useState('loading');
  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      detailPackageFeatureReq(selectedID)
        .then((res) => {
          setInitialValue({
            name: res.name,
            type:
              config.featureTypes &&
              res.type &&
              config.featureTypes.filter((e) => e.value === res.type)[0],
            domain:
              config.featureDomains &&
              res.type &&
              config.featureDomains.filter((e) => e.value === res.domain)[0],
          });
        })
        .finally(() => {
          setStatus('done');
        });
    }
  }, [selectedID, config]);
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : PackageFeatureInitialValues}
        validationSchema={PackageFeatureSchema}
      >
        {() => (
          <Form>
            <div className="o-title text-center">{t('feature:detail_feature')}</div>
            {status === 'loading' ? (
              <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="name"
                  className="mt-5"
                  label={t('feature:name')}
                  placeholder={t('feature:name_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />

                <Field
                  name="type"
                  label={t('feature:type')}
                  placeholder={t('feature:type_placeholder') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <Field
                  name="domain"
                  label={t('feature:domain')}
                  placeholder={t('feature:domain_placeholder') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <div className="d-flex flex-column mt-5">
                  <Button
                    btnType="outline"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ShowPackageFeature;
