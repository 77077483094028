import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import IconLoading from '../../../assets/icons/loading.svg';
import IconCancel from '../../../assets/icons/cancel.png';
import IconCheck from '../../../assets/icons/check.svg';
import IconDoc from '../../../assets/icons/doc.svg';
import { detailClientReq } from '../../../api/client/detailClient';
import PermissionsContext from '../../../contexts/permissionContext';

import ConfirmationDeleteStatement from './modals/statement/confirmationDelete';
import ConfirmationRegenerateStatement from './modals/statement/confirmationRegenerate';

import {
  clientStatementsReq, downloadStatementReq
} from '../../../api/client/statements';
import {
  tableStatementsHeader,
  renderStatementsTable,
} from './statements.utils';

const ClientStatements = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [statementsDetailTableData, setStatementsTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allStatements, setAllStatements] = useState([]);
  const [selectedStatements, setSelectedStatements] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingIdentification, setLoadingIdentification] = useState('loading');
  const [status, setStatus] = useState('loading');

  const [selectedItem, setSelectedItem] = useState();
  const [deleteStatementOpen, setDeleteStatementOpen] = useState(false);
  const [regenerateStatementOpen, setRegenerateStatementOpen] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshData();
      })
      .finally(() => setStatus('done'));
  }, []);

  useEffect(() => {
    const statementsDetail = allStatements.find((x) => x.statementId === selectedID);
    setSelectedStatements(statementsDetail);
    setSelectedItem('statement');
  }, [selectedID]);

  const refreshData = () => {
    setLoadingIdentification('loading');
    clientStatementsReq(id).then((res) => {
      setAllStatements(res);
      setStatementsTableData(renderStatementsTable(res));
      setSelectedStatements(null);
      setSelectedItem(null);
    })
    .finally(() => setLoadingIdentification('done'));
  };

  const refreshDataClient = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
  };

  const getStatementPdf = async (statementId) => {
    if (statementId) {
      setLoading(true);
      const res = await downloadStatementReq(statementId);
      setLoading(false);
    }
  }

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Relevés de compte
      </Fragment>
    );
  }

  const renderSelectedStatementsActions = (selectedStatements) => {
    return(
      <div className="o-information-detail__actions">
        { permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
           <Button btnType="outline" onClick={() => getStatementPdf(selectedStatements.statementId)}>
            {
               loading ?
                 <img className="mr-2" src={IconLoading} height="25" alt="" /> :
                 <img className="mr-2" height="14" src={IconCheck} alt="" />
             }
             Download Relevé
           </Button>
        ) : ('')
        }
        { permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
           <Button btnType="outline" onClick={() => setDeleteStatementOpen(true)}>
             <img className="mr-2" height="14" src={IconCancel} alt="" />
             Supprimer le Relevé
           </Button>
        ) : ('')
        }
        { permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
           <Button btnType="outline" onClick={() => setRegenerateStatementOpen(true)}>
             <img className="mr-2" height="14" src={IconDoc} alt="" />
             Regénerer le Relevé
           </Button>
        ) : ('')
        }
        <ConfirmationDeleteStatement
          statementId={selectedStatements?.statementId}
          deleteStatementOpen={deleteStatementOpen}
          setDeleteStatementOpen={setDeleteStatementOpen}
          refreshData={refreshData}
        />
        <ConfirmationRegenerateStatement
          statementId={selectedStatements?.statementId}
          regenerateStatementOpen={regenerateStatementOpen}
          setRegenerateStatementOpen={setRegenerateStatementOpen}
          refreshData={refreshData}
        />
      </div>
      )
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
        {status === 'loading' ? (
          <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
        ) : (
          <div className="o-information">
            <div className="my-3">
              <b>Informations client</b>
            </div>
            <div className="o-information-row">
              <div className="o-information-column">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>UserId | TrzUserId</div>
                    <div>{detailData?.userId} | {detailData?.trzUserId}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Prénom</div>
                    <div className="ml-2">{detailData?.firstname ? detailData?.firstname : '-'}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Nom</div>
                    <div className="ml-2">{detailData?.lastname ? detailData?.lastname : '-'}</div>
                  </div>
                </div>
              </div>
              <div className="o-information-column">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>Date de création</div>
                    <div>{detailData?.created ? moment(detailData?.created).format('DD/MM/YYYY HH:mm') : '-'}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Dernière mise à jour</div>
                    <div>{detailData?.updated ? moment(detailData?.updated).format('DD/MM/YYYY HH:mm') : '-'}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Date de validation</div>
                    <div>{detailData?.validation_date ? moment(detailData?.validation_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
          <Table
            columns={tableStatementsHeader}
            tableData={statementsDetailTableData}
            status={loadingIdentification}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => 'Historique des relevés de compte'}
            contain
          />
        </div>
        <div className="o-viewer">
        {selectedID && selectedItem === 'statement' && (
          <div>
            <div>
              <div className="mt-5">
                <b>Informations Relevé bancaire</b>
              </div>
              <hr />
              <div className="d-flex">
                <div className="mt-2">
                  <div>
                    <div><b>Identification ID</b></div>
                    <div>{selectedStatements?.statementId}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Download Path</b></div>
                    <div>{selectedStatements?.downloadGAEPath}</div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <b>Actions</b>
                <hr />
                {renderSelectedStatementsActions(selectedStatements)}
              </div>
            </div>
          </div>
        )}
        {!selectedID && (<Logo className="o-viewer-logo"/>)}
        </div>
      </div>
    </div>
  );
};

export default ClientStatements;
