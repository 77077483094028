import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../../components/header';
import Table from '../../../../components/table';
import TablePage from '../../../../components/tablepage';
import Button from '../../../../components/button';
import CreditCard from '../../../../components/creditCard';
import TransactionCard from '../../../../components/transaction';
import CreditCardActions from '../../../../components/creditCard/cardActions';
import Logo from '../../../../components/logo';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import IconHand from '../../../../assets/icons/hand.svg';
import IconCalc from '../../../../assets/icons/calculator.svg';
import IconWarning from '../../../../assets/icons/triangle.svg';
import IconRenew from '../../../../assets/icons/renew.svg';
import IconEdit from '../../../../assets/icons/edit.svg';
import IconDollarRenew from '../../../../assets/icons/dollar-renew.svg';
import IconDoc from '../../../../assets/icons/doc.svg';
import IconLoading from '../../../../assets/icons/loading.svg';
import ConfirmationBlockCard from '../modals/card/confirmationBlock';
import ConfirmationForcePin from '../modals/card/confirmationForcePin';
import ConfirmationOrderCard from '../modals/card/confirmationOrderCard';
import ConfirmationOrderVirtualCard from '../modals/card/confirmationOrderVirtualCard';
import ConfirmationLostStolenCard from '../modals/card/confirmationLostStolen';
import ConfirmationActivateCard from '../modals/card/confirmationActivate';
import ConfirmationUnlockPinCard from '../modals/card/confirmationUnlockPin';
import ConfirmationUpdateCardLimits from '../modals/card/confirmationUpdateCardLimits';
import ConfirmationUpdateCardOptions from '../modals/card/confirmationUpdateCardOptions';
import RefundTransactionModal from '../modals/refundTransactionModal';
import { handleRib, generateRibReq } from '../../../../api/client/wallet/rib';
import {cardListReq, cardDetailReq} from '../../../../api/client/card/card'
import { detailClientReq } from '../../../../api/client/detailClient';
import moment from 'moment';
import {
  tableCardHeader,
  renderCardTable,
  tableTransactionsHeader,
  renderTransactionsTable,
} from '../wallet.utils';

const ClientCards = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState();
  const [cardTableData, setCardTableData] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [allCardStatus, setAllCardStatus] = useState('loading');
  const [selectedCard, setSelectedCard] = useState();
  const [refreshCardLoading, setRefreshCardLoading] = useState(false);

  const [orderCardModal, setOrderCardModal] = useState(false);
  const [orderVirtualCardModal, setOrderVirtualCardModal] = useState(false);
  const [activateCardModal, setActivateCardModal] = useState(false);
  const [unlockPinCardModal, setUnlockPinCardModal] = useState(false);
  const [updateLimitsCardModal, setUpdateLimitsCardModal] = useState(false);
  const [updateOptionsCardModal, setUpdateOptionsCardModal] = useState(false);
  const [blockCardModal, setBlockCardModal] = useState(false);
  const [forceCodeModal, setForceCodeModal] = useState(false);
  const [lostCardModal, setLostCardModal] = useState(false);
  const [renewCardModal, setRenewCardModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const refreshUser = () => {
    detailClientReq(id, false).then((res) => {
      setUserData(res);
    });
  };

  const refreshCardList = () => {
    setAllCardStatus('loading');
    cardListReq(id).then((res) => {
      setAllCards(res);
      setCardTableData(renderCardTable(res));
    })
    .finally(() => {
      setSelectedID(null);
      setSelectedCard(null);
      setAllCardStatus('done');
    });
  };

  useEffect(() => {
    refreshUser();
    refreshCardList();
  }, []);

  useEffect(() => {
    const card = allCards.find((x) => x.cardId === selectedID);
    setSelectedCard(card);
    setSelectedItem('card');
  }, [selectedID]);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Liste Cartes
      </Fragment>
    );
  }

  const refreshCardDetailInfos = async (cardId) => {
    setRefreshCardLoading(true);
    cardDetailReq(id, cardId, true).then((res) => {
      refreshCardList();
    }).finally(() => {
      setRefreshCardLoading(false);
    });
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div className="o-information-detail__block">
            <div className="o-information-detail__info">
            &nbsp;
            </div>
            { userData?.userId && !userData?.parent && (
            <div className="mx-3 mt-3">
              <Button btnType="outline" onClick={() => setOrderVirtualCardModal(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Commander une carte VIRTUELLE
              </Button>
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => setOrderCardModal(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Commander une carte PHYSIQUE
              </Button>
            </div>
            )}
          </div>
          <Table
              columns={tableCardHeader}
              tableData={cardTableData}
              status={allCardStatus}
              setShowModal={() => false}
              setSelectedID={setSelectedID}
              ContainHeader={() => 'Cartes'}
              contain
            />
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'card' && selectedCard && (
            <div>
              <CreditCard
                cardNumber={selectedCard?.maskedPan}
                name={selectedCard?.embossedName}
                date={selectedCard?.expiryDate}
                cardImageUrl={selectedCard?.image_url}
              />
              <div>
                <div className="mt-3">
                  <b>Informations Cartes
                  <a href={`/dashboard/client/${id}/wallet/${selectedCard?.wallet_id}/card/${selectedCard?.cardId}`} className="pl-2">
                    Voir détail <ArrowRight />
                  </a>
                  </b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div><b>Card ID / Trz Card ID</b></div>
                      <div>{selectedCard?.cardId} / {selectedCard?.trzCardId}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Date de création</b></div>
                      <div>{moment(selectedCard?.created).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Statut</b></div>
                      <div>{selectedCard?.status}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>3 PIN Faux</b></div>
                      <div>{selectedCard?.pinTryExceeds ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div><b>Type</b></div>
                      <div>{selectedCard?.card_type}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>PublicToken</b></div>
                      <div>{selectedCard?.publicToken}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Active</b></div>
                      <div>{selectedCard?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Date destruction</b></div>
                      <div>{selectedCard?.deleted_date ? moment(selectedCard?.deleted_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                    </div>
                  </div>
                </div>
                { selectedCard?.cardLimits && (
                <div className="mt-3">
                  <b>Plafonds Paiements</b>
                  <hr/>
                  <div className="mt-2 mb-2">
                    <div><i>30 derniers jours</i> : {selectedCard?.cardLimits?.limitPaymentMonth}&nbsp;€ ({selectedCard?.cardStats?.totalPaymentMonth.toFixed(2)}&nbsp;€)</div>
                    <div><i>7 derniers jours</i> : {selectedCard?.cardLimits?.limitPaymentWeek}&nbsp;€ ({selectedCard?.cardStats?.totalPaymentWeek.toFixed(2)}&nbsp;€)</div>
                    <div><i>aujourd'hui</i> : {selectedCard?.cardLimits?.limitPaymentDay}&nbsp;€ ({selectedCard?.cardStats?.totalPaymentDay.toFixed(2)}&nbsp;€)</div>
                  </div>
                  <b>Plafonds Retraits</b>
                  <hr/>
                  <div className="mt-2">
                    <div><i>30 derniers jours</i> : {selectedCard?.cardLimits?.limitAtmMonth}&nbsp;€ ({selectedCard?.cardStats?.totalAtmMonth.toFixed(2)}&nbsp;€)</div>
                    <div><i>7 derniers jours</i> : {selectedCard?.cardLimits?.limitAtmWeek}&nbsp;€ ({selectedCard?.cardStats?.totalAtmWeek.toFixed(2)}&nbsp;€)</div>
                    <div><i>aujourd'hui</i> : {selectedCard?.cardLimits?.limitAtmDay}&nbsp;€ ({selectedCard?.cardStats?.totalAtmDay.toFixed(2)}&nbsp;€)</div>
                  </div>
                </div>
                )}
                { selectedCard?.cardOptions && (
                <div className="mt-3">
                  <b>Options de paiement</b>
                  <hr/>
                  <div className="mt-2 mb-2">
                    <div><i>Retrait ATM</i> : {selectedCard?.cardOptions?.optionAtm ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                    <div><i>Paiement étranger</i> : {selectedCard?.cardOptions?.optionForeign ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                    <div><i>Paiement e-commerce</i> : {selectedCard?.cardOptions?.optionOnline ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                    <div><i>Paiment NFC</i> : {selectedCard?.cardOptions?.optionNfc ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                  </div>
                </div>
                )}
                <div className="mt-3">
                  <b>Actions</b>
                  <hr />
                  <CreditCardActions
                    cardDetailData={selectedCard}
                    setBlockCardModal={setBlockCardModal}
                    setLostCardModal={setLostCardModal}
                    setActivateCardModal={setActivateCardModal}
                    setForceCodeModal={setForceCodeModal}
                    setUnlockPinCardModal={setUnlockPinCardModal}
                    setUpdateLimitsCardModal={setUpdateLimitsCardModal}
                    setUpdateOptionsCardModal={setUpdateOptionsCardModal}
                    refreshCardDetailInfos={refreshCardDetailInfos}
                    refreshCardLoading={refreshCardLoading}
                  />
                </div>
                { selectedCard?.shipping_tracking_url && (
                <div className="mt-3">
                  <b>Code suivi</b>
                  <hr />
                  <div>
                  {selectedCard?.shipping_tracking_url}
                  </div>
                  <div className="mt-2">
                    <div><b>Date d'envoi</b></div>
                    <div>{selectedCard?.shipping_date ? moment(selectedCard?.shipping_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                  </div>
                </div>
                )}
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo"/>}
        </div>
      </div>
      <ConfirmationOrderVirtualCard
        userId={id}
        orderVirtualCardModal={orderVirtualCardModal}
        setOrderVirtualCardModal={setOrderVirtualCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationOrderCard
        userId={id}
        card={selectedCard}
        orderCardModal={orderCardModal}
        setOrderCardModal={setOrderCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationBlockCard
        userID={id}
        card={selectedCard}
        blockModal={blockCardModal}
        status={selectedCard?.status}
        setBlockModal={setBlockCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationForcePin
        userID={id}
        card={selectedCard}
        forceCodeModal={forceCodeModal}
        setForceCodeModal={setForceCodeModal}
      />
      <ConfirmationLostStolenCard
        userID={id}
        card={selectedCard}
        lostStolenCardModal={lostCardModal}
        setLostStolenCardModal={setLostCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationActivateCard
        userID={id}
        card={selectedCard}
        activateCardModal={activateCardModal}
        setActivateCardModal={setActivateCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationUnlockPinCard
        userID={id}
        card={selectedCard}
        unlockPinCardModal={unlockPinCardModal}
        setUnlockPinCardModal={setUnlockPinCardModal}
      />
      <ConfirmationUpdateCardLimits
        userID={id}
        card={selectedCard}
        updateLimitsModal={updateLimitsCardModal}
        setUpdateLimitsModal={setUpdateLimitsCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationUpdateCardOptions
        userID={id}
        card={selectedCard}
        updateOptionsModal={updateOptionsCardModal}
        setUpdateOptionsModal={setUpdateOptionsCardModal}
        refreshData={refreshCardList}
      />
    </div>
  );
};

export default ClientCards;
