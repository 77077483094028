import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header';
import Table from '../../../components/table';
import IconLoading from '../../../assets/icons/loading.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import { detailClientReq } from '../../../api/client/detailClient';
import { columnsPackageTable, renderPackageTable } from './client.utils';

export default function ClientPackage() {
  const [detailData, setDetailData] = useState();
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Forfait Client
      </Fragment>
    );
  }
  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setTableData(renderPackageTable(res.membership?.type?.features));
        setDetailData(res);
      })
      .finally(() => setStatus('done'));
  }, []);
  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} disabledSearch />
      <div className="my-3 ml-5">
        <b>Forfait Client</b>
      </div>
      {status === 'loading' ? (
        <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
      ) : (
        <Fragment>
          <div className="o-information">
            <div className="o-information-column">
              <div className="o-card">
                <div className="o-information-detail__item mb-2">
                  <div>Nom</div>
                  <div className="ml-2">
                    {detailData?.membership?.type?.name ? detailData?.membership?.type?.name : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Prix</div>
                  <div className="ml-2">{detailData?.membership?.price}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre Wallets Autorisés</div>
                  <div className="ml-2">{detailData?.membership?.numberOfWalletAllowed}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre Retraits Autorisés</div>
                  <div className="ml-2">{detailData?.membership?.numberOfWithdrawalAllowed}</div>
                </div>
              </div>
            </div>
            <div className="o-information-column">
              <div className="o-card">
                <div className="o-information-detail__item mb-2">
                  <div>Activé</div>
                  <div className="ml-2">{detailData?.membership?.activated ? 'TRUE' : 'FALSE'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Membership ID</div>
                  <div className="ml-2">{detailData?.membership?.membershipId}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre d’enfants</div>
                  <div className="ml-2">{detailData?.membership?.nbChild}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre d’adultes</div>
                  <div className="ml-2">{detailData?.membership?.nbAdult}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="o-content">
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsPackageTable}
              tableData={tableData}
              ContainHeader="Test"
              contain
              notClickableRow={true}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
