import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { addPartnershipReq } from '../../../../api/partnership/partnership';
import { PartnershipSchema, PartnershipInitialValues } from '../partnership.utils';

const AddPartnership = ({ addModal, setAddModal, currentItem }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentItem ? currentItem : PartnershipInitialValues}
        validationSchema={PartnershipSchema}
        onSubmit={(values) => {
          addPartnershipReq(values).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {currentItem ? t('partnership:modify_partnership') : t('partnership:new_partnership')}
            </div>
            <Field
              name="code"
              className="mt-5"
              label={t('partnership:unique_code')}
              placeholder={t('partnership:unique_code_placeholder') + ' *'}
              component={InputFormik}
              disabled={currentItem}
            />
            <Field
              name="name"
              className="mt-5"
              label={t('partnership:descriptive_name')}
              placeholder={t('partnership:descriptive_name_placeholder') + ' *'}
              component={InputFormik}
            />
            <Field
              name="description"
              className="mt-5"
              label={t('partnership:full_description')}
              placeholder={t('partnership:full_description_placeholder') + ' *'}
              component={InputFormik}
            />
            <div className="d-flex flex-column mt-5">
              <Button className="mb-2" type="submit" disabled={!(props.isValid && props.dirty)}>
                {currentItem ? t('global:validate') : t('global:add')}
              </Button>
              <Button
                btnType="outline"
                type="button"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddPartnership;
