import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientBlacklistRemoveMandateReq } from '../../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationRemoveBlacklistMandate = ({ removeBlacklistMandateOpen, setRemoveBlacklistMandateOpen, userId, beneficiaryId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const removeBlacklistMandateHandler = async (id, beneficiaryId, data) => {
    setLoading(true);
    let status = await clientBlacklistRemoveMandateReq(id, beneficiaryId, data);
    setLoading(false);
    setRemoveBlacklistMandateOpen(false)
    if (status === 'success') {
      refreshData()
    }
  }

  const BlacklistMandateInitialValues = {
  };
  const BlacklistMandateSchema = Yup.object().shape({
    mandate: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={removeBlacklistMandateOpen} toggle={setRemoveBlacklistMandateOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={BlacklistMandateInitialValues}
      validationSchema={BlacklistMandateSchema}
      onSubmit={(values) => {
        console.log('Blacklist beneficiary mandat : ' + values);
        removeBlacklistMandateHandler(userId, beneficiaryId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer le rétablissement du mandat, veuillez saisir la référence du mandat à rétablir</div>
          <Field
            name="mandate"
            className="mt-5"
            label="Mandat"
            placeholder="Mandat *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setRemoveBlacklistMandateOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationRemoveBlacklistMandate;
