import { backendApiEndpoint, API_KEY, BASE_URL } from '../constants.js';
import { localStorageKeys } from '../constants.js';
import { toast } from 'react-toastify';
import { CheckAuth, instanceBackendApi } from '../index';
import axios from 'axios';

export async function uploadUserDocumentReq(userId, docTypeId, rectoContent, versoContent) {

  try {
    var formData = new FormData();
    formData.append("docTypeId", docTypeId);
    formData.append("document", rectoContent);
    if (versoContent)
      formData.append("document_verso", versoContent);

    const res = await instanceBackendApi.post(backendApiEndpoint.client_documents.replace('{{userId}}', userId), formData);
    if (res.status === 200) {
      toast('Importation des fichiers de la carte faite !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    return 'fail';
    CheckAuth(error);
  }
}
