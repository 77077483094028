import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../input';
import { Formik, Form, Field } from 'formik';
import Modal from '../modal';
import Button from '../button';
import IconLoading from '../../assets/icons/loading.svg';
import { cardDigitalizationDetailUpdateStatusReq } from '../../api/client/card/carddigitalizations';
import { CardDigitalizationUpdateActions, CardDigitalizationUpdateReasons } from '../../modules/common.utils';
import Dropdown from '../dropdown';

const ConfirmationUpdateStatusCardDigitalization = ({ cardId, cardDigitalizationId, updateCardDigitalizationDetailModal, setUpdateCardDigitalizationDetailModal, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const updateStatusCardDigitalizationHandler = async (data) => {
    setLoading(true);
    cardDigitalizationDetailUpdateStatusReq(cardId, cardDigitalizationId , {
      action : data.action.value,
      reason : data.reason.value,
    }).then((res) => {
      if (res !== 'fail') {
        refreshData();
        close();
      }
    }).finally(() => {
      setLoading(false);
    });

  }

  const close = () => {
    setUpdateCardDigitalizationDetailModal(false);
  }

  const UpdateCardDigitalizationInitialValues = {
  };
  const UpdateCardDigitalizationSchema = Yup.object().shape({
    action: Yup.object().required('Requis'),
    reason: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updateCardDigitalizationDetailModal} toggle={setUpdateCardDigitalizationDetailModal}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateCardDigitalizationInitialValues}
      validationSchema={UpdateCardDigitalizationSchema}
      onSubmit={(values) => {
        updateStatusCardDigitalizationHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
        <div className="o-title text-center mb-4">Veuillez confirmer le changement de status de la digitalization de la carte.</div>
        <Field
            name="action"
            className="mt-2"
            label="Action *"
            placeholder="Action *"
            component={Dropdown}
            options={CardDigitalizationUpdateActions}
          />
        <Field
            name="reason"
            className="mt-2"
            label="Raison *"
            placeholder="Raison *"
            component={Dropdown}
            options={CardDigitalizationUpdateReasons}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateStatusCardDigitalization;
