import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconDoc from '../../../assets/icons/doc.svg';
import IconLoading from '../../../assets/icons/loading.svg';
import { columnsNotifEventTable, renderNotifEventTable } from './historyNotifEvent.utils';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import { historyClientEventNotificationReq } from '../../../api/client/historyClient';

const ClientNotifEvent = () => {
  const { id } = useParams();
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);

  useEffect(() => {
    historyClientEventNotificationReq(id)
      .then((res) => {
        console.log(res);
        setTableHistoryData(renderNotifEventTable(res));
      })
      .finally(() => setStatus('done'));
  }, []);

  function HeaderTitle() {
    return (
      <Fragment>
        <Fragment>
          <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
          <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
          Historique des évenements
        </Fragment>
      </Fragment>
    );
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="d-flex o-content">
        <div className="flex-fill">
        <Table
          showModal={showModal}
          setShowModal={setShowModal}
          columns={columnsNotifEventTable}
          tableData={tableHistoryData}
          status={status}
          textLeft
          ContainHeader={() => (
            <div className="mb-4 mt-2">
              <b>Historique des évenements (Mail + Notification Push)</b>
            </div>
          )}
          contain
          itemPerPage={10}
        />
        </div>
      </div>
    </div>
  );
};

export default ClientNotifEvent;
