import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import { columns, renderTable, tableSearch } from './packageFeature.utils';
import { getPackageFeatureReq } from '../../../api/packageFeature/getPackageFeature';
import Header from '../../../components/header';
import Table from '../../../components/table';
import { IconPackage } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import { getPackageFeatureConfigReq } from '../../../api/packageFeature/config';
import ShowPackageFeature from './modals/showPackageFeature';
import AddPackageFeature from './modals/addPackageFeature';
import DeletePackageFeature from './modals/deletePackageFeature';

const PackageFeature = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [config, setConfig] = useState([]);
  const [isAdd, setIsAdd] = useState();

  useEffect(() => {
    getPackageFeatureConfigReq().then((res) => setConfig(res));
  }, []);

  useEffect(() => {
    let isEdit, isDelete, isRead;

    isRead = permContext?.featureRead;
    isEdit = permContext?.featureUpdate;
    isDelete = permContext?.featureDelete;
    if (permContext && !isRead) window.location.href = '/dashboard';
    setIsAdd(isEdit);

    if (permContext) {
      /*Get data of the list*/
      getPackageFeatureReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setAddModal, setDeleteModal, setSelectedID, config, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext, config]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/admin">Administration</a> <ArrowRight />{' '}{t('sidebar:features')}
      </Fragment>
    );
  }

  return (
    <div>
      <Header
        headerTitle={HeaderTitle()}
        HeaderIcon={IconPackage}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setSelectedID={setSelectedID}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
        />
      </div>
      {/*Add and edit group*/}
      {addModal && (
        <AddPackageFeature
          addModal={addModal}
          setAddModal={setAddModal}
          selectedID={selectedID}
          config={config}
        />
      )}
      {/*Show document*/}
      {showModal && (
        <ShowPackageFeature
          showModal={showModal}
          setShowModal={setShowModal}
          selectedID={selectedID}
          config={config}
        />
      )}
      {/*Delete group*/}
      {deleteModal && (
        <DeletePackageFeature
          selectedID={selectedID}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </div>
  );
};

export default PackageFeature;
