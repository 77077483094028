import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Header from '../../../../components/header';
import Table from '../../../../components/table';
import CreditCard from '../../../../components/creditCard';
import CreditCardActions from '../../../../components/creditCard/cardActions';
import CreditCardDigitalization from '../../../../components/creditCard/card_digitalization';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import Logo from '../../../../components/logo';

import ConfirmationBlockCard from '../modals/card/confirmationBlock';
import ConfirmationForcePin from '../modals/card/confirmationForcePin';
import ConfirmationLostStolenCard from '../modals/card/confirmationLostStolen';
import ConfirmationActivateCard from '../modals/card/confirmationActivate';
import ConfirmationUnlockPinCard from '../modals/card/confirmationUnlockPin';
import ConfirmationUpdateCardLimits from '../modals/card/confirmationUpdateCardLimits';
import ConfirmationUpdateCardOptions from '../modals/card/confirmationUpdateCardOptions';
import RefundTransactionModal from '../modals/refundTransactionModal';

import {
  tableCardDigitalizationsHeader,
  renderCardDigitalizationsTable,
} from './card_detail.utils';
import {cardDetailReq} from '../../../../api/client/card/card'
import {
  cardDigitalizationListReq, cardDigitalizationDetailReq
} from '../../../../api/client/card/carddigitalizations'

const ClientWalletCardDetail = () => {
  const { userId, walletId, cardId } = useParams();
  const [cardDetailData, setCardDetailData] = useState({});
  const [cardDetailLoading, setCardDetailLoading] = useState(true);
  const [cardDigitalizations, setCardDigitalizations] = useState([]);
  const [cardDigitalizationsData, setCardDigitalizationsData] = useState([]);
  const [cardDigitalizationsLoading, setCardDigitalizationsLoading] = useState(true);

  const [activateCardModal, setActivateCardModal] = useState(false);
  const [unlockPinCardModal, setUnlockPinCardModal] = useState(false);
  const [updateLimitsCardModal, setUpdateLimitsCardModal] = useState(false);
  const [updateOptionsCardModal, setUpdateOptionsCardModal] = useState(false);
  const [blockCardModal, setBlockCardModal] = useState(false);
  const [forceCodeModal, setForceCodeModal] = useState(false);
  const [lostCardModal, setLostCardModal] = useState(false);

  const [selectedCardDigitalizationId, setSelectedCardDigitalizationId] = useState();
  const [selectedCardDigitalization, setSelectedCardDigitalization] = useState();

  const refreshCardDigitalizationList = () => {
    setCardDigitalizationsLoading(true);
    cardDigitalizationListReq(cardId).then((res) => {
      setCardDigitalizations(res);
      setCardDigitalizationsData(renderCardDigitalizationsTable(res));
    })
    .finally(() => {
      setCardDigitalizationsLoading(false);
    });
  };

  const loadCardDetailInfos = async (force) => {
    setCardDetailLoading(true);
    cardDetailReq(userId, cardId, force).then((res) => {
      setCardDetailData(res);
    }).finally(() => {
      setCardDetailLoading(false);
    });
  }

  const refreshCardDetailInfos = async (cardId) => {
    loadCardDetailInfos(true);
  }

  useEffect(() => {
    loadCardDetailInfos();

    refreshCardDigitalizationList();
  }, []);

  useEffect(() => {
    const cardDigitalizationDetail = cardDigitalizations.find((x) => x.carddigitalizationid === selectedCardDigitalizationId);
    setSelectedCardDigitalization(cardDigitalizationDetail);
  }, [selectedCardDigitalizationId]);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${userId}`}>Détail Client</a> <ArrowRight />
        <a href={`/dashboard/client/${userId}/wallet/${walletId}`}>Détail Wallet</a> <ArrowRight />
        Détail Carte
      </Fragment>
    );
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div className="o-information-detail__block">
            <div className="o-information-column">
              <div className="o-information-flex">
                <div className="o-information--block">
                  <CreditCard
                    cardNumber={cardDetailData?.maskedPan}
                    name={cardDetailData?.embossedName}
                    date={cardDetailData?.expiryDate}
                    cardImageUrl={cardDetailData?.image_url}
                  />
                  <div>
                    <div className="mt-3">
                      <b>Informations Cartes</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div><b>Card ID / Trz Card ID</b></div>
                          <div>{cardDetailData?.cardId} / {cardDetailData?.trzCardId}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>Wallet Id</b></div>
                          <div>{cardDetailData?.wallet_id}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>Date de création</b></div>
                          <div>{moment(cardDetailData?.created).format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>Statut</b></div>
                          <div>{cardDetailData?.status}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>3 PIN Faux</b></div>
                          <div>{cardDetailData?.pinTryExceeds ? <span className="o-red">OUI</span> : <span className="o-green">Non</span>}</div>
                        </div>
                      </div>
                      <div className=" mt-2 ml-4">
                        <div>
                          <div><b>Type</b></div>
                          <div>{cardDetailData?.card_type}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>User Id</b></div>
                          <div><a href={`/dashboard/client/${cardDetailData?.owner_user_id}`}>{cardDetailData?.owner_user_id}</a> <ArrowRight /></div>
                        </div>
                        <div className="mt-2">
                          <div><b>PublicToken</b></div>
                          <div>{cardDetailData?.publicToken}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>Active</b></div>
                          <div>{cardDetailData?.activated ? <span className="o-green">OUI</span> : <span className="o-red">Non</span>}</div>
                        </div>
                        <div className="mt-2">
                          <div><b>Date destruction</b></div>
                          <div>{cardDetailData?.deleted_date ? moment(cardDetailData?.deleted_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-information--block">
                  <div>
                    { cardDetailData?.cardLimits && (
                    <div className="mt-3">
                      <b>Plafonds Paiements</b>
                      <hr/>
                      <div className="mt-2 mb-2">
                        <div><i>30 derniers jours</i> : {cardDetailData?.cardLimits?.limitPaymentMonth}&nbsp;€ ({cardDetailData?.cardStats?.totalPaymentMonth.toFixed(2)}&nbsp;€)</div>
                        <div><i>7 derniers jours</i> : {cardDetailData?.cardLimits?.limitPaymentWeek}&nbsp;€ ({cardDetailData?.cardStats?.totalPaymentWeek.toFixed(2)}&nbsp;€)</div>
                        <div><i>aujourd'hui</i> : {cardDetailData?.cardLimits?.limitPaymentDay}&nbsp;€ ({cardDetailData?.cardStats?.totalPaymentDay.toFixed(2)}&nbsp;€)</div>
                      </div>
                      <b>Plafonds Retraits</b>
                      <hr/>
                      <div className="mt-2">
                        <div><i>30 derniers jours</i> : {cardDetailData?.cardLimits?.limitAtmMonth}&nbsp;€ ({cardDetailData?.cardStats?.totalAtmMonth.toFixed(2)}&nbsp;€)</div>
                        <div><i>7 derniers jours</i> : {cardDetailData?.cardLimits?.limitAtmWeek}&nbsp;€ ({cardDetailData?.cardStats?.totalAtmWeek.toFixed(2)}&nbsp;€)</div>
                        <div><i>aujourd'hui</i> : {cardDetailData?.cardLimits?.limitAtmDay}&nbsp;€ ({cardDetailData?.cardStats?.totalAtmDay.toFixed(2)}&nbsp;€)</div>
                      </div>
                    </div>
                    )}
                    { cardDetailData?.cardOptions && (
                    <div className="mt-3">
                      <b>Options de paiement</b>
                      <hr/>
                      <div className="mt-2 mb-2">
                        <div><i>Retrait ATM</i> : {cardDetailData?.cardOptions?.optionAtm ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                        <div><i>Paiement étranger</i> : {cardDetailData?.cardOptions?.optionForeign ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                        <div><i>Paiement e-commerce</i> : {cardDetailData?.cardOptions?.optionOnline ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                        <div><i>Paiment NFC</i> : {cardDetailData?.cardOptions?.optionNfc ? <span className="o-green">OUI</span> : <span className="o-red">NON</span>}</div>
                      </div>
                    </div>
                    )}
                    { cardDetailData?.shipping_tracking_url && (
                    <div className="mt-3">
                      <b>Code suivi</b>
                      <hr />
                      <div>
                      {cardDetailData?.shipping_tracking_url}
                      </div>
                      <div className="mt-2">
                        <div><b>Date d'envoi</b></div>
                        <div>{cardDetailData?.shipping_date ? moment(cardDetailData?.shipping_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="o-information--block">
                  <div>
                    <div className="mt-3">
                      <b>Actions</b>
                      <hr />
                      <CreditCardActions
                        cardDetailData={cardDetailData}
                        setBlockCardModal={setBlockCardModal}
                        setLostCardModal={setLostCardModal}
                        setActivateCardModal={setActivateCardModal}
                        setForceCodeModal={setForceCodeModal}
                        setUnlockPinCardModal={setUnlockPinCardModal}
                        setUpdateLimitsCardModal={setUpdateLimitsCardModal}
                        setUpdateOptionsCardModal={setUpdateOptionsCardModal}
                        refreshCardDetailInfos={refreshCardDetailInfos}
                        refreshCardLoading={cardDetailLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {cardDigitalizationsData && (
            <Table
              columns={tableCardDigitalizationsHeader}
              tableData={cardDigitalizationsData}
              ContainHeader={() => 'Digitalisations'}
              setShowModal={() => {}}
              setSelectedID={setSelectedCardDigitalizationId}
              contain
            />
          )}
        </div>
        <div className="o-viewer">
          {selectedCardDigitalizationId && selectedCardDigitalization && (
            <div>
<CreditCardDigitalization
  cardId={cardId}
  cardDetailDigitalizationData={selectedCardDigitalization}
  refreshData={refreshCardDigitalizationList}
  />
            </div>
          )}
          {!selectedCardDigitalization && <Logo className="o-viewer-logo"/>}
        </div>
      </div>
      <ConfirmationBlockCard
        userID={userId}
        walletID={walletId}
        cardID={cardId}
        blockModal={blockCardModal}
        status={cardDetailData?.status}
        setBlockModal={setBlockCardModal}
        refreshData={loadCardDetailInfos}
      />
      <ConfirmationForcePin
        userID={userId}
        walletID={walletId}
        cardID={cardId}
        forceCodeModal={forceCodeModal}
        setForceCodeModal={setForceCodeModal}
      />
      <ConfirmationLostStolenCard
        userID={userId}
        walletID={walletId}
        cardID={cardId}
        lostStolenCardModal={lostCardModal}
        setLostStolenCardModal={setLostCardModal}
        refreshData={loadCardDetailInfos}
      />
      <ConfirmationActivateCard
        userID={userId}
        walletID={walletId}
        cardID={cardId}
        activateCardModal={activateCardModal}
        setActivateCardModal={setActivateCardModal}
        refreshData={loadCardDetailInfos}
      />
      <ConfirmationUnlockPinCard
        userID={userId}
        walletID={walletId}
        cardID={cardId}
        unlockPinCardModal={unlockPinCardModal}
        setUnlockPinCardModal={setUnlockPinCardModal}
      />
      <ConfirmationUpdateCardLimits
        userID={userId}
        walletID={walletId}
        card={cardDetailData}
        updateLimitsModal={updateLimitsCardModal}
        setUpdateLimitsModal={setUpdateLimitsCardModal}
        refreshData={loadCardDetailInfos}
      />
      <ConfirmationUpdateCardOptions
        userID={userId}
        walletID={walletId}
        card={cardDetailData}
        updateOptionsModal={updateOptionsCardModal}
        setUpdateOptionsModal={setUpdateOptionsCardModal}
        refreshData={loadCardDetailInfos}
      />
    </div>
  );
};

export default ClientWalletCardDetail;
