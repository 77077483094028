import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updateClientCodePromo } from '../../../../api/client/updateClient';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationUpdateCodePromo = ({ updateCodePromoOpen, setUpdateCodePromoOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const updateHandler = async (id, formData) => {
    setLoading(true);
    let status = await updateClientCodePromo(id, formData);
    setLoading(false);
    setUpdateCodePromoOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  const UpdateCodePromoInitialValues = (data) => {
    return {
      code: data ? data.code_promo : '',
    }
  };
  const UpdateCodePromoSchema = Yup.object().shape({
    code: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateCodePromoOpen} toggle={setUpdateCodePromoOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateCodePromoInitialValues(userData)}
      validationSchema={UpdateCodePromoSchema}
      onSubmit={(values) => {
        console.log('Mise à jour du code promo');
        updateHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setUpdateCodePromoOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification du code promotionnel</div>
          <Field
            name="code"
            className="mt-3"
            label="Code promo"
            placeholder="Code promo *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUpdateCodePromoOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCodePromo;
