import { endpoint, backendApiEndpoint, notifCenterApiEndpoint } from '../constants.js';
import { CheckAuth, instance, instanceBackendApi, instanceNotifCenterApi } from '../index';
import { toast } from 'react-toastify';

export async function historyClientHttpReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_history_http.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}


export async function summaryClientHttpReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_summary_http.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function summaryByCountryClientHttpReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_bycountry_http.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function summaryByPlateformClientHttpReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_byplateform_http.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function downloadClientHttpHistoryReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_download_http.replace('{{userId}}', userId));
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "text/csv" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', 'user_' + userId + '_http_history.csv')
      document.body.appendChild(link)
      link.click()
      //const csvWindow = window.open();
      //csvWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function historyClientEventNotificationReq(userId) {
  try {
    const res = await instanceNotifCenterApi.get(notifCenterApiEndpoint.client_history_events.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
