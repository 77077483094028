import React, { useState, useEffect, useContext, Fragment  } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { InputFormik } from '../../../components/input';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import TablePage from '../../../components/tablepage';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columns, renderTable, tableSearch, orderByParams } from './client.status';
import { localStorageKeys } from '../../../api/constants.js';
import { getClientByStatusDownload } from '../../../api/client/getClient';
import { searchClientPageReq } from '../../../api/client/searchClient';
import IconLoading from '../../../assets/icons/loading.svg';
import IconDoc from '../../../assets/icons/doc.svg';

const ClientSearchByStatus = () => {
  const { t } = useTranslation();
  const { status } = useParams();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [displayPayin, setDisplayPayin] = useState(false);

  const downloadCsv = async (status) => {
    if (status) {
      setCsvLoading(true);
      const res = await getClientByStatusDownload(status)
      .finally(() => setCsvLoading(false));
    }
  }

  const refreshUsersListPage = (values, pageNo, pageSize) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(values);
    pageNo = pageNo != null ? pageNo : pageMetaData.pageNumber;
    pageSize = pageSize != null ? pageSize : pageMetaData.pageSize;
    values["display_payin"] = ((status === 'onboarding_finalized') || (status === 'onboarding_waiting_payin'));
    searchClientPageReq(values, status, pageNo, pageSize)
      .then((res) => {
        let renderResult = JSON.stringify(res.content);
        //console.log("renderResult : " + renderResult);
        localStorage.setItem(localStorageKeys.clients_searched + status, renderResult);
        setTableData(renderTable(status, res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({count:res.totalElements});
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  }

  const refreshData = (pageNo, pageSize) => {
    refreshUsersListPage(formValues, pageNo, pageSize);
  }

  useEffect(() => {

    let isRead;
    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    setLoading(true);
    setDataStatus('loading');
    let searchedClients = localStorage.getItem(localStorageKeys.clients_searched + status);
    var tableDataList = [];
    try {
      tableDataList = renderTable(status, JSON.parse(searchedClients))
    } catch (e) {
      console.log('Problème de parsing de ' + searchedClients);
    } finally {
      setTableData(tableDataList);
    }
    setLoading(false);
    setDataStatus('done');
  }, [permContext, status]);

  useEffect(() => {
    // Redirection vers le détail du client
    if (showModal) window.location.href = `/dashboard/client/${selectedID}?status=` + status;
  }, [showModal]);

  useEffect(() => {
    //tableSearch(status, tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function DownloadButton(status) {
    return (
      <Fragment>
          <div className="ml-5">
          <Formik
            enableReinitialize={true}
            initialValues={SearchUserInitialValues}
            validationSchema={SearchUserSchema}
            onSubmit={(values) => {
              refreshUsersListPage(values, 0);
            }}
          >
            <Form className="form-inline">
              <div className="form-group">
                { ((status === 'onboarding_finalized') || (status === 'onboarding_waiting_payin')) ?
                <div>
                  <Field
                    type="checkbox"
                    name="with_payin"
                    className="ml-2"
                    label='with_payin'
                    placeholder='with_payin'
                  />
                  <div className="ml-2">&nbsp;Avec virement entrant</div>
                </div>
                  : ''
                }
                <Button className="ml-5" type="submit">
                {loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
              </div>
            </Form>
          </Formik>
          </div>
        <div className="ml-auto">
        <Button btnType="outline" className="ml-4"
          onClick={() => {
            downloadCsv(status);
          }}
        >
          {
            csvLoading ?
              <img className="mr-2" src={IconLoading} height="25" alt="" /> :
              <img className="mr-2" src={IconDoc} alt="" />
          }
        Télécharger CSV
        </Button>
        </div>
      </Fragment>
    );
  }

  const SearchUserInitialValues = {
    with_payin: '',
  };
  const SearchUserSchema = Yup.object().shape({
  });

  return (
    <div>
      <Header
        headerTitle={status}
        HeaderIcon={IconClient}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setSelectedID={setSelectedID}
        isAdd={false}
        rightFragment={DownloadButton(status)}
        disabledSearch={true}
      />
      <div className="o-content">
        <TablePage
          columns={columns(status)}
          tableData={searchInput ? filteredData : tableData}
          pageMetaData={userPageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => 'Clients'}
          contain
        />
      </div>
    </div>
  );
};

export default ClientSearchByStatus;
