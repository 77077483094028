import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deleteClientReq } from '../../../../api/client/deleteClient';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationDeleteInfo = ({ deleteInfoOpen, setDeleteInfoOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id, confirmName) => {
    setLoading(true);
    let status = await deleteClientReq(id, confirmName);
    setLoading(false);
    setDeleteInfoOpen(false)
    if (status == 'success') {
      refreshData()
    }
  }

  const DeleteClientInitialValues = {
    confirmLastnameUppercase: ''
  };
  const DeleteClientSchema = Yup.object().shape({
    confirmLastnameUppercase: Yup.string().required('Requis')
  });

  return (
    <Modal isOpen={deleteInfoOpen} toggle={setDeleteInfoOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteClientInitialValues}
      validationSchema={DeleteClientSchema}
      onSubmit={(values) => {
        console.log('Delete client submit');
        deleteHandler(userId, values['confirmLastnameUppercase']).then((res) => {
          if (res === 'success') {
            setDeleteInfoOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer la suppression du client, veuillez saisir en majuscule le nom du client</div>
          <Field
            name="confirmLastnameUppercase"
            className="mt-5"
            label="Nom du client en MAJUSCULE *"
            placeholder="Nom du client en MAJUSCULE *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setDeleteInfoOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteInfo;
