import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { addCodePromoReq } from '../../../../api/codepromo/addCodePromo';
import { CodePromoSchema, CodePromoInitialValues } from '../codepromo.utils';
import { YesNo } from '../../../common.utils'
import Dropdown from '../../../../components/dropdown';

const AddCodePromo = ({ addModal, setAddModal, currentItem }) => {
  const { t } = useTranslation();
  const [currentCodePromo, setCurrentCodePromo] = useState();

  useEffect(() => {
    if (currentItem) {
      console.info('currentItem : ' + currentItem);
      console.info('currentItem.code : ' + currentItem.code);
      console.info('currentItem.description : ' + currentItem.description);
      console.info('currentItem.activated : ' + currentItem.activated);
      setCurrentCodePromo(
        {
          code: currentItem.code,
          description: currentItem.description,
          activated: currentItem.activated ? YesNo[0] : YesNo[1],
      });
    }
  }, [currentItem]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentCodePromo ? currentCodePromo : CodePromoInitialValues}
        validationSchema={CodePromoSchema}
        onSubmit={(values) => {
          let data = {
            code: values.code,
            description: values.description,
            activated: values.activated.value,
          }
          addCodePromoReq(data).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/codepromos';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {currentCodePromo ? "Modification Code Promo" : "Nouveau Code Promo"}
            </div>
            <Field
              name="code"
              className="mt-5"
              label="Code"
              placeholder="Code *"
              component={InputFormik}
              disabled={currentCodePromo}
            />
            <Field
              name="description"
              className="mt-5"
              label="Description"
              placeholder="Description *"
              component={InputFormik}
            />
            <Field
              name="activated"
              label="Activated"
              placeholder="Activated *"
              options={YesNo}
              component={Dropdown}
            />
            <div className="d-flex flex-column mt-5">
              <Button className="mb-2" type="submit">
                {currentCodePromo ? t('global:validate') : t('global:add')}
              </Button>
              <Button
                btnType="outline"
                type="button"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddCodePromo;
