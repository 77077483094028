import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function handleRib(walletId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.wallet_rib.replace('{{walletId}}', walletId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function generateRibReq(walletId) {
  try {
    let queryParams = {
      params: {
        force : true,
      },
      responseType: "arraybuffer"
    };
    const res = await instanceBackendApi.get(backendApiEndpoint.wallet_rib.replace('{{walletId}}', walletId), queryParams);
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
