import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateCardStatusReq } from '../../../../../api/client/card/lockCard';
import { CardLostStolenTypes } from '../../../../common.utils';
import Dropdown from '../../../../../components/dropdown';

const ConfirmationLostStolenCard = ({ userID, card, lostStolenCardModal, setLostStolenCardModal, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const changeLostStolenHandler = async (data) => {
    setLoading(true);
    let status = await updateCardStatusReq(userID, card.wallet_id, card.cardId, {
      status : data.status.value,
      client_name : data.client_name,
    }).finally(() => {
      setLoading(false);
    });
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setLostStolenCardModal(false);
  }

  const LostStolenCardInitialValues = {
  };
  const LostStolenCardSchema = Yup.object().shape({
    status: Yup.object().required('Requis'),
    client_name: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={lostStolenCardModal} toggle={setLostStolenCardModal}>
    <Formik
      enableReinitialize={true}
      initialValues={LostStolenCardInitialValues}
      validationSchema={LostStolenCardSchema}
      onSubmit={(values) => {
        console.log('Confirm Lost/stolen Card');
        changeLostStolenHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
        <div className="o-title text-center mb-4">ATTENTION CETTE OPERATION EST IRREVERSIBLE</div>
        <div className="o-title text-center mb-4">Veuillez confirmer en saisissant le status (LOST / STOLEN) et le nom du client en majuscule.</div>
        <Field
            name="status"
            className="mt-2"
            label="Status de la carte *"
            placeholder="Status de la carte *"
            component={Dropdown}
            options={CardLostStolenTypes}
          />
          <Field
            name="client_name"
            className="mt-5"
            label="Nom du client en majuscule *"
            placeholder="Nom du client en majuscule *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationLostStolenCard;
