import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../components/input';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import TablePage from '../../../components/tablepage';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columns, renderTable, tableSearch } from '../client/client.utils';
import { searchClientPageReq } from '../../../api/client/searchClient';
import IconLoading from '../../../assets/icons/loading.svg';

const ClientSearchByIds = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('done');

  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [formValues, setFormValues] = useState({});

  const searchUserHandler = async (values) => {
    setLoading(true);
    setDataStatus('loading')
    setFormValues(values);
    searchClientPageReq(values)
      .then((res) => {
        setPageMetaData(res.pageable);
        setUserPageMetaData({count:res.totalElements});
        setTableData(renderTable(res.content));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  }

  const refreshData = (pageNo, pageSize) => {
    searchUserHandler(formValues, pageNo, pageSize);
  }

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      /*Get data of the list*/

    }
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  const SearchUserInitialValues = {
    userId: '',
    trzUserId: '',
    walletId: '',
    trzWalletId: '',
  };
  const SearchUserSchema = Yup.object().shape({
  });

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={SearchUserInitialValues}
        validationSchema={SearchUserSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          searchUserHandler(values)
        }}
      >
        <Form className="form-inline">
          <div className="form-group mt-5 ml-5">
            <Field
                name="userId"
                className="mb-2 ml-5"
                label='userId'
                placeholder='userId'
                component={InputFormik}
              />
            <Field
                name="trzUserId"
                className="mb-2 ml-5"
                label='Treezor UserId'
                placeholder='Treezor UserId'
                component={InputFormik}
              />
            <Field
                name="walletId"
                className="mb-2 ml-5"
                label='walletId'
                placeholder='walletId'
                component={InputFormik}
              />
            <Field
                name="trzWalletId"
                className="mb-2 ml-5"
                label='Treezor WalletId'
                placeholder='Treezor WalletId'
                component={InputFormik}
              />
                <Button
                  className="mb-2 ml-5"
                  type="submit"
                >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
          </div>
        </Form>
      </Formik>
      <Header
        headerTitle={t('sidebar:client_search')}
        HeaderIcon={IconClient}
        isAdd={false}
        disabledSearch={true}
      />
      <div className="o-content">
        <TablePage
          columns={columns}
          tableData={tableData}
          pageMetaData={userPageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => 'Clients'}
          contain
        />
      </div>
    </div>
  );
};

export default ClientSearchByIds;
