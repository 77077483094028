import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../utils/windowDimension';
import '../table/table.css';
import { Pagination } from '../pagination';
import IconLoading from '../../assets/icons/loading.svg';

const TablePage = ({
  contain,
  columns,
  tableData,
  pageMetaData,
  loadTableData,
  status,
  setShowModal,
  setCurrentItem,
  setSelectedID,
  ContainHeader,
  textLeft,
  itemPerPage,
  ...props
}) => {
  const [perPage, setPerPage] = useState(itemPerPage ? itemPerPage : 100);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(itemPerPage ? itemPerPage : 100);
  const [data, setData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [mobileData, setMobileData] = useState([]);
  const isMobile = useWindowDimensions().width < 650;
  const isTablet = useWindowDimensions().width < 900;

  useEffect(() => {
    loadTableData([startIndex - 1] / perPage, perPage);
  },[startIndex, perPage]);

  useEffect(() => {
    setTableCount(pageMetaData.count);
  },[pageMetaData]);

  useEffect(() => {
    const data = tableData.map((row, index) => {
      let rowData = [];
      for (const key in row) {
        if (key !== 'key' && key !== 'searchData') {
          rowData.push({
            val: row[key],
          });
        }
      }
      return (
        <tr
          key={index}
          onClick={() => {
            setCurrentItem && setCurrentItem(row);
            setSelectedID && setSelectedID(row.key);
            setShowModal(true);
          }}
        >
          {rowData.map((data, index) => (
            <td key={index}>{data.val}</td>
          ))}
        </tr>
      );
    });
    setData(data);
    //Mobile view data of the table
    const mobileData = tableData.map((row, index) => {
      let rowData = [];
      let i = 0;
      for (const key in row) {
        if (key !== 'key' && key !== 'searchData') {
          rowData.push({
            column: columns[i],
            val: row[key],
          });
          i++;
        }
      }
      return (
        <div
          className="o-table-mobile__items"
          key={index}
          onClick={() => {
            setCurrentItem && setCurrentItem(row);
            setSelectedID && setSelectedID(row.key);
            setShowModal(true);
          }}
        >
          {rowData.map((data, index) => (
            <div key={index} className="o-table-mobile__item">
              <div className="o-table-mobile__column">{data.column}</div>
              <div className="o-table-mobile__element">{data.val}</div>
            </div>
          ))}
        </div>
      );
    });
    setMobileData(mobileData);
  }, [tableData, endIndex, columns, setShowModal]);

  return (
    <div className={!isMobile && contain ? 'o-table-container' : ''}>
      {!contain && <div className="o-table-line" />}
      {/*Condition for desktop and mobile view of the table */}
      {isMobile ? (
        status !== 'loading' ? (
          <div className="o-table-mobile">
            {mobileData.length > 0 ? (
              mobileData
            ) : (
              <div className="text-center my-5">Pas de données</div>
            )}
          </div>
        ) : (
          <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
        )
      ) : (
        <div
          className={`o-table ${props.notClickableRow ? 'cursor-default' : ''} ${contain ? 'o-table-contain' : ''}  ${
            textLeft ? 'o-table__left' : ''
          }`}
        >
          {contain && <ContainHeader />}
          <table>
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody className="mx-auto">{status !== 'loading' && data}</tbody>
          </table>
          {status === 'loading' && (
            <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
          )}
          {status !== 'loading' && data.length <= 0 && (
            <div className="text-center my-5">Pas de données</div>
          )}
        </div>
      )}
      {/*Pagination of the table */}
      <Pagination
        perPage={perPage}
        setPerPage={setPerPage}
        count={tableCount}
        startIndex={startIndex}
        endIndex={endIndex}
        setStartIndex={setStartIndex}
        setEndIndex={setEndIndex}
        sticky={!contain && !isTablet}
      />
    </div>
  );
};

export default TablePage;
