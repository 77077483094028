import React, {  useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../../contexts/permissionContext';
import Header from '../../../components/header';
import './supervision.css';

import {
  IconPermission,
  IconClient,
  IconMeter,
} from '../../../assets/icons/icons-sidebar.jsx';

const SupervisionCenter = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const activeStyle = { backgroundColor: 'var(--primary-dark)', color: 'var(--black)' };


  useEffect(() => {

  }, []);

  return (
<div>
  <Header
    headerTitle="Centre de Surveillance"
    HeaderIcon={IconPermission}
    disabledSearch={true}
  />
  <div className="row mt-5 ml-5">
    {permContext?.clientRead && (
      <NavLink
        className="o-supervision-item"
        to="/dashboard/supervision/client_fraud_suspected"
        activeStyle={activeStyle}
      >
        <IconClient />
        <div> {t('sidebar:client_fraud_suspected')}</div>
      </NavLink>
    )}
    {permContext?.clientRead && (
      <NavLink
        className="o-supervision-item"
        to="/dashboard/supervision/payin_alerts"
        activeStyle={activeStyle}
      >
        <IconMeter />
        <div>Payin Alertes</div>
      </NavLink>
    )}
    {permContext?.clientRead && (
      <NavLink
        className="o-supervision-item"
        to="/dashboard/supervision/sddr_rejected"
        activeStyle={activeStyle}
      >
        <IconMeter />
        <div>SDDR Rejets</div>
      </NavLink>
    )}
    {permContext?.clientRead && (
      <NavLink
        className="o-supervision-item"
        to="/dashboard/supervision/client_to_recover"
        activeStyle={activeStyle}
      >
        <IconClient />
        <div> {t('sidebar:client_to_recover')}</div>
      </NavLink>
    )}
    {permContext?.clientRead && (
      <NavLink
        className="o-supervision-item"
        to="/dashboard/supervision/client_overdraft"
        activeStyle={activeStyle}
      >
        <IconClient />
        <div> {t('sidebar:client_overdraft')}</div>
      </NavLink>
    )}

  </div>
</div>
  );
};

export default SupervisionCenter;
