import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateCardLockUnlockReq } from '../../../../../api/client/card/lockCard';
import IconCheck from '../../../../../assets/icons/check.svg';
import IconCross from '../../../../../assets/icons/cross.svg';

const ConfirmationBlockCard = ({ userID, card, blockModal, setBlockModal, status, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');

  const LockUnlockAccountHandler = async () => {
    setLoading(true);
    const res = await updateCardLockUnlockReq(userID, card.wallet_id, card.cardId, status === 'UNLOCK' ? 'LOCK' : 'UNLOCK');
    res ? setResponseText('success') : setResponseText('fail');
    refreshData();
    setLoading(false);
  }

  const close = () => {
    setBlockModal(false);
    setResponseText('');
  }

  const ShowResponseText = () => (
    <div style={{textAlign: 'center', margin: "20px auto 30px auto"}}>
      {responseText} <br />
      {
      responseText === 'success' ?
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCheck} alt="" /> :
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCross} alt="" />
      }

    </div>
  );


  return (
    <Modal isOpen={blockModal} toggle={setBlockModal}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">{status === 'UNLOCK' ? 'Bloquer' : 'Débloquer'} la carte</div>
          {
          loading ?
            <img className="d-flex mx-auto" style={{ margin: "20px auto 30px auto" }} src={IconLoading} height="25" alt="" /> :
            responseText.length ? <ShowResponseText /> : ''
          }
          {
            !responseText.length ?
            <Button
              className="mb-2 mt-2"
              onClick={LockUnlockAccountHandler}
              type="submit"
            >Confirmer</Button> :
            ''
          }
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationBlockCard;
