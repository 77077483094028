import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updatePartnershipReq } from '../../../../api/partnership/partnership';
const UpdatePartnership = ({ updateModal, setUpdateModal, currentItem }) => {
  const { t } = useTranslation();

  const updateReq = () => {
    updatePartnershipReq(currentItem).then((res) => {
      if (res === 'success') {
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 700);
      }
    });
  };

  return (
    <Modal isOpen={updateModal} toggle={setUpdateModal}>
      <div>
        <div className="d-flex flex-column">
          <div className="o-title text-center mb-4">{t('partnership:delete_partnership')}</div>
          <p
            className="text-center mb-5"
            dangerouslySetInnerHTML={{ __html: t('partnership:delete_partnership_description') }}
          />
          <Button className="mb-2" type="submit" onClick={updateReq}>
            {t('global:confirm')}
          </Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setUpdateModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePartnership;
