import React from 'react';
import PropTypes from 'prop-types';
import './button.css';
import IconEdit from '../../assets/icons/edit.svg';
import IconDelete from '../../assets/icons/delete.svg';
import IconDisabledEdit from '../../assets/icons/disabledEdit.svg';
import IconDisabledDelete from '../../assets/icons/disabledDelete.svg';

const Button = ({ className, children, btnType, ...props }) => {
  return (
    <button className={`o-btn o-btn-${btnType} ${className}`} {...props}>
      {children}
    </button>
  );
};

export const ButtonWithIcon = ({ className, children, ...props }) => {
  return (
    <button className={`o-btn-icon ${className}`} {...props}>
      {children}
    </button>
  );
};

function renderButtonTableImage(action, disabled) {
  if (action === 'edit' && !disabled) {
    return IconEdit;
  } else if (action === 'delete' && !disabled) {
    return IconDelete;
  } else if (action === 'edit' && disabled) {
    return IconDisabledEdit;
  } else if (action === 'delete' && disabled) {
    return IconDisabledDelete;
  }
}
export const ButtonTable = ({ className, children, action, disabled, ...props }) => {
  return (
    <div className="o-btn-table">
      <img
        title={disabled && "Vous n'avez pas les droits nécessaires pour effectuer cette action"}
        className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}
        src={renderButtonTableImage(action, disabled)}
        alt=""
        {...props}
      />
    </div>
  );
};

Button.defaultProps = {
  btnType: 'default',
};
Button.propTypes = {
  className: PropTypes.string,
  btnType: PropTypes.oneOf(['default', 'outline', 'text']),
  disabled: PropTypes.bool,
};

ButtonWithIcon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
