import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { UserSchema, UserInitialValues } from '../user.utils';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../assets/icons/loading.svg';
import { detailUserReq } from '../../../../api/user/users';

const ShowUser = ({ showModal, setShowModal, selectedID }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [groupDrp, setGroupDrp] = useState();
  const [permissionDrp, setPermissionDrp] = useState();
  const [status, setStatus] = useState('loading');
  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      detailUserReq(selectedID)
        .then((res) => {
          let permissionOptions = [];
          res.permissions &&
            res.permissions.map((selectedPermission) =>
              permissionOptions.push({
                value: selectedPermission.code,
                label: selectedPermission.name,
              }),
            );
          setPermissionDrp(permissionOptions);
          setInitialValue({
            email: res.email,
            group: res.group && { value: res.group.id, label: res.group.name },
            permissions: permissionOptions,
          });
          res.group && setGroupDrp([{ value: res.group.id, label: res.group.name }]);
        })
        .finally(() => setStatus('done'));
    }
  }, [selectedID]);
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : UserInitialValues}
        validationSchema={UserSchema}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">{t('user:detail_user')}</div>
            {status === 'loading' ? (
              <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="email"
                  className="mt-5"
                  label={t('user:email')}
                  placeholder={t('user:email_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="group"
                  label={t('user:group')}
                  placeholder={t('user:group_placeholder') + ' *'}
                  options={groupDrp}
                  component={Dropdown}
                  onlyRead
                />
                {permissionDrp.length > 0 && (
                  <Field
                    name="permissions"
                    label="Permissions"
                    placeholder="Permissions"
                    options={permissionDrp}
                    component={Dropdown}
                    onlyRead
                    isMulti
                  />
                )}

                <div className="d-flex flex-column mt-5">
                  <Button btnType="outline" type="button" onClick={() => setShowModal(false)}>
                    {t('global:back')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ShowUser;
