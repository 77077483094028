import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './dropdown.css';

/*
  <Dropdown label="Permissions" placeholder="Permissions" options={options} isMulti />
*/
/*Conditions for the color of the underline to change in cases of disable, error and focus*/
const conditionsBottomColor = (error, state, onlyRead) => {
  if (state.isDisabled && !onlyRead) {
    return '1px solid var(--secondary-mid-2)';
  } else if (onlyRead) {
    return '1px solid var(--secondary)';
  } else {
    if (error) {
      return '2px solid var(--red)';
    } else {
      if (state.isFocused) {
        return '2px solid var(--primary)';
      } else {
        return '1px solid var(--secondary-mid-1)';
      }
    }
  }
};
/*Conditions for the color of the arrow to change in cases of disable, error and focus*/
const conditionsArrowColor = (error, state, onlyRead) => {
  if (state.isDisabled && !onlyRead) {
    return ' var(--secondary-mid-2)';
  } else if (onlyRead) {
    return ' var(--white)';
  } else {
    if (error) {
      return 'var(--red)';
    } else {
      if (state.isFocused) {
        return 'var(--primary)';
      } else {
        return 'var(--secondary-mid-1)';
      }
    }
  }
};

const customStyles = (error, onlyRead) => {
  return {
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0px',
      background: state.isDisabled && 'var(--white)',
      '&:hover': {
        borderColor: error ? 'var(--red)' : 'var(--primary)',
      },
      borderBottom: conditionsBottomColor(error, state, onlyRead),
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: '14px',
      };
    },
    multiValue: (styles, { isDisabled }) => {
      return {
        ...styles,
        borderRadius: '50px',
        backgroundColor: '#949494',
        color: isDisabled ? '#949494' : 'white',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
      fontSize: '10px',
    }),
    singleValue: (styles, { data }) => ({ ...styles, color: onlyRead && 'var(--secondary)' }),
    menu: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      color: 'var(--secondary)',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: conditionsArrowColor(error, state, onlyRead),
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };
};

const customTheme = (theme, error) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: error ? 'var(--red)' : 'var(--primary)',
      primary75: error ? 'var(--red)' : 'var(--primary-dark)',
      primary50: error ? 'var(--primary-dark)' : 'var(--primary-light)',
      primary25: error ? 'var(--primary)' : 'var(--secondary-light-1)',
      danger: 'var(--red)',
      neutral50: 'var(--secondary-mid-1)',
    },
    borderRadius: 0,
  };
};
const Dropdown = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  placeholder,
  options,
  isMulti,
  disabled,
  onChange,
  supportText,
  onlyRead,
  ...props
}) => {
  return (
    <div
      className={`o-dropdown-group ${
        !disabled && touched[field.name] && errors[field.name] && 'o-dropdown-error'
      }`}
      style={{ cursor: disabled && 'not-allowed' }}
    >
      <label className="o-dropdown-label">{label}</label>
      <Select
        {...field}
        styles={customStyles(errors[field.name] && touched[field.name], onlyRead)}
        theme={(theme) => customTheme(theme, errors[field.name] && touched[field.name], onlyRead)}
        placeholder={placeholder}
        options={options}
        onBlur={() => {
          setFieldTouched(field.name, true);
        }}
        onChange={
          onChange
            ? onChange
            : (e) => {
                setFieldValue(field.name, e);
              }
        }
        isDisabled={onlyRead || disabled}
        searchable={false}
        isMulti={isMulti}
        noOptionsMessage={() => 'Pas d’options'}
        {...props}
      />
      <span className="o-dropdown-message">
        {touched[field.name] && errors[field.name] ? errors[field.name] : supportText}
      </span>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedOption: PropTypes.func,
  errorText: PropTypes.string,
  supportText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropdown;
