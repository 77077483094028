import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columnsHistoryTable, dummyDataHistoryTable } from './client.utils';
import { ArrowRight } from '../../../assets/icons/icons-arrow';

const ClientHistory = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Historique Client
      </Fragment>
    );
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-content">
        <Table
          showModal={showModal}
          setShowModal={setShowModal}
          columns={columnsHistoryTable}
          tableData={dummyDataHistoryTable}
          textLeft
        />
      </div>
    </div>
  );
};

export default ClientHistory;
