import { endpoint, BASE_URL } from '../constants.js';
import { localStorageKeys } from '../constants.js';
import { toast } from 'react-toastify';
import axios from 'axios';
var qs = require('qs');

export async function refundTransaction(clientId, transactionId, commentText) {

  var data = JSON.stringify({"payinId": transactionId,"comment": commentText});

  var config = {
    method: 'post',
    url: BASE_URL + endpoint.client + '/' + clientId + "/payins/refunds"  + endpoint.key,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token),
    },
    data
  };
  return axios(config)
    .then(function (response) {
      if (response.status === 200) {
        toast('succès');
        return true;
      }
      return false;
    })
    .catch(function (error) {
      console.log(error);
      toast('error' + error.response.data?.error?.message);
      return false;
    });
}
