import React, { useState, useEffect, useContext, Fragment  } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { InputFormik } from '../../../components/input';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import TablePage from '../../../components/tablepage';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columns, renderTable, tableSearch } from './client.utils';
import { localStorageKeys } from '../../../api/constants.js';
import { searchClientPageReq } from '../../../api/client/searchClient';
import IconLoading from '../../../assets/icons/loading.svg';
import IconDoc from '../../../assets/icons/doc.svg';

const Client = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [formValues, setFormValues] = useState({});

  const updateTableDataFromLocalStorage = () => {
    let searchedClients = localStorage.getItem(localStorageKeys.clients_searched);
    var tableDataList = [];
    try {
      tableDataList = renderTable(JSON.parse(searchedClients))
    } catch (e) {
      console.log('Problème de parsing de ' + searchedClients);
    } finally {
      setTableData(tableDataList);
    }
  }

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      updateTableDataFromLocalStorage();
    }
    setLoading(false);
    setDataStatus('done');
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  const downloadCsv = async () => {
    // if (status) {
    //   setCsvLoading(true);
    //   const res = await getClientByStatusDownload(status)
    //   .finally(() => setCsvLoading(false));
    // }
  }

  const searchUserHandler = (values) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(values);
    if (Object.keys(values).length === 0 && Object.getPrototypeOf(values) === Object.prototype) {
      updateTableDataFromLocalStorage();
      setLoading(false);
      setDataStatus('done');
    } else {
      searchClientPageReq(values)
        .then((res) => {
          /*Render and set data of the list*/
          let renderResult = JSON.stringify(res.content);
          localStorage.setItem(localStorageKeys.clients_searched, renderResult);
          setPageMetaData(res.pageable);
          setUserPageMetaData({count:res.totalElements});
          setTableData(renderTable(res.content));
        })
        .finally(() => {
          setLoading(false);
          setDataStatus('done');
        });
    }
  }

  const refreshData = (pageNo, pageSize) => {
    searchUserHandler(formValues, pageNo, pageSize);
  }

  function SearchForm() {
    return (
      <Fragment>
          <div className="ml-5">
          <Formik
            enableReinitialize={true}
            initialValues={SearchUserInitialValues}
            validationSchema={SearchUserSchema}
            onSubmit={(values) => {
              searchUserHandler(values);
            }}
          >
          <Form className="form-inline">
              <div className="form-group">
              <Field
                  name="firstname"
                  className="mb-2 ml-5"
                  label='firstname'
                  placeholder='firstname'
                  component={InputFormik}
                />
              <Field
                  name="lastname"
                  className="mb-2 ml-5"
                  label='lastname'
                  placeholder='lastname'
                  component={InputFormik}
                />
                <Field
                    name="email"
                    className="mb-2 ml-5"
                    label='email'
                    placeholder='email'
                    component={InputFormik}
                  />
                <Field
                    name="mobile"
                    className="mb-2 ml-5"
                    label='mobile'
                    placeholder='mobile'
                    component={InputFormik}
                  />
                <Field
                    name="company_name"
                    className="mb-2 ml-5"
                    label='Nom Entreprise'
                    placeholder='Nom Entreprise'
                    component={InputFormik}
                  />
                <Button className="ml-5" type="submit">
                {loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
              </div>
            </Form>
          </Formik>
          </div>
        <div className="ml-auto">
        <Button btnType="outline" className="ml-4 hidden"
          onClick={() => {
            // Pour l'instant bouton hidden avant de mettre en place le service
            //downloadCsv();
          }}
        >
          {
            csvLoading ?
              <img className="mr-2" src={IconLoading} height="25" alt="" /> :
              <img className="mr-2" src={IconDoc} alt="" />
          }
        Télécharger CSV
        </Button>
        </div>
      </Fragment>
    );
  }

  const SearchUserInitialValues = {
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    company_name: '',
  };
  const SearchUserSchema = Yup.object().shape({
  });

  return (
    <div>
      <Header
        headerTitle={t('sidebar:client')}
        HeaderIcon={IconClient}
        setSelectedID={setSelectedID}
        isAdd={false}
        rightFragment={SearchForm()}
        disabledSearch={true}
      />
      <div className="o-content">
        <TablePage
          columns={columns}
          tableData={tableData}
          pageMetaData={userPageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => 'Clients'}
          contain
        />
      </div>
    </div>
  );
};

export default Client;
