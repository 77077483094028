import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { createFeesToCollectClient } from '../../../../../api/client/billing';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationCreateFutureFees = ({ createFutureFeesOpen, setCreateFutureFeesOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const createFutureFeesHandler = async (userId, data) => {
    setLoading(true);
    console.log(data);
    data['userId'] = userId;
    let status = await createFeesToCollectClient(userId, data);
    setLoading(false);
    if(status === 'success'){
      setCreateFutureFeesOpen(false);
      refreshData();
    }
  }

  const CollectFeesInitialValues = {
    label: 'Frais',
    amount: '',
  };
  const CollectFeesSchema = Yup.object().shape({
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={createFutureFeesOpen} toggle={setCreateFutureFeesOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={CollectFeesInitialValues}
      validationSchema={CollectFeesSchema}
      onSubmit={(values) => {
        createFutureFeesHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Saisir les informations de l'élément de facturation
          <br/>ATTENTION : La facturation sera effectuée le soir ou dès que le wallet sera suffisamment alimenté
          </div>
          <Field
            name="label"
            className="mt-3"
            label="Libellé des frais"
            placeholder="Libellé des frais *"
            component={InputFormik}
          />
          <Field
            name="amount"
            className="mt-3"
            label="Montant des frais (€)"
            placeholder="Montant des frais en €"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setCreateFutureFeesOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationCreateFutureFees;
