import { endpoint } from '../../constants.js';
import { CheckAuth, instance } from '../../index';
import { toast } from 'react-toastify';
import axios from 'axios';
var qs = require('qs');

export async function CancelPayoutTransaction(userId, payoutId, confirmName) {
  try {
    const res = await instance.delete(endpoint.payoutDetail.replace('{{userId}}', userId).replace('{{payoutId}}', payoutId) + endpoint.key + '&client_name=' + encodeURIComponent(confirmName));
    if (res.status === 200) {
      toast('Le virement a été supprimé avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! ' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
