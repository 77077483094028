import moment from 'moment';

function renderUserWallets(array){
  return array.map((wallet, index) =>
  <li>
  { wallet.solde < 0 ?
    <span className="o-red">{((wallet.solde).toFixed(2) + '€')}</span>
    : <span className="o-green">{((wallet.solde).toFixed(2) + '€')}</span>
  }
  { ' ' + wallet.name + ' (' + wallet.walletId + ')'}
  </li>);
}

export const tableSearchUserOverdraft = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.debitor.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const tableUserOverdraftHeader = ['Débiteur', 'Solde de wallet'];

export const renderUserOverdraftTable = (res, setDeleteModal, setCurrentItem) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.userId,
      debitor: item.firstname + ' ' + item.lastname + ' (' + item.userId + ')' + ' - ' + item.userStatus,
      wallets_overdraft: renderUserWallets(item.wallets),
    }),
  );
  return data;
};
