import React from 'react';
import IconCheck from '../../../assets/icons/check.svg';
import IconCancel from '../../../assets/icons/cancel.png';
import IconRedCross from '../../../assets/icons/red-cross.svg';

import moment from 'moment';

export const columns = (status) => {
  if (status === 'onboarding_finalized') {
    return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Virement reçu'];
  } else if (status === 'onboarding_waiting_payin') {
    return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Virement reçu'];
  } else if (status === 'validated') {
    return ['Création','Date de Validation', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Fraude Suspect'];
  } else {
    return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Fraude Suspect'];
  }
}

export const orderByParams = (status) => {
  if (status === 'validated') {
    return { "order_by" : "validationDate", "order_direction" : "desc"};
  } else {
    return { "order_by" : "updated", "order_direction" : "desc"};
  }
}

export const tableSearch = (status, tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
      return (
        value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.firstname.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        value.address.toLowerCase().includes(searchInput.toLowerCase())
      );
    });

  setFilteredData(result);
};

export const renderTable = (status, res) => {
  let data = [];
  if (status === 'onboarding_finalized') {
    res.map((item) =>
      data.push({
        key: item.userId,
        createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
        updatedDate: moment(item.updated).format('DD/MM/YYYY HH:mm'),
        id: item.userId + ' (' + item.trzUserId + ')',
        email: item.email,
        firstname: item.firstname + ' ' + item.lastname,
        birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
        address : item.postcode + ' ' + item.city,
        status: item.userStatus,
        blocked: item?.blocked ? 'Oui' : 'Non',
        receiverPayin: item?.payin_received ? <img className="mr-2" src={IconCheck} alt="" /> : <img className="mr-2" src={IconRedCross} alt="" />,
      }),
    );
  } else if (status === 'onboarding_waiting_payin') {
    res.map((item) =>
      data.push({
        key: item.userId,
        createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
        updatedDate: moment(item.updated).format('DD/MM/YYYY HH:mm'),
        id: item.userId + ' (' + item.trzUserId + ')',
        email: item.email,
        firstname: item.firstname + ' ' + item.lastname,
        birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
        address : item.postcode + ' ' + item.city,
        status: item.userStatus,
        blocked: item?.blocked ? 'Oui' : 'Non',
        receiverPayin: item?.payin_received ? <img className="mr-2" src={IconCheck} alt="" /> : <img className="mr-2" src={IconRedCross} alt="" />,
      }),
    );
  } else if (status === 'validated') {
    res.map((item) =>
      data.push({
        key: item.userId,
        createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
        updatedDate: item.validation_date ? moment(item.validation_date).format('DD/MM/YYYY HH:mm') : '',
        id: item.userId + ' (' + item.trzUserId + ')',
        email: item.email,
        firstname: item.firstname + ' ' + item.lastname,
        birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
        address : item.postcode + ' ' + item.city,
        status: item.userStatus,
        blocked: item?.blocked ? 'Oui' : 'Non',
        fraudWarning: item?.fraud_warning ? 'Oui' : 'Non',
      }),
    );
  } else {
    res.map((item) =>
      data.push({
        key: item.userId,
        createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
        updatedDate: moment(item.updated).format('DD/MM/YYYY HH:mm'),
        id: item.userId + ' (' + item.trzUserId + ')',
        email: item.email,
        firstname: item.firstname + ' ' + item.lastname,
        birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
        address : item.postcode + ' ' + item.city,
        status: item.userStatus,
        blocked: item?.blocked ? 'Oui' : 'Non',
        fraudWarning: item?.fraud_warning ? 'Oui' : 'Non',
      }),
    );
  }

  return data;
};
