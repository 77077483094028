import { endpoint, backendApiEndpoint } from '../constants.js';
import { CheckAuth, instance, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';



export async function createTransferReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.transfers, data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function getScheduledTransferReq(ooUserId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.scheduled_transfers.replace('{{userId}}' , ooUserId));
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function deleteScheduledTransferReq(ooUserId, ooScheduledTransferId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.scheduled_transfers.replace('{{userId}}' , ooUserId).replace('{{scheduledTransferId}}' , ooScheduledTransferId));
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}
