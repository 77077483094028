import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import IconLoading from '../../../../assets/icons/loading.svg';
import { updatePayinAlertStatusReq } from '../../../../api/monitoring/monitoring';
import { AlertFraudStatus } from '../../../common.utils';
import Dropdown from '../../../../components/dropdown';

const ConfirmationUpdateAlertStatus = ({ item, updateAlertStatusModal, setUpdateAlertStatusModal, refreshData }) => {
  const [loading, setLoading] = useState(false);

  const changeUpdateAlertStatusHandler = async (data) => {
    setLoading(true);
    let status = await updatePayinAlertStatusReq({
      alertfraudid: item.alertfraudid,
      status: data.status.value,
      comment: data.comment,
    }).finally(() => {
      setLoading(false);
    });
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setUpdateAlertStatusModal(false);
  }

  const UpdateAlertStatusInitialValues = () => {
    const initStatus = item?.status ? AlertFraudStatus.find((x) => x.value === item?.status) : AlertFraudStatus[0];
    return {
      status : initStatus,
    }
  };
  const UpdateAlertStatusSchema = Yup.object().shape({
    status: Yup.object().required('Requis'),
    comment: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateAlertStatusModal} toggle={setUpdateAlertStatusModal}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateAlertStatusInitialValues()}
      validationSchema={UpdateAlertStatusSchema}
      onSubmit={(values) => {
        changeUpdateAlertStatusHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
        <div className="o-title text-center mb-4">Modifier le status de l'Alerte Fraude.</div>
          <Field
            name="status"
            className="mt-2"
            label="Status de l'alerte' *"
            placeholder="Status de l'alerte' *"
            component={Dropdown}
            options={AlertFraudStatus}
          />
          <Field
            name="comment"
            className="mt-5"
            label="Commentaire *"
            placeholder="Commentaire *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateAlertStatus;
