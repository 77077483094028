import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientReviewKycLivenessReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationReviewKycLiveness = ({ reviewKycLivenessOpen, setReviewKycLivenessOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const reviewKycLivenessUrlHandler = async (id, data) => {
    setLoading(true);
    let status = await clientReviewKycLivenessReq(id, false);
    setLoading(false);
    console.log(status);
    if (status === 'success') {
      setReviewKycLivenessOpen(false)
      refreshData()
    }
  }

  const AskKycLivenessURLInitialValues = {
  };
  const AskKycLivenessURLSchema = Yup.object().shape({
    //redirect_url: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={reviewKycLivenessOpen} toggle={setReviewKycLivenessOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={AskKycLivenessURLInitialValues}
      validationSchema={AskKycLivenessURLSchema}
      onSubmit={(values) => {
        console.log('Demander la Review auprès de Treezor');
        reviewKycLivenessUrlHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Confirmez-vous que vous voulez demander la Review de ce client</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setReviewKycLivenessOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationReviewKycLiveness;
