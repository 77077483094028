import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../../components/dropdown';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import IconCheck from '../../../../../assets/icons/check.svg';
import IconCross from '../../../../../assets/icons/cross.svg';
import { orderVirtualCardReq } from '../../../../../api/client/card/card';
import { clientWalletListReq } from '../../../../../api/client/wallet';
import { clientMembersReq } from '../../../../../api/client/detailClient';

const ConfirmationOrderVirtualCard = ({ userId, orderVirtualCardModal, setOrderVirtualCardModal, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');

  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];
  const [userListDrp, setUserListDrp] = useState();
  let userOptions = [];

  useEffect(() => {
    if (orderVirtualCardModal) {
      // On doit charger la liste des wallets pouvant avoir une carte
      clientWalletListReq(userId).then((res) => {
        res.map((item) => walletOptions.push({ label: item.walletId + ' - ' + item.name, value: item.walletId }));
        setWalletListDrp(walletOptions);
      });

      // on doit charger la liste des membres pouvant être associés à une carte
      let params = {
        only_physical : true,
      }
      clientMembersReq(userId, params).then((res) => {
        res.map((item) => userOptions.push({ label: item.userId + ' - ' + item.firstname + ' ' + item.lastname, value: item.userId }));
        setUserListDrp(userOptions);
      });
    }
  }, [orderVirtualCardModal]);

  const orderVirtualCardAccountHandler = async (data) => {
    setLoading(true);
    let walletID = null;
    const status = await orderVirtualCardReq(data.user_id?.value, data.wallet_id?.value);
    if (status === 'success') {
      refreshData();
      close();
    }
    setLoading(false);
  }

  const close = () => {
    setOrderVirtualCardModal(false);
    setResponseText('');
  }

  const ShowResponseText = () => (
    <div style={{textAlign: 'center', margin: "20px auto 30px auto"}}>
      {responseText} <br />
      {
      responseText === 'success' ?
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCheck} alt="" /> :
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCross} alt="" />
      }

    </div>
  );

  const OrderCardInitialValues = {
    user_id: [],
    wallet_id: [],
  };
  const OrderCardSchema = Yup.object().shape({
    user_id: Yup.object().required('Requis'),
    wallet_id: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={orderVirtualCardModal} toggle={setOrderVirtualCardModal}>
    <Formik
      enableReinitialize={true}
      initialValues={OrderCardInitialValues}
      validationSchema={OrderCardSchema}
      onSubmit={(values) => {
        orderVirtualCardAccountHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Commander une nouvelle carte VIRTUELLE</div>
          <Field
            name="user_id"
            label="Porteur de la carte"
            placeholder="Porteur de la carte *"
            options={userListDrp}
            component={Dropdown}
          />
          <Field
            name="wallet_id"
            label="Wallet associé"
            placeholder="Wallet associé *"
            options={walletListDrp}
            component={Dropdown}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationOrderVirtualCard;
