import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconDoc from '../../../assets/icons/doc.svg';
import IconLoading from '../../../assets/icons/loading.svg';
import { columnsHistoryHttpTable, renderHistoryHttpTable } from './client.utils';
import { columnsSummaryHttpTable, renderSummaryHttpTable } from './client.utils';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import { historyClientHttpReq, summaryClientHttpReq, downloadClientHttpHistoryReq, summaryByCountryClientHttpReq, summaryByPlateformClientHttpReq } from '../../../api/client/historyClient';

import {  Chart as ChartJS,  CategoryScale,  LinearScale,  PointElement,  LineElement,
  BarElement,  Title,  Tooltip,  Legend , ArcElement} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Line, Pie } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,
  BarElement, Title, Tooltip, Legend , ArcElement);

const pieData = (res) => {
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.country)
  );
  let datas = [];
  res.map((item) =>
    datas.push(item.count)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: '# / Pays',
      data: datas,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
  };
  return result;
};

const piePlateformData = (res) => {
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.plateform)
  );
  let datas = [];
  res.map((item) =>
    datas.push(item.count)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: '# / Plateform',
      data: datas,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
  };
  return result;
};

const emptyPieData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

const emptyPiePlateformData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

const ClientHistoryHttp = () => {
  const { id } = useParams();
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState([]);
  const [pieChartData, setPieChartData] = useState(emptyPieData);
  const [pieChartPlateformData, setPieChartPlateformData] = useState(emptyPiePlateformData);
  const [pieChartLoading, setPieChartLoading] = useState(false);
  const [pieChartPlateformLoading, setPieChartPlateformLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);

  useEffect(() => {
    historyClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setTableHistoryData(renderHistoryHttpTable(res));
      })
      .finally(() => setStatus('done'));

    summaryClientHttpReq(id)
        .then((res) => {
          //console.log(res);
          setTableSummaryData(renderSummaryHttpTable(res));
        })
        .finally(() => setStatus('done'));

    setPieChartLoading(true);
    summaryByCountryClientHttpReq(id)
            .then((res) => {
              //console.log(res);
              setPieChartData(pieData(res));
            })
            .finally(() => setPieChartLoading(false));

    setPieChartPlateformLoading(true);
    summaryByPlateformClientHttpReq(id)
            .then((res) => {
              //console.log(res);
              setPieChartPlateformData(piePlateformData(res));
            })
            .finally(() => setPieChartPlateformLoading(false));
  }, []);


  const downloadCsv = async (userId) => {
    if (userId) {
      setCsvLoading(true);
      const res = await downloadClientHttpHistoryReq(userId);
      setCsvLoading(false);
    }
  }

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Historique Http Client
      </Fragment>
    );
  }

  function RightTitle() {
    return (
      <Button btnType="outline" className="ml-4 mb-4"
        onClick={() => {
          downloadCsv(id);
        }}
      >
        {
          csvLoading ?
            <img className="mr-2" src={IconLoading} height="25" alt="" /> :
            <img className="mr-2" src={IconDoc} alt="" />
        }
      Télécharger CSV
      </Button>
    );
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch rightFragment={RightTitle()}/>
      <div className="d-flex o-content justify-content-center">
        <div>
        {
          pieChartLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Pie data={pieChartData} />
        }
        </div>
        <div>
        {
          pieChartPlateformLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Pie data={pieChartPlateformData} />
        }
        </div>
      </div>
      <div className="d-flex o-content">
        <div className="flex-fill mr-2">
          <Table
            showModal={showModal}
            setShowModal={setShowModal}
            columns={columnsSummaryHttpTable}
            tableData={tableSummaryData}
            status={status}
            textLeft
            ContainHeader={() => (
              <div className="mb-4 mt-2">
                <b>Historique Résumé</b>
              </div>
            )}
            contain
            itemPerPage={10}
          />
          </div>
          <div className="flex-fill">
          <Table
            showModal={showModal}
            setShowModal={setShowModal}
            columns={columnsHistoryHttpTable}
            tableData={tableHistoryData}
            status={status}
            textLeft
            ContainHeader={() => (
              <div className="mb-4 mt-2">
                <b>Historique Total</b>
              </div>
            )}
            contain
            itemPerPage={10}
          />
          </div>
      </div>
    </div>
  );
};

export default ClientHistoryHttp;
