import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { GroupSchema, GroupInitialValues } from '../group.utils';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import Dropdown from '../../../../components/dropdown';
import { getPermissionsReq } from '../../../../api/permission/permissions';
import { getGroupDetailReq, addGroupReq } from '../../../../api/group/groups';
import IconLoading from '../../../../assets/icons/loading.svg';

const AddGroup = ({ addModal, setAddModal, selectedID }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('loading');
  const [permissionDrp, setPermissionDrp] = useState();
  const [currentItem, setCurrentItem] = useState();
  let permissionOptions = [];
  useEffect(() => {
    if (selectedID) {
      setDataStatus('loading');
      getGroupDetailReq(selectedID)
        .then((res) => {
          let permissions = [];
          res.permissions &&
            res.permissions.map((item) => permissions.push({ label: item.name, value: item.code }));
          setCurrentItem({
            name: res.name,
            description: res.description,
            level: res.level,
            permissions: permissions,
          });
        })
        .finally(() => setDataStatus('done'));
    }
    getPermissionsReq()
      .then((res) => {
        /*Render and set data of the list*/
        res.map((item) => permissionOptions.push({ label: item.name, value: item.code }));
        setPermissionDrp(permissionOptions);
      })
      .finally(() => setDataStatus('done'));
  }, [selectedID]);
  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : GroupInitialValues}
        validationSchema={GroupSchema}
        onSubmit={(values) => {
          let permissionList = [];
          values.permissions && values.permissions.map((item) => permissionList.push(item.value));

          addGroupReq(
            selectedID
              ? {
                  id: selectedID,
                  name: values.name,
                  description: values.description,
                  level: values.level,
                  permissions: permissionList,
                }
              : {
                  name: values.name,
                  description: values.description,
                  level: values.level,
                  permissions: permissionList,
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/groups';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {selectedID ? t('group:modify_group') : t('group:new_group')}
            </div>
            {dataStatus === 'loading' ? (
              <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="name"
                  className="mt-5"
                  label={t('group:name')}
                  placeholder={t('group:name_placeholder') + ' *'}
                  component={InputFormik}
                />
                <Field
                  name="description"
                  className="mt-5"
                  label={t('group:description')}
                  placeholder={t('group:description_placeholder') + ' *'}
                  component={InputFormik}
                />
                <Field
                  name="level"
                  className="mt-5"
                  label={t('group:level')}
                  placeholder={t('group:level_placeholder') + ' *'}
                  component={InputFormik}
                  type="number"
                />
                <Field
                  name="permissions"
                  label={t('group:permission') + ' *'}
                  placeholder={t('group:permission_placeholder') + ' *'}
                  options={permissionDrp}
                  component={Dropdown}
                  isMulti
                />
                <div className="d-flex flex-column mt-5">
                  <Button className="mb-2" type="submit" disabled={!(props.isValid && props.dirty)}>
                    {selectedID ? t('global:validate') : t('global:add')}
                  </Button>
                  <Button
                    btnType="outline"
                    type="button"
                    onClick={() => {
                      setAddModal(false);
                    }}
                  >
                    {t('global:cancel')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddGroup;
