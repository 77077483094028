import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { CancelPayoutTransaction } from '../../../../../api/client/wallet/cancelPayout';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationCancelPayout = ({ cancelPayoutOpen, setCancelPayoutOpen, userId, payoutId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (userId, payoutId, confirmName) => {
    setLoading(true);
    let status = await CancelPayoutTransaction(userId, payoutId, confirmName);
    setLoading(false);
    setCancelPayoutOpen(false)
    if (status == 'success') {
      refreshData()
    }
  }

  const CancelPayoutInitialValues = {
    confirmLastnameUppercase: ''
  };
  const CancelPayoutSchema = Yup.object().shape({
    confirmLastnameUppercase: Yup.string().required('Requis')
  });

  return (
    <Modal isOpen={cancelPayoutOpen} toggle={setCancelPayoutOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={CancelPayoutInitialValues}
      validationSchema={CancelPayoutSchema}
      onSubmit={(values) => {
        console.log('Delete client submit');
        deleteHandler(userId, payoutId, values['confirmLastnameUppercase']).then((res) => {
          if (res === 'success') {
            setCancelPayoutOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer l'annulation du virement externe du client, veuillez saisir en majuscule le nom du client</div>
          <Field
            name="confirmLastnameUppercase"
            className="mt-5"
            label="Nom du client en MAJUSCULE *"
            placeholder="Nom du client en MAJUSCULE *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setCancelPayoutOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationCancelPayout;
