import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function searchClientPageReq(data, status, pageNo, pageSize) {
  pageNo = pageNo == null ? 0 : pageNo;
  pageSize = pageSize == null ? 100 : pageSize;
  if (status) {
    data["userStatus"] = status.toUpperCase();
  }
  let queryParams = {
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    params: {
      pageNo : pageNo,
      pageSize : pageSize,
      sortBy : status === 'validated' ? 'validationDate' : 'updated',
      sortDirection : 'DESC',
  }};
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_search_page, JSON.stringify(data), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function searchClientByCodePromo(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_search_by_code_promo, data);
    if (res.status === 200) {
      return res.data.sort((a,b) => a.code_promo > b.code_promo ? 1 : -1);
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function getClientByCodePromoDownload(data) {
  try {
    let codePromo = data["code_promo"] ? data["code_promo"] : 'all';
    const res = await instanceBackendApi.post(backendApiEndpoint.client_search_by_code_promo_download, data);
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "text/csv" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', 'users_code_promo_' + codePromo + '.csv')
      document.body.appendChild(link)
      link.click()
      //const csvWindow = window.open();
      //csvWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    return 'fail';
  }
}
