import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { addPermissionReq } from '../../../../api/permission/permissions';
import { PermissionSchema, PermissionInitialValues } from '../permission.utils';

const AddPermission = ({ addModal, setAddModal, currentItem }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentItem ? currentItem : PermissionInitialValues}
        validationSchema={PermissionSchema}
        onSubmit={(values) => {
          addPermissionReq(values).then((res) => {
            setAddModal(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {currentItem ? t('permission:modify_permission') : t('permission:new_permission')}
            </div>
            <Field
              name="code"
              className="mt-5"
              label={t('permission:unique_code')}
              placeholder={t('permission:unique_code_placeholder') + ' *'}
              component={InputFormik}
              disabled={currentItem}
            />
            <Field
              name="name"
              className="mt-5"
              label={t('permission:descriptive_name')}
              placeholder={t('permission:descriptive_name_placeholder') + ' *'}
              component={InputFormik}
            />
            <Field
              name="description"
              className="mt-5"
              label={t('permission:full_description')}
              placeholder={t('permission:full_description_placeholder') + ' *'}
              component={InputFormik}
            />
            <div className="d-flex flex-column mt-5">
              <Button className="mb-2" type="submit" disabled={!(props.isValid && props.dirty)}>
                {currentItem ? t('global:validate') : t('global:add')}
              </Button>
              <Button
                btnType="outline"
                type="button"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddPermission;
