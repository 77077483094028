import moment from 'moment';

export const tableCardDigitalizationsHeader = ['IDs','Date création', 'Device', 'Statut','Activation Code','Plateforme'];

export const renderCardDigitalizationsTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.carddigitalizationid,
      ids: item.carddigitalizationid + ' | ' + item.trz_card_digitalization_id,
      created_date: item.created_date,
      label: item.device_name + ' - ' + item.device_type,
      status: item.status,
      activation_code: item.activation_code,
      plateform: item.token_requestor,
    }),
  );
  return data;
};
