import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { DocumentSchema, DocumentInitialValues, YesNo } from '../documenttype.utils';
import { addDocumentReq, detailDocumentReq } from '../../../../api/documenttype/documenttype';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../assets/icons/loading.svg';

const AddDocument = ({ addModal, setAddModal, selectedID, config }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('done');
  const [currentItem, setCurrentItem] = useState();
  useEffect(() => {
    if (selectedID) {
      setDataStatus('loading');
      detailDocumentReq(selectedID)
        .then((res) => {
          setCurrentItem({
            name: res.name,
            order: res.order,
            activated: res.activated ? YesNo[0] : YesNo[1],
            rectoVerso: res.rectoVerso ? YesNo[0] : YesNo[1],
            level: res.level && config.securityLevels.filter((e) => e.value === res.level)[0],
            trzDocumentType:
              res.level && config.trzDocumentType.filter((e) => e.value === res.trzDocumentType)[0],
            vialinkDocumentType:
              res.level &&
              config.vlDocumentType.filter((e) => e.value === res.vialinkDocumentType)[0],
          });
        })
        .finally(() => setDataStatus('done'));
    }
  }, [selectedID]);
  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : DocumentInitialValues}
        validationSchema={DocumentSchema}
        onSubmit={(values) => {
          addDocumentReq(
            selectedID
              ? {
                  documentTypeId: selectedID,
                  name: values.name,
                  trzDocumentType: values.trzDocumentType ? values.trzDocumentType.value : null,
                  vialinkDocumentType: values.vialinkDocumentType ? values.vialinkDocumentType.value : null,
                  activated: values.activated.value,
                  rectoVerso: values.rectoVerso.value,
                  order: values.order,
                  level: values.level.value,
                }
              : {
                  name: values.name,
                  trzDocumentType: values.trzDocumentType ? values.trzDocumentType.value : null,
                  vialinkDocumentType: values.vialinkDocumentType ? values.vialinkDocumentType.value : null,
                  activated: values.activated.value,
                  rectoVerso: values.rectoVerso.value,
                  order: values.order,
                  level: values.level.value,
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/documents';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {selectedID ? t('document:modify_document') : t('document:new_document')}
            </div>
            {dataStatus === 'loading' ? (
              <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="name"
                  className="mt-5"
                  label={t('document:name')}
                  placeholder={t('document:name_placeholder') + ' *'}
                  component={InputFormik}
                />

                <Field
                  name="activated"
                  label={t('document:activated')}
                  placeholder={t('document:activated') + ' *'}
                  options={YesNo}
                  component={Dropdown}
                />
                <Field
                  name="order"
                  className="mt-5"
                  label={t('document:order')}
                  placeholder={t('document:order_placeholder') + ' *'}
                  type="number"
                  component={InputFormik}
                />
                <Field
                  name="level"
                  label={t('document:level')}
                  placeholder={t('document:level') + ' *'}
                  options={config && config.securityLevels}
                  type="number"
                  component={Dropdown}
                />
                <Field
                  name="rectoVerso"
                  label={t('document:both_side')}
                  placeholder={t('document:both_side') + ' *'}
                  options={YesNo}
                  component={Dropdown}
                />
                <Field
                  name="trzDocumentType"
                  label={t('document:treezor')}
                  placeholder={t('document:treezor')}
                  options={config.trzDocumentType}
                  component={Dropdown}
                />
                <Field
                  name="vialinkDocumentType"
                  label={t('document:vialink')}
                  placeholder={t('document:vialink')}
                  options={config.vlDocumentType}
                  component={Dropdown}
                />
                <div className="d-flex flex-column mt-5">
                  <Button className="mb-2" type="submit" disabled={!(props.isValid && props.dirty)}>
                    {selectedID ? t('global:validate') : t('global:add')}
                  </Button>
                  <Button
                    btnType="outline"
                    type="button"
                    onClick={() => {
                      setAddModal(false);
                    }}
                  >
                    {t('global:cancel')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddDocument;
