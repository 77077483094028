import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { ONLYONE_WEB_APP_BASE_URL } from '../../../../../utils/constants-env.js';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { collectClientBillingHistoryReq } from '../../../../../api/client/billing';
import { clientWalletListReq } from '../../../../../api/client/wallet';
import IconLoading from '../../../../../assets/icons/loading.svg';
import Dropdown from '../../../../../components/dropdown';

const ConfirmationCollectBillingsHistory = ({ collectBillingsHistoryOpen, setCollectBillingsHistoryOpen, userId, userFeatureHistory, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];

  useEffect(() => {
    //for wallet dropdown
    clientWalletListReq(userId).then((res) => {
      /*Render and set data of the list*/
      res.map((item) => walletOptions.push({ label: item.walletId + ' - ' + item.name + ' - ' + item.authorized_balance + ' €', value: item.walletId }));
      setWalletListDrp(walletOptions);
    });
  }, []);

  const collectBillingsHistoryHandler = async (id, data) => {
    setLoading(true);
    // Conversion des données du formulaire
    let postData = {
      user_feature_history_id : data?.user_feature_history_id,
      walletId : data?.wallet.value,
      amount_with_vat : data?.amount_with_vat,
      description : data?.description,
    };
    let responseData = await collectClientBillingHistoryReq(id, userFeatureHistory?.user_feature_history_id, postData);
    setLoading(false);
    console.log(responseData);
    if (responseData === 'success') {
      refreshData();
      setDetailData(responseData);
    } else {
      setDetailData(null);
    }
  }

  const CollectBillingsHistoryInitialValues = {
    user_feature_history_id : userFeatureHistory?.user_feature_history_id,
    amount_with_vat : (userFeatureHistory?.amount? Math.round(userFeatureHistory?.amount*(1+userFeatureHistory?.vat.rate)):'0'),
    description : 'Facturation ' + (userFeatureHistory?.feature ? userFeatureHistory?.feature.name : ''),
  };
  const CollectBillingsHistorySchema = Yup.object().shape({
    user_feature_history_id: Yup.string().required('Requis'),
    wallet: Yup.object().required('Requis'),
    amount_with_vat: Yup.number().required('Requis').min(0),
    description: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={collectBillingsHistoryOpen} toggle={setCollectBillingsHistoryOpen}>
    { detailData ? (
      <div>
      <div className="o-information-detail__item mb-2">
        <div>Facturation</div>
        <div className="ml-2">Facturation effectuée</div>
      </div>
      <Button
        btnType="outline"
        type="button"
        onClick={() => {
          setDetailData(null);
          setCollectBillingsHistoryOpen(false);
        }}
      >Fermer</Button>
      </div>
): (<Formik
      enableReinitialize={true}
      initialValues={CollectBillingsHistoryInitialValues}
      validationSchema={CollectBillingsHistorySchema}
      onSubmit={(values) => {
        console.log('Récupération d\'une URL de KycLiveness');
        collectBillingsHistoryHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Saisir les informations de la facturation</div>
          <Field
            name="user_feature_history_id"
            className="mt-5"
            label="Identifiant de facturation"
            placeholder="Identifiant de facturation"
            component={InputFormik}
            onlyRead
          />
          <Field
            name="wallet"
            label="Wallet source"
            placeholder="Wallet source *"
            options={walletListDrp}
            component={Dropdown}
          />
          <Field
            name="amount_with_vat"
            className="mt-5"
            label="Montant prélevé en € TTC *"
            placeholder="Montant prélevé *"
            component={InputFormik}
          />
          <Field
            name="description"
            className="mt-5"
            label="Description (message affiché au client) *"
            placeholder="Description (message affiché au client) *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setDetailData(null);
              setCollectBillingsHistoryOpen(false);
            }}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
  )}


    </Modal>
  );
};

export default ConfirmationCollectBillingsHistory;
