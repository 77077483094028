import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { isicCardImportReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationImportIsicCard = ({ importIsicCardOpen, setImportIsicCardOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const importHandler = async (id, data) => {
    setLoading(true);
    let status = await isicCardImportReq(id, data);
    setLoading(false);
    setImportIsicCardOpen(false);
    if(status === 'success'){
      refreshData();
    }
  }

  const ImportIsicCardInitialValues = () => {
    return {
      isic_card_number: '',
    }
  };
  const ImportIsicCardSchema = Yup.object().shape({
    isic_card_number: Yup.string().min(14).matches('^[a-zA-Z]{1}\\d{3}\\d{9}[a-zA-Z]{1}$').required('Requis'),
  });

  return (
    <Modal isOpen={importIsicCardOpen} toggle={setImportIsicCardOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={ImportIsicCardInitialValues()}
      validationSchema={ImportIsicCardSchema}
      onSubmit={(values) => {
        importHandler(userId, values).then((res) => {
          if (res === 'success') {
            setImportIsicCardOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Ajout d'un commentaire sur le client</div>
          <Field
            name="isic_card_number"
            components="textarea"
            className="mt-3"
            label="ISIC Card Number"
            placeholder="S01234...789B *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setImportIsicCardOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationImportIsicCard;
