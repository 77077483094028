import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientPhone } from '../../../../api/client/updateClient';
import { countryPhones } from '../../../../api/config/config';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationUpdatePhone = ({ updatePhoneOpen, setUpdatePhoneOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [countryPhonesData, setCountryPhonesData] = useState([]);

  useEffect(() => {
    countryPhones(false).then((res) => {
      let phoneCodes = [];
      res.map((item) => phoneCodes.push({ label: item.name + ' (' + item.code + ' / +' + item.phone_code + ')', value: item.code }));
      setCountryPhonesData(phoneCodes);
    });
  }, []);

  const updateHandler = async (id, data) => {
    setLoading(true);
    data["mobile_country"] = data.mobile_country?.value
    let status = await updateClientPhone(id, data);
    setLoading(false);
    setUpdatePhoneOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  const UpdatePhoneInitialValues = (data) => {
    return {
      oldMobile: data ? data.mobile : '',
      newMobile: '',
      confirmMobile: '',
      mobile_country: 'FR',
    }
  };
  const UpdatePhoneSchema = Yup.object().shape({
    oldMobile: Yup.string().required('Requis'),
    newMobile: Yup.string().required('Requis'),
    confirmMobile: Yup.string().oneOf([Yup.ref('newMobile'), null], 'Les numéros de téléphone doivent être identiques').required('Requis'),
    mobile_country: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updatePhoneOpen} toggle={setUpdatePhoneOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdatePhoneInitialValues(userData)}
      validationSchema={UpdatePhoneSchema}
      onSubmit={(values) => {
        console.log('Delete client submit');
        updateHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setUpdatePhoneOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer la modification du numéro de téléphone du client (Utiliser impérativement le format téléphoneique internation +33XXXXXXXXX)</div>
          <Field
            name="oldMobile"
            className="mt-3"
            label="Ancien numéro de téléphone"
            placeholder="Ancien numéro de téléphone *"
            component={InputFormik}
          />
          <Field
            name="newMobile"
            className="mt-3"
            label="Nouveau mobile"
            placeholder="Nouveau mobile *"
            component={InputFormik}
          />
          <Field
            name="confirmMobile"
            className="mt-3"
            label="Confirmation du nouveau mobile"
            placeholder="Confirmation du nouveau mobile *"
            component={InputFormik}
          />
          <Field
            name="mobile_country"
            className="mt-3"
            label="Code Pays de l'indicatif"
            placeholder="Code Pays de l'indicatif *"
            component={Dropdown}
            options={countryPhonesData}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUpdatePhoneOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdatePhone;
