import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PartnershipSchema, PartnershipInitialValues } from '../partnership.utils';

const ShowPartnership = ({ showModal, setShowModal, currentItem }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentItem ? currentItem : PartnershipInitialValues}
        validationSchema={PartnershipSchema}
      >
        {() => (
          <Form>
            <div className="o-title text-center">{t('partnership:detail_partnership')}</div>
            <Field
              name="code"
              className="mt-5"
              label={t('partnership:unique_code')}
              placeholder={t('partnership:unique_code_placeholder') + ' *'}
              component={InputFormik}
              onlyRead
            />
            <Field
              name="name"
              className="mt-5"
              label={t('partnership:descriptive_name')}
              placeholder={t('partnership:descriptive_name_placeholder') + ' *'}
              component={InputFormik}
              onlyRead
            />
            <Field
              name="description"
              className="mt-5"
              label={t('partnership:full_description')}
              placeholder={t('partnership:full_description_placeholder') + ' *'}
              component={InputFormik}
              onlyRead
            />
            <div className="d-flex flex-column mt-5">
              <Button
                btnType="outline"
                type="button"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('global:back')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ShowPartnership;
