import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { clientRefundReq } from '../../../../../api/client/billing';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../../components/dropdown';

const RefundClientModal = ({ clientId, refundModal, setRefundModal, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [commentText, setCommentText] = useState('');

  const refundClientHandler = async (clientId, data) => {
      console.log(data);
      setLoading(true);
      let status = await clientRefundReq(clientId, data);
      setLoading(false);
      if(status === 'success'){
        setRefundModal(false);
        refreshData();
      }
  }

  const RefundClientInitialValues = {
    label:'Remboursement',
  };
  const RefundClientSchema = Yup.object().shape({
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={refundModal} toggle={setRefundModal}>
      <Formik
        enableReinitialize={true}
        initialValues={RefundClientInitialValues}
        validationSchema={RefundClientSchema}
        onSubmit={(values) => {
          refundClientHandler(clientId, values);
        }}
      >
        <Form>
          <div>
          <div className="d-flex flex-column ">
            <div className="o-title text-center mb-4">Remboursement</div>
            <Field
              name="label"
              className="mt-3"
              label="Libellé du remboursement"
              placeholder="Libellé du remboursement *"
              component={InputFormik}
            />
            <Field
              name="amount"
              className="mt-3"
              label="Montant du remboursement (€)"
              placeholder="Montant du remboursement en €"
              component={InputFormik}
            />
            <Button
              className="mb-2"
              type="submit"
            >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
            <Button
              btnType="outline"
              type="button"
              onClick={() => setRefundModal(false)}
            >Cancel</Button>
          </div>
        </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default RefundClientModal;
