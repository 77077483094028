import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PermissionsContext from '../../../contexts/permissionContext';

import { useTranslation } from 'react-i18next';
import { columns, tableSearch, renderTable } from './sepasddrrejected.utils';
import { clientSepaSddrRejectedReq } from '../../../api/client/beneficiary/beneficiary';

import Header from '../../../components/header';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';

const ClientSepasSddrRejected = () => {
  const { t } = useTranslation();
  const { id, beneficiaryId } = useParams();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);


  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Liste des Refus de SDDR
      </Fragment>
    );
  }

  useEffect(() => {

    if (permContext) {
      /*Get data of the list*/
      clientSepaSddrRejectedReq(id)
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, t),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext, t]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setCurrentItem={setCurrentItem}
        />
      </div>
    </div>
  );
};

export default ClientSepasSddrRejected;
