import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateCardLimts } from '../../../../../api/client/card/cardlimits';

const ConfirmationUpdateCardLimits = ({ userID, card, updateLimitsModal, setUpdateLimitsModal, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [cardLimits, setCardLimits] = useState();

  const updateCardLimitsHandler = async (data) => {
    setLoading(true);
    console.log('card.cardLimits : ' + card.cardLimits);
    let status = await updateCardLimts(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setUpdateLimitsModal(false);
  }

  const UpdateLimitsInitialValues = {
    limitAtmDay: card?.cardLimits?.limitAtmDay ? card.cardLimits.limitAtmDay : '0',
    limitAtmWeek: card?.cardLimits?.limitAtmWeek ? card.cardLimits.limitAtmWeek : '0',
    limitAtmMonth: card?.cardLimits?.limitAtmMonth ? card.cardLimits.limitAtmMonth : '0',
    limitPaymentDay: card?.cardLimits?.limitPaymentDay ? card.cardLimits.limitPaymentDay : '0',
    limitPaymentWeek: card?.cardLimits?.limitPaymentWeek ? card.cardLimits.limitPaymentWeek : '0',
    limitPaymentMonth: card?.cardLimits?.limitPaymentMonth ? card.cardLimits.limitPaymentMonth : '0',
  };
  const UpdateLimitsSchema = Yup.object().shape({
    limitAtmDay: Yup.number().required('Requis').min(0).integer(),
    limitAtmWeek: Yup.number().required('Requis').min(0).integer(),
    limitAtmMonth: Yup.number().required('Requis').min(0).integer(),
    limitPaymentDay: Yup.number().required('Requis').min(0).integer(),
    limitPaymentWeek: Yup.number().required('Requis').min(0).integer(),
    limitPaymentMonth: Yup.number().required('Requis').min(0).integer(),
  });

  return (
    <Modal isOpen={updateLimitsModal} toggle={setUpdateLimitsModal}>
    <Formik
      enableReinitialize={true}
      initialValues={cardLimits ? cardLimits : UpdateLimitsInitialValues}
      validationSchema={UpdateLimitsSchema}
      onSubmit={(values) => {
        console.log('Update card limits' + cardLimits);
        updateCardLimitsHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification des plafonds de la carte </div>
          <Field
            name="limitAtmDay"
            className="mt-5"
            label="Limit Atm Day *"
            placeholder="Limit Atm Day *"
            component={InputFormik}
          />
          <Field
            name="limitAtmWeek"
            className="mt-5"
            label="Limit Atm Week *"
            placeholder="Limit Atm Week *"
            component={InputFormik}
          />
          <Field
            name="limitAtmMonth"
            className="mt-5"
            label="Limit Atm Month *"
            placeholder="Limit Atm Month *"
            component={InputFormik}
          />
          <Field
            name="limitPaymentDay"
            className="mt-5"
            label="Limit Payment Day *"
            placeholder="Limit Payment Day *"
            component={InputFormik}
          />
          <Field
            name="limitPaymentWeek"
            className="mt-5"
            label="Limit Payment Week *"
            placeholder="Limit Payment Week *"
            component={InputFormik}
          />
          <Field
            name="limitPaymentMonth"
            className="mt-5"
            label="Limit Payment Month *"
            placeholder="Limit Payment Month *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCardLimits;
