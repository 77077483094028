import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { regenerateStatementReq } from '../../../../../api/client/statements';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationRegenerateStatement = ({ statementId, regenerateStatementOpen, setRegenerateStatementOpen, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const regenerateHandler = async (id) => {
    setLoading(true);
    let status = await regenerateStatementReq(id);
    setLoading(false);
    setRegenerateStatementOpen(false);
    if (status === 'success') {
      refreshData();
    }
  }

  const AddStatementInitialValues = {
  };
  const AddStatementSchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={regenerateStatementOpen} toggle={setRegenerateStatementOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={AddStatementInitialValues}
      validationSchema={AddStatementSchema}
      onSubmit={(values) => {
        regenerateHandler(statementId);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Confirmez la regéneration du relevé de compte sélectionné ({statementId})</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setRegenerateStatementOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationRegenerateStatement;
