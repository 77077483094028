import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/header';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import IconEdit from '../../../assets/icons/edit.svg';
import IconLoading from '../../../assets/icons/loading.svg';
import IconCancel from '../../../assets/icons/cancel.png';
import IconCheck from '../../../assets/icons/check.svg';
import IconEye from '../../../assets/icons/eye.svg';
import IconDoc from '../../../assets/icons/doc.svg';
import IconRing from '../../../assets/icons/ring.svg';
import { detailClientReq } from '../../../api/client/detailClient';
import PermissionsContext from '../../../contexts/permissionContext';

import {
  clientBridgeItemsReq, clientPaymentRequestsReq
} from '../../../api/client/bridge/bridge';
import {
  tableBridgeItemsHeader, renderBridgeItemsTable, tableBridgePaymentRequestHeader, renderBridgePaymentRequestTable
} from './bridge.utils';

const ClientBridgeItems = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [selectedID, setSelectedID] = useState();
  const [bridgePaymentsTableData, setBridgePaymentsTableData] = useState([]);
  const [bridgeItemsTableData, setBridgeItemsTableData] = useState([]);
  const [selectedBridgePayment, setSelectedBridgePayment] = useState();
  const [selectedBridgeItem, setSelectedBridgeItem] = useState();
  const [allBridgePayments, setAllBridgePayments] = useState([]);
  const [allBridgeItems, setAllBridgeItems] = useState([]);
  const [loadingBridgePayments, setLoadingBridgePayments] = useState(true);
  const [loadingBridgeItems, setLoadingBridgeItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('loading');

  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshPaymentsData();
        refreshItemsData();
      })
      .finally(() => setStatus('done'));

  }, []);

  useEffect(() => {
    const bridgeItemDetail = allBridgeItems.find((x) => x.itemId === selectedID);
    setSelectedBridgeItem(bridgeItemDetail);
    setSelectedItem('item');
  }, [selectedID]);

  const refreshPaymentsData = () => {
    setLoadingBridgePayments(true);
    clientPaymentRequestsReq(id).then((res) => {
      console.log(res);
      setAllBridgePayments(res);
      setBridgePaymentsTableData(renderBridgePaymentRequestTable(res, t));
    })
    .finally(() => setLoadingBridgePayments(false));
  };

  const refreshItemsData = () => {
    setLoadingBridgeItems(true);
    clientBridgeItemsReq(id).then((res) => {
      console.log(res);
      setAllBridgePayments(res);
      setBridgeItemsTableData(renderBridgeItemsTable(res));
    })
    .finally(() => setLoadingBridgeItems(false));
  };

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight />
        Espace Bridge
      </Fragment>
    );
  }

  const renderSelectedBridgeItemsActions = (selectedBridgeItem) => {
    return(
      <div className="o-information-detail__actions">

      </div>
      )
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
        {status === 'loading' ? (
          <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
        ) : (
          <div className="o-information">
            <div className="my-3">
              <b>Informations client</b>
            </div>
            <div className="o-information-row">
              <div className="o-information-column">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>UserId | TrzUserId</div>
                    <div>{detailData?.userId} | {detailData?.trzUserId}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Prénom NOM</div>
                    <div className="ml-2">{detailData?.firstname ? detailData?.firstname : '-'} {detailData?.lastname ? detailData?.lastname : '-'}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Statut</div>
                    <div>{detailData?.userStatus}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
          <Table
            columns={tableBridgePaymentRequestHeader}
            tableData={bridgePaymentsTableData}
            status={loadingBridgePayments}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => 'Liste alimentation par virement'}
            contain
            itemPerPage={10}
          />
          <Table
            columns={tableBridgeItemsHeader}
            tableData={bridgeItemsTableData}
            status={loadingBridgeItems}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => 'Liste Comptes agrégés'}
            contain
            itemPerPage={10}
          />
        </div>
        <div className="o-viewer">
        {selectedID && selectedItem === 'item' && (
          <div>
            <div>
              <div className="mt-5">
                <b>Informations de l'Item</b>
              </div>
              <hr />
              <div className="d-flex">
                <div className="mt-2">
                  <div>
                    <div><b>Identification ID</b></div>
                    <div>{selectedBridgeItem?.user_feature_history_id}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Date</b></div>
                    <div>{selectedBridgeItem?.date? moment(selectedBridgeItem.date).format('MMMM YYYY') : '-'}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Item</b></div>
                    <div>{selectedBridgeItem?.feature?.name}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Montant HT</b></div>
                    <div>{selectedBridgeItem?.amount ? selectedBridgeItem.amount + '€':'- €'}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>TVA</b></div>
                    <div>{(selectedBridgeItem?.vat? Math.round(selectedBridgeItem.vat.rate*100) :'-') + '%'}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Montant TTC</b></div>
                    <div>{(selectedBridgeItem?.amount? Math.round(selectedBridgeItem.amount*(1+selectedBridgeItem.vat.rate)):'-')+ '€'}</div>
                  </div>
                  <div className="mt-2">
                    <div><b>Facturé (O/N)</b></div>
                    <div>{selectedBridgeItem?.transfer ? 'Oui' : 'Non'}</div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <b>Actions</b>
                <hr />
                {renderSelectedBridgeItemsActions(selectedBridgeItem)}
              </div>
            </div>
          </div>
        )}
        {!selectedID && (<Logo className="o-viewer-logo"/>)}
        </div>
      </div>
    </div>
  );
};

export default ClientBridgeItems;
