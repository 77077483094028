import React from 'react';

import moment from 'moment';

export const columns = [
  'Date demande',
  'Email',
  'Type demande',
  'Firstname',
  'Lastname',
  'Company',
  'Date de naissance',
  'Pays',
  'Status',
];

export const renderTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.email,
      created: item.created ? moment(item.created).format('DD/MM/YYYY') : ' - ',
      email: item.email,
      type: item.waiting_type,
      firstname: item.firstname,
      lastname: item.lastname,
      company: item.company_name,
      birthday : item.birthday ? moment(item.birthday).format('DD/MM/YYYY') : ' - ',
      country: item.country,
      status: item.status ? item.status : ' - ',
    }),
  );
  return data;
};
