import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { changePinReq } from '../../../../../api/client/card/forcePin';

const ConfirmationForcePin = ({ userID, card, forceCodeModal, setForceCodeModal }) => {

  const [loading, setLoading] = useState(false);

  const changePinHandler = async (data) => {
    setLoading(true);
    let status = await changePinReq(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      close();
    }
  }

  const close = () => {
    setForceCodeModal(false);
  }

  const ForcePINInitialValues = {
  };
  const ForcePINSchema = Yup.object().shape({
    newPin: Yup.string().required('Requis'),
    confirmPin: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={forceCodeModal} toggle={setForceCodeModal}>
    <Formik
      enableReinitialize={true}
      initialValues={ForcePINInitialValues}
      validationSchema={ForcePINSchema}
      onSubmit={(values) => {
        console.log('Force PIN code submit');
        changePinHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour forcer le PIN de la carte il faut saisir le nouveau code PIN</div>
          <Field
            name="newPin"
            className="mt-5"
            label="Code PIN *"
            placeholder="Code PIN *"
            component={InputFormik}
          />
          <Field
            name="confirmPin"
            className="mt-5"
            label="Confirmation du code PIN *"
            placeholder="Confirmation du code PIN *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationForcePin;
