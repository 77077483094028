import * as React from "react";
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { clientWalletListReq } from '../../../api/client/wallet';
import { detailClientReq, clientNotesReq, clientChildrenReq } from '../../../api/client/detailClient';
import ConfirmationLock from './modals/confirmationLock';
import ConfirmationDeleteInfo from './modals/confirmationDelete';
import ConfirmationCollectATD from './modals/confirmationCollectATD';
import ConfirmationCreateTransfer from './modals/confirmationCreateTransfer';
import ConfirmationCreatePayout from './modals/confirmationCreatePayout';
import ConfirmationKycAccept from './modals/confirmationKycAccept';
import ConfirmationKycRefuse from './modals/confirmationKycRefuse';
import ConfirmationAddNote from './modals/confirmationAddNote';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconEye from '../../../assets/icons/eye.svg';
import IconEdit from '../../../assets/icons/edit.svg';
import IconLoading from '../../../assets/icons/loading.svg';
import IconDoc from '../../../assets/icons/doc.svg';
import IconRing from '../../../assets/icons/ring.svg';
import IconHand from '../../../assets/icons/hand.svg';
import IconCross from '../../../assets/icons/green-cross.svg';
import IconCheck from '../../../assets/icons/check.svg';
import Header from '../../../components/header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import {
  tableWalletHeader,
  renderWalletTable,
} from './wallet.utils';
import PermissionsContext from '../../../contexts/permissionContext';

function ClientDetail() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [userNotes, setUserNotes] = useState([]);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [walletTableData, setWalletTableData] = useState([]);
  const [statusWalletTable, setStatusWalletTable] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [deleteInfoOpen, setDeleteInfoOpen] = useState(false);
  const [kycAcceptOpen, setKycAcceptOpen] = useState(false);
  const [kycRefuseOpen, setKycRefuseOpen] = useState(false);
  const [collectATDOpen, setCollectATDOpen] = useState(false);
  const [createTransferOpen, setCreateTransferOpen] = useState(false);
  const [createPayoutOpen, setCreatePayoutOpen] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  useEffect(() => {
    refreshData();

    clientWalletListReq(id)
      .then((res) => setWalletTableData(renderWalletTable(res)))
      .finally(() => setStatusWalletTable('done'));
  }, []);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${id}/wallet/${selectedID}`;
  }, [showModal]);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href={status ? '/dashboard/clientbystatus/' + status: '/dashboard/client'}>Liste Clients</a> <ArrowRight /> Détail Client
      </Fragment>
    );
  }

  const toggleBlockedData = () => {
    setDetailData({
      ...detailData,
      blocked: !detailData.blocked,
    });
  };

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientNotesReq(id)
    .then((res) => setUserNotes(res));
  };

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} disabledSearch />
      <div className="o-information-detail">
        <div className="o-information-detail__block">
          <div className="o-information-detail__info">
            <div className="d-flex">
              {permContext?.clientRead && (
              <div className="mb-3 flex-fill">
                <b>
                  Informations
                  <a href={`/dashboard/client/${id}/information`} className="pl-2">
                    Voir détail <ArrowRight />
                  </a>
                </b>
              </div>)}
              {permContext?.clientRead  && (
              <div className="mb-3 flex-fill">
                <b>
                  Membership
                  <a href={`/dashboard/client/${id}/membership`} className="pl-2">
                    {detailData?.membership ? detailData?.membership?.type?.name + ' ' + detailData?.membership?.billing_type : 'Modifier'}  <ArrowRight />
                  </a>
                </b>
              </div>)}
            </div>
            <div className="o-information-column">
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>UserId | TrzUserId</div>
                  <div>{detailData?.userId} | {detailData?.trzUserId} </div>
                </div>
                {permContext?.market === 'pro' && detailData?.company_type && (
                  <div className="o-information-detail__item mb-2">
                    <div>Type d'Entreprise</div>
                    <div>{detailData?.company_type} </div>
                  </div>
                )}
                {permContext?.market === 'pro' && detailData?.parent && (
                  <div className="o-information-detail__item mb-2">
                    <div>Parent</div>
                    <div><a href={`/dashboard/client/${detailData?.parent?.userId}`} className="pl-2">{detailData?.parent?.userId} <ArrowRight /></a>
                    </div>
                  </div>
                )}
                <div className="o-information-detail__item mb-2">
                  <div>Création&nbsp;|&nbsp;MAJ</div>
                  <div>{detailData?.created ? moment(detailData?.created).format('DD/MM/YY HH:mm') : '-'} | {detailData?.updated ? moment(detailData?.updated).format('DD/MM/YY HH:mm') : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Date de validation</div>
                  <div>{detailData?.validation_date ? moment(detailData?.validation_date).format('DD/MM/YY HH:mm') : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Prénom | Nom</div>
                  <div>{detailData?.firstname} | {detailData?.lastname}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>E-mail / Téléphone</div>
                  <div>{detailData?.email} | {detailData?.mobile ? detailData?.mobile : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Date de naissance</div>
                  <div>{detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'} à {detailData?.placeOfBirth} ({detailData?.birthCountry})</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Bloqué</div>
                  {detailData?.blocked ?
                    <div className="o-red">Oui&nbsp;&nbsp;
                      {permContext?.clientUpdate && (<Button btnType="outline" onClick={() => setLockModal(true)}>
                      <img className="mr-2" src={IconHand} alt="" />Débloquer
                    </Button>)}</div> :
                    <div className="o-green">Non&nbsp;&nbsp;
                      {permContext?.clientUpdate && (<Button btnType="outline" onClick={() => setLockModal(true)}>
                      <img className="mr-2" src={IconHand} alt="" />Bloquer</Button>)}
                    </div>
                  }

                </div>
              </div>
              <div className="o-information--block">
              {userNotes.map((note, index) => (
                <div>{note?.created ? moment(note?.created).format('DD/MM/YY HH:mm') : ''} - {note.operator}&nbsp;:&nbsp;{note.note}</div>
              ))}
                <div><a href="#" className="pl-2" onClick={() => setAddNoteOpen(true)}>Ajouter commentaire</a></div>
              </div>
            </div>
            <div className="container">
              <div className="d-flex row align-items-start">
                <div className="mt-4 flex-fill">
                  <b>Cartes<a href={`/dashboard/client/${id}/cards`} className="pl-2"> Voir détail <ArrowRight /></a></b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Client Documents<a href={`/dashboard/client/${id}/document`} className="pl-2"> Voir détail <ArrowRight /></a></b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Client IP History
                    <a href={`/dashboard/client/${id}/history/http`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
              </div>
              <div className="d-flex row align-items-start">
                <div className="mt-4 flex-fill">
                  <b>Historique de facturation
                    <a href={`/dashboard/client/${id}/billings`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Historique (Email + Notif)
                    <a href={`/dashboard/client/${id}/events/history`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
              </div>
              <div className="d-flex row align-items-start">
                <div className="mt-4 flex-fill">
                  <b>Bridge (Virement + Agrégation)
                    <a href={`/dashboard/client/${id}/bridge/history`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Virements programmés
                    <a href={`/dashboard/client/${id}/scheduledtransfers`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Topups Carte
                    <a href={`/dashboard/client/${id}/topupcards`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Relevés de compte
                    <a href={`/dashboard/client/${id}/statements`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
              </div>
              <div className="d-flex row align-items-start">
                <div className="mt-4 flex-fill">
                  <b>Carte ISIC
                    <a href={`/dashboard/client/${id}/isic_cards`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
                <div className="mt-4 flex-fill">
                  <b>Filleuls
                    <a href={`/dashboard/client/${id}/godchildren`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
              </div>
              {permContext?.market === 'pro' && detailData?.userTypeId === 2 && (
              <div className="d-flex row align-items-start">
                <div className="mt-4 flex-fill">
                  <b>Membres
                    <a href={`/dashboard/client/${id}/members?${requestSuffix}`} className="pl-2">
                      Voir détail <ArrowRight />
                    </a>
                  </b>
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="o-information-detail__package">
            <div className="mb-3">
              <b>
                KYC
                <a href={`/dashboard/client/${id}/kycliveness`} className="pl-2">
                  Voir détail <ArrowRight />
                </a>
              </b>
            </div>
            <div className="mb-3">
              <div className="o-information--block mb-3">
                <div className="o-information-detail__item mb-2">
                  <div>Statut</div>
                  <div>{detailData?.userStatus}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>KYC Level | KYC Review</div>
                  <div className="ml-2">{detailData?.kycLevel ? detailData?.kycLevel : '-'} | {detailData?.kycReview ? detailData?.kycReview : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>KYC Review Comment</div>
                  <div className="ml-2">
                    {detailData?.kycReviewComment ? detailData?.kycReviewComment : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Freezed</div>
                  {detailData?.isFreezed ?
                    <div className="ml-2 o-red">Oui</div> :
                    <div className="ml-2">Non</div> }
                </div>
              </div>
              <div className="o-information--block mb-3">
                <div className="o-information-detail__item mb-2">
                  <div>Niveau de confiance</div>
                  <div className="ml-2">{detailData?.trust_level?.trust_level_id ? detailData?.trust_level?.trust_level_id+ ' - ' + detailData?.trust_level?.description + ' (Alerte > ' + detailData?.trust_level?.alert_payin_limit +'€)' : '-'}</div>
                </div>
              </div>
              <div className="o-information--block mb-2">
                <div className="o-information-detail__item mb-2">
                  <div>Adresse</div>
                  <div className="ml-2">
                  {detailData?.address1 ? detailData?.address1 : '-'}<br/>
                  {detailData?.address2 ? detailData?.address2 : '-'}<br/>
                  {detailData?.postcode ? detailData?.postcode : '-'}&nbsp;{detailData?.city ? detailData?.city : '-'}<br/>
                  {detailData?.state ? detailData?.state : '-'}<br/>
                  {detailData?.countryName ? detailData?.countryName : '-'} ({detailData?.country ? detailData?.country : '-'})
                  </div>
                </div>
              </div>
              <Button btnType="outline" onClick={() => refreshData(true)}>
                {
                  loading ?
                    <img className="mr-2" src={IconLoading} height="25" alt="" /> :
                    <img className="mr-2" src={IconEdit} alt="" />
                }
                Rafraichir les données KYC
              </Button>
              <div className="mt-4">
                <b>
                  Bénéficiaires
                  <a href={`/dashboard/client/${id}/beneficiary`} className="pl-2">
                    Voir liste <ArrowRight />
                  </a>
                </b>
              </div>
              <div className="mt-4">
                <b>
                  Mandats (SDDR)
                  <a href={`/dashboard/client/${id}/mandate`} className="pl-2">
                    Voir liste <ArrowRight />
                  </a>
                </b>
              </div>
              <div className="mt-4">
                <b>
                  SDDR Refusés
                  <a href={`/dashboard/client/${id}/sddr_rejected`} className="pl-2">
                    Voir liste <ArrowRight />
                  </a>
                </b>
              </div>
            </div>
          </div>
          { permContext?.clientUpdate ? (
          <div className="o-information-detail__actions">
            <b>Actions sur client</b>
            <div>
              <Button btnType="outline" onClick={() => setKycAcceptOpen(true)}>
                <img className="mr-2" src={IconCheck} alt="" />
                Accepter KYC
              </Button>
              <Button btnType="outline" onClick={() => setKycRefuseOpen(true)}>
                <img className="mr-2" src={IconCross} alt="" />
                Refuser KYC
              </Button>
            </div>
            <div>
              <Button btnType="outline" onClick={() => setCollectATDOpen(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Saisie ATD
              </Button>
            </div>
            <div>
              <Button btnType="outline" onClick={() => setCreatePayoutOpen(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Virement externe
              </Button>
              <Button btnType="outline" onClick={() => setCreateTransferOpen(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Transfert entre wallets
              </Button>
            </div>
            <div>
              {permContext?.clientDelete && (
              <Button btnType="outline" onClick={() => setDeleteInfoOpen(true)}>
                <img className="mr-2" src={IconCross} alt="" />
                Suppr. infos client
              </Button>
              )}
            </div>
          </div>
        ) :
        (
          <div className="o-information-detail__actions">&nbsp;</div>
        )}
        </div>
        <Table
          columns={tableWalletHeader}
          tableData={walletTableData}
          status={statusWalletTable}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => (
            <div className="mb-4 mt-2">
              <b>Wallets</b>
            </div>
          )}
          contain
        />
      </div>
      <ConfirmationLock
        lockModal={lockModal}
        setLockModal={setLockModal}
        userId={detailData?.userId}
        blocked={detailData?.blocked}
        toggleBlockedData={toggleBlockedData}
      />
      <ConfirmationDeleteInfo
        deleteInfoOpen={deleteInfoOpen}
        setDeleteInfoOpen={setDeleteInfoOpen}
        userId={detailData?.userId}
        refreshData={refreshData}
      />
      <ConfirmationCollectATD
        collectATDOpen={collectATDOpen}
        setCollectATDOpen={setCollectATDOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationCreateTransfer
        createTransferOpen={createTransferOpen}
        setCreateTransferOpen={setCreateTransferOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationCreatePayout
        createPayoutOpen={createPayoutOpen}
        setCreatePayoutOpen={setCreatePayoutOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationKycAccept
        kycAcceptOpen={kycAcceptOpen}
        setKycAcceptOpen={setKycAcceptOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationKycRefuse
        kycRefuseOpen={kycRefuseOpen}
        setKycRefuseOpen={setKycRefuseOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationAddNote
        addNoteOpen={addNoteOpen}
        setAddNoteOpen={setAddNoteOpen}
        userData={detailData}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetail;
