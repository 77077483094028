import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { uploadUserDocumentReq } from '../../../../api/client/submitKYC';
import { documentTypesToSubmitReq } from '../../../../api/client/getDocumentTypes';
import IconLoading from '../../../../assets/icons/loading.svg';
import IconDelete from '../../../../assets/icons/delete.svg';
import IconCheck from '../../../../assets/icons/check.svg';
import IconCross from '../../../../assets/icons/cross.svg';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../components/dropdown';

const ConfirmationSubmitKYCDocs = ({ submitKycOpen, setSubmitKycOpen, userId, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState();
  //const [selectedFile, setSelectedFile] = useState(null);
  const [responseText, setResponseText] = useState('');
  const [selectedRecto, setSelectedRecto] = useState(null);
  const [selectedVerso, setSelectedVerso] = useState(null);

  const submitKYCHandler = async () => {
    setLoadingPost(true);
    const res = await uploadUserDocumentReq(userId, selectedDocumentType?.documentTypeId, selectedRecto, selectedVerso);
    setResponseText(res);

    setLoadingPost(false);
    close();
  }

  const ShowResponseText = () => (
    <div style={{textAlign: 'center', margin: "20px auto 30px auto"}}>
      {responseText} <br />
      {
      responseText === 'success' ?
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCheck} alt="" /> :
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCross} alt="" />
      }
    </div>
  );

  const close = () => {
    setSubmitKycOpen(false);
    refreshData();
  }

  const getDocumentTypesHandler = async () => {
    setLoading(true);
    const types = await documentTypesToSubmitReq();
    console.log(types)
    if(types){
      let optionTypes = types.map((t) => (
        {
          label: t.name,
          value: t.name,
          documentTypeId: t.documentTypeId
        }
      ));
      console.log(types)
      setDocumentTypes(optionTypes);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDocumentTypesHandler();
  }, []);

  const displaySelectedRecto = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <img src={IconDelete} height="20" onClick={() => setSelectedRecto(null)} alt="" />
      </div>
    )
  }

  const displaySelectedVerso = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <img src={IconDelete} height="20" onClick={() => setSelectedVerso(null)} alt="" />
      </div>
    )
  }

  return (
    <Modal isOpen={submitKycOpen} toggle={setSubmitKycOpen}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Soumettre un nouveau document KYC</div>
          <div style={{margin: '20px auto 20px auto', width: '300px', zIndex: '12'}} >
          <Formik
            initialValues={documentTypes.length? documentTypes[0]: {}}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                <Field
                  name="documenttype"
                  as="select"
                  component={Dropdown}
                  options={documentTypes}
                  label="Type de document"
                  style={{ zIndex: 1 }}
                  onChange={(e) => {
                    setSelectedDocumentType(e);
                  }}
                />
                <div style={{textAlign: 'center', marginTop: '45px', position: 'relative'}}>
                  <a style={{color: '#00DB9B', textDecoration: 'underlined'}}>
                    {selectedRecto?.name ? displaySelectedRecto(selectedRecto.name) : '+ Add Recto'}
                  </a>
                  <input type="file" className="custom-file-input"
                    onChange={e => setSelectedRecto(e.target.files[0])}
                  />
                </div>
                <div style={{textAlign: 'center', marginTop: '45px', position: 'relative'}}>
                  <a style={{color: '#00DB9B', textDecoration: 'underlined'}}>
                    {selectedVerso?.name ? displaySelectedVerso(selectedVerso.name) : '+ Add Verso'}
                  </a>
                  <input type="file" className="custom-file-input"
                    onChange={e => setSelectedVerso(e.target.files[0])}
                  />
                </div>
                <div className="d-flex flex-column mt-5">
                {
                  loadingPost ?
                    <img className="d-flex mx-auto" style={{ margin: "20px auto 30px auto" }} src={IconLoading} height="25" alt="" /> :
                    <ShowResponseText />
                }
                <Button
                  className="mb-2"
                  onClick={submitKYCHandler}
                  style={{backgroundColor: "#00DB9B", opacity: selectedRecto ? '1' : '0.3', color: '#000'}}
                  disabled={selectedRecto ? false : true}
                  type="submit"
                >
                  {
                    loading ?
                    <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Ajouter'
                  }
                </Button>
                <Button
                  btnType="outline"
                  type="button"
                  onClick={close}
                >Annuler</Button>
                </div>
              </Form>
            )}
          </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationSubmitKYCDocs;
