import React, { useState, useEffect } from 'react';
import { localStorageKeys } from '../../api/constants.js';
import './login.css';
import Logo from '../../components/logo';
//import background from '../../assets/img/mountain-scaled.jpg';
import { startOnlyOneLogin } from './login';
import IconLoading from '../../assets/icons/loading.svg';

// Workflow du one time code : https://developers.google.com/identity/sign-in/web/server-side-flow

// https://developers.google.com/identity/gsi/web/guides/overview
const Login = () => {
  const [background, setBackground] = useState("https://static.onlyonecard.io/images/onlyone_forest.jpg");

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.id = 'google-auth-onlyone';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = startOnlyOneLogin;
    document.body.append(scriptTag);

    if (localStorage.getItem(localStorageKeys.onlyone_token)) {
      window.location.href = '/dashboard/clientbystatus/onboarding_finalized';
    }

    const backgrounds = ["https://static.onlyonecard.io/images/onlyone_tree.jpg",
                        "https://static.onlyonecard.io/images/onlyone_landscape.jpg",
                        "https://static.onlyonecard.io/images/onlyone_forest.jpg",
                        "https://static.onlyonecard.io/images/onlyone_sky.jpg",
                        "https://static.onlyonecard.io/images/onlyone_mountains.jpg"];
    let randomIndex = Math.random();
    let indexBackground = Math.floor(randomIndex * 5);
    setBackground(backgrounds[indexBackground]);

  }, []);

  return (
    <div className="login">
      <div className="login-left">
        <div className="login-block">
          <Logo/>
          <div>
          <div id="signinButtonOnlyone">&nbsp;</div>
          <img id="loader" className="mx-auto mt-4" src={IconLoading} height="40" alt="" style={{display: 'none'}}></img>
          </div>
        </div>
      </div>
      <div className="login-right">
        <img src={background} alt="bg" />
      </div>
    </div>
  );
};

export default Login;
