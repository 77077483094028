import React from 'react';
import PropTypes from 'prop-types';
import './input.css';
import TextareaAutosize from 'react-textarea-autosize';

const Input = ({
  label,
  errorText,
  supportText,
  placeholder,
  className,
  multiline = true,
  ...props
}) => {
  return (
    <div
      className={`o-input-group  ${errorText ? 'o-input-error' : ''} ${className ? className : ''}`}
    >
      {multiline ? (
        <TextareaAutosize className="o-input" type="text" placeholder={placeholder} {...props} />
      ) : (
        <input className="o-input" type="text" placeholder={placeholder} {...props} />
      )}
      <label>{label}</label>
      <span className="o-input-subtext">{errorText ? errorText : supportText}</span>
    </div>
  );
};

export const InputFormik = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  supportText,
  placeholder,
  className,
  onlyRead,
  disabled,
  type,
  ...props
}) => (
  <div
    className={`o-input-group  ${touched[field.name] && errors[field.name] && 'o-input-error'} ${
      className && className
    }`}
  >
    {type === 'number' ? (
      <input
        className="o-input"
        type="number"
        placeholder={placeholder}
        {...field}
        {...props}
        disabled={onlyRead || disabled}
        style={onlyRead && { color: 'black', borderBottomColor: 'black', cursor: 'default' }}
      />
    ) : (
      <TextareaAutosize
        className="o-input"
        type="text"
        placeholder={placeholder}
        {...field}
        {...props}
        disabled={onlyRead || disabled}
        style={onlyRead && { color: 'black', borderBottomColor: 'black', cursor: 'default' }}
      />
    )}

    <label>{label}</label>
    <span className="o-input-subtext">
      {touched[field.name] && errors[field.name] ? errors[field.name] : supportText}
    </span>
  </div>
);

Input.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  supportText: PropTypes.string,
};

export default Input;
