import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Header from '../../../components/header';
import IconLoading from '../../../assets/icons/loading.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconDelete from '../../../assets/icons/delete.svg';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import IconEdit from '../../../assets/icons/edit.svg';
import Button from '../../../components/button';
import { detailClientReq } from '../../../api/client/detailClient';
import ConfirmationUpdateInfos from './modals/confirmationUpdateInfos';
import ConfirmationUpdateLegalInfos from './modals/confirmationUpdateLegalInfos';
import ConfirmationUpdatePhone from './modals/confirmationUpdatePhone';
import ConfirmationUpdateEmail from './modals/confirmationUpdateEmail';
import ConfirmationUpdateCodePromo from './modals/confirmationUpdateCodePromo';
import ConfirmationDeleteCodePromo from './modals/confirmationDeleteCodePromo';
import ConfirmationUpdateTrustLevel from './modals/confirmationUpdateTrustLevel';
import ConfirmationUpdateAclRole from './modals/confirmationUpdateAclRole';
import PermissionsContext from '../../../contexts/permissionContext';

export default function ClientInformation() {
  const [detailData, setDetailData] = useState();
  const [updateInfosOpen, setUpdateInfosOpen] = useState(false);
  const [updateLegalInfosOpen, setUpdateLegalInfosOpen] = useState(false);
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false);
  const [updatePhoneOpen, setUpdatePhoneOpen] = useState(false);
  const [updateCodePromo, setUpdateCodePromo] = useState(false);
  const [deleteCodePromo, setDeleteCodePromo] = useState(false);
  const [updateTrustLevel, setUpdateTrustLevel] = useState(false);
  const [updateAclRole, setUpdateAclRole] = useState(false);
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const [status, setStatus] = useState('loading');
  const { id } = useParams();
  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${id}`}>Détail Client</a> <ArrowRight /> Informations Client
      </Fragment>
    );
  }
  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    setLoading(true);
    detailClientReq(id).then((res) => {
      setDetailData(res);
      setLoading(false);
    })
    .finally(() => {
      setStatus('done');
      setLoading(false);
    });
  };

  return (
    <>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} disabledSearch />

      {status === 'loading' ? (
        <img className="d-flex mx-auto mt-4" src={IconLoading} height="40" alt="" />
      ) : (
        <div className="o-information">
          <div className="my-3">
            <b>Informations client</b>
          </div>
          <div className="o-information-row">
            <div className="o-information-column">
            {permContext.market === 'pro' && detailData?.company_type && detailData?.legal_infos && (
              <div className="o-information--block">
              <div className="o-information-detail__item mb-2">
                <div>Nom de société</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_name ? detailData?.legal_infos?.legal_name : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>SIREN/SIRET</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_registration_number ? detailData?.legal_infos?.legal_registration_number : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>N° TVA intracommunautaire</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_tva_number ? detailData?.legal_infos?.legal_tva_number : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Date d'immatriculation</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_registration_date ? detailData?.legal_infos?.legal_registration_date : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Forme juridique</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_form ? detailData?.legal_infos?.legal_form?.code_insee_level_3 + ' - ' + detailData?.legal_infos?.legal_form?.name_insee_level_3 : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Capital social</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_share_capital ? detailData?.legal_infos?.legal_share_capital + ' €' : '- €'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Secteur d'activité</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_sector ? detailData?.legal_infos?.legal_sector : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>CA annuel</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_annual_turn_over ? detailData?.legal_infos?.legal_annual_turn_over : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Résultat net annuel</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_annual_net_income ? detailData?.legal_infos?.legal_annual_net_income : '-'}</div>
              </div>
              <div className="o-information-detail__item mb-2">
                <div>Nombre employés</div>
                <div className="ml-2">{detailData?.legal_infos?.legal_number_of_employee ? detailData?.legal_infos?.legal_number_of_employee : '-'}</div>
              </div>
              </div>
            )}

              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Titre</div>
                  <div className="ml-2">{detailData?.title ? detailData?.title : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Prénom</div>
                  <div className="ml-2">{detailData?.firstname ? detailData?.firstname : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nom</div>
                  <div className="ml-2">{detailData?.lastname ? detailData?.lastname : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Date de naissance</div>
                  <div className="ml-2">
                    {detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Ville de Naissance</div>
                  <div className="ml-2">
                    {detailData?.placeOfBirth ? detailData?.placeOfBirth : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Pays de Naissance</div>
                  <div className="ml-2">
                    {detailData?.birthCountry ? detailData?.birthCountry : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nationalité</div>
                  <div className="ml-2">
                    {detailData?.nationality ? detailData?.nationality : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Autre Nationalité</div>
                  <div className="ml-2">
                    {detailData?.nationalityOther ? detailData?.nationalityOther : '-'}
                  </div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>E-mail</div>
                  <div className="ml-2">{detailData?.email ? detailData?.email : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Téléphone mobile</div>
                  <div className="ml-2">{detailData?.mobile ? detailData?.mobile : '-'}</div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Adresse 1</div>
                  <div className="ml-2">{detailData?.address1 ? detailData?.address1 : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Adresse 2</div>
                  <div className="ml-2">{detailData?.address2 ? detailData?.address2 : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Code Postal</div>
                  <div className="ml-2">{detailData?.postcode ? detailData?.postcode : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Ville</div>
                  <div className="ml-2">{detailData?.city ? detailData?.city : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>État</div>
                  <div className="ml-2">{detailData?.state ? detailData?.state : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Pays</div>
                  <div className="ml-2">{detailData?.country ? detailData?.country : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nom du Pays</div>
                  <div className="ml-2">
                    {detailData?.countryName ? detailData?.countryName : '-'}
                  </div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Occupation</div>
                  <div className="ml-2">
                    {detailData?.occupation ? detailData?.occupation : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Revenus</div>
                  <div className="ml-2">
                    {detailData?.incomeRange ? detailData?.incomeRange : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Patrimoine financier</div>
                  <div className="ml-2">
                    {detailData?.personalAssets ? detailData?.personalAssets : '-'}
                  </div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Activités hors EU</div>
                  <div className="ml-2">
                    {detailData?.activity_outside_eu != null ? detailData?.activity_outside_eu ? 'Oui' : 'Non' : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Sanctions Economiques</div>
                  <div className="ml-2">
                    {detailData?.economic_sanctions != null ? detailData?.economic_sanctions ? 'Oui' : 'Non' : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Sanctions du pays résident</div>
                  <div className="ml-2">
                    {detailData?.resident_countries_sanctions != null ? detailData?.resident_countries_sanctions ? 'Oui' : 'Non' : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Sanctions en cours</div>
                  <div className="ml-2">
                    {detailData?.involved_sanctions != null ? detailData?.involved_sanctions ? 'Oui' : 'Non' : '-'}
                  </div>
                </div>
              </div>
              {permContext.market === 'pro' && (
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>Parent Id</div>
                    <div className="ml-2">
                      {detailData?.parent ? detailData?.parent?.userId : '-'}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Parent</div>
                    <div className="ml-2">
                      {detailData?.parent ? detailData?.parent?.legals?.legal_name : '-'}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="o-information-column">
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Date de création</div>
                  <div>{detailData?.created ? moment(detailData?.created).format('DD/MM/YYYY HH:mm') : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Dernière mise à jour</div>
                  <div>{detailData?.updated ? moment(detailData?.updated).format('DD/MM/YYYY HH:mm') : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Date de validation</div>
                  <div>{detailData?.validation_date ? moment(detailData?.validation_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>User ID</div>
                  <div className="ml-2">{detailData?.userId ? detailData?.userId : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Treezor User ID</div>
                  <div className="ml-2">{detailData?.trzUserId ? detailData?.trzUserId : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>User Status</div>
                  <div className="ml-2">
                    {detailData?.userStatus ? detailData?.userStatus : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>User Type ID</div>
                  <div className="ml-2">
                    {detailData?.userTypeId ? detailData?.userTypeId : '-'}
                  </div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>KYC Level</div>
                  <div className="ml-2">{detailData?.kycLevel ? detailData?.kycLevel : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>KYC Review</div>
                  <div className="ml-2">{detailData?.kycReview ? detailData?.kycReview : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>KYC Review Comment</div>
                  <div className="ml-2">
                    {detailData?.kycReviewComment ? detailData?.kycReviewComment : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Freezed</div>
                  <div className="ml-2">{detailData?.isFreezed}</div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Niveau de confiance</div>
                  <div className="ml-2">{detailData?.trust_level?.trust_level_id ? detailData?.trust_level?.trust_level_id+ ' - ' + detailData?.trust_level?.description + ' (Alerte > ' + detailData?.trust_level?.alert_payin_limit +'€)' : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Rôle</div>
                  <div className="ml-2">{detailData?.acl_role}</div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>US Person ?</div>
                  <div className="ml-2">{detailData?.specifiedUSPerson ? 'Oui' : 'Non'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Numéro d’imposition</div>
                  <div className="ml-2">{detailData?.taxNumber ? detailData?.taxNumber : '-'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Pays d’imposition</div>
                  <div className="ml-2">
                    {detailData?.taxResidence ? detailData?.taxResidence : '-'}
                  </div>
                </div>
              </div>
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Code Promo</div>
                  <div className="ml-2">
                    {detailData?.code_promo ? (
                    <>{detailData?.code_promo}
                    <Button btnType="none" action="delete" onClick={() => setDeleteCodePromo(true)}>
                      <img className="mr-2" height="14" src={IconDelete} alt="" />
                    </Button></>
                  ): ('-')}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Code Invitation</div>
                  <div className="ml-2">
                    {detailData?.invitation_code ? detailData?.invitation_code : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Code parrain</div>
                  <div className="ml-2">
                    {detailData?.code_parrain ? detailData?.code_parrain : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Code parent</div>
                  <div className="ml-2">
                    {detailData?.code_parent ? detailData?.code_parent : '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="o-information-column">
              <div className="my-3">
                <b>Actions</b>
              </div>
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setUpdateInfosOpen(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Modifier les infos.
                </Button>
                <Button btnType="outline" onClick={() => setUpdatePhoneOpen(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Modifier numéro téléphone
                </Button>
                <Button btnType="outline" onClick={() => setUpdateEmailOpen(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Modifier email
                </Button>
                <Button btnType="outline" onClick={() => setUpdateCodePromo(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Modifier Code Promo
                </Button>
                <Button btnType="outline" onClick={() => setUpdateTrustLevel(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Niveau de confiance
                </Button>
                {permContext.market === 'pro' && (
                <Button btnType="outline" onClick={() => setUpdateAclRole(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Rôle du client
                </Button>)}
                {permContext.market === 'pro' && detailData?.company_type && (
                <Button btnType="outline" onClick={() => setUpdateLegalInfosOpen(true)}>
                  <img className="mr-2" src={IconEdit} alt="" />
                  Informations d'Entreprise
                </Button>)}
              </div>
            </div>
          </div>
          <ConfirmationUpdateInfos
            updateInfosOpen={updateInfosOpen}
            setUpdateInfosOpen={setUpdateInfosOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateLegalInfos
            updateLegalInfosOpen={updateLegalInfosOpen}
            setUpdateLegalInfosOpen={setUpdateLegalInfosOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdatePhone
            updatePhoneOpen={updatePhoneOpen}
            setUpdatePhoneOpen={setUpdatePhoneOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateEmail
            updateEmailOpen={updateEmailOpen}
            setUpdateEmailOpen={setUpdateEmailOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateCodePromo
            updateCodePromoOpen={updateCodePromo}
            setUpdateCodePromoOpen={setUpdateCodePromo}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationDeleteCodePromo
            deleteCodePromoOpen={deleteCodePromo}
            setDeleteCodePromoOpen={setDeleteCodePromo}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateTrustLevel
            updateTrustLevelOpen={updateTrustLevel}
            setUpdateTrustLevelOpen={setUpdateTrustLevel}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateAclRole
            updateAclRoleOpen={updateAclRole}
            setUpdateAclRoleOpen={setUpdateAclRole}
            userData={detailData}
            refreshData={refreshData}
          />
        </div>
      )}
    </>
  );
}
