import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './creditCard.css';
import Button from '../button';
import IconHand from '../../assets/icons/hand.svg';
import IconCalc from '../../assets/icons/calculator.svg';
import IconWarning from '../../assets/icons/triangle.svg';
import IconRenew from '../../assets/icons/renew.svg';
import IconEdit from '../../assets/icons/edit.svg';
import IconDollarRenew from '../../assets/icons/dollar-renew.svg';
import IconDoc from '../../assets/icons/doc.svg';
import IconLoading from '../../assets/icons/loading.svg';

function CreditCardActions({ cardDetailData, setBlockCardModal, setLostCardModal, setActivateCardModal,
                              setForceCodeModal, setUnlockPinCardModal, setUpdateLimitsCardModal, setUpdateOptionsCardModal, refreshCardDetailInfos, refreshCardLoading,
                              ...props}) {
  if(cardDetailData?.status === 'UNLOCK'){
    return(
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setBlockCardModal(true)}>
          <img className="mr-2" height="14" src={IconHand} alt="" />
          Bloquer la carte
        </Button>
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <img className="mr-2" height="14" src={IconWarning} alt="" />
          Perte / Vol / Destruction
        </Button>
        { cardDetailData?.activated == false ?
          <Button btnType="outline" onClick={() => setActivateCardModal(true)}>
              <img className="mr-2" height="14" src={IconHand} alt="" />
              Activer la carte
            </Button> : ''
        }
        { cardDetailData?.virtualConverted ?
          <Button btnType="outline" onClick={() => setForceCodeModal(true)}>
            <img className="mr-2" height="11" src={IconCalc} alt="" />
            Forcer code PIN
          </Button>: ''
        }
        { cardDetailData?.pinTryExceeds && cardDetailData?.virtualConverted ?
          <Button btnType="outline" onClick={() => setUnlockPinCardModal(true)}>
            <img className="mr-2" height="14" src={IconHand} alt="" />
            Débloquer PIN 3 erreurs
          </Button> : ''
        }
        <Button btnType="outline" onClick={() => setUpdateLimitsCardModal(true)}>
          <img className="mr-2" height="14" src={IconHand} alt="" />
          Modifier les plafonds
        </Button>
        <Button btnType="outline" onClick={() => setUpdateOptionsCardModal(true)}>
          <img className="mr-2" height="14" src={IconHand} alt="" />
          Modifier les options
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {
            refreshCardLoading ?
              <img className="mr-2" height="25" src={IconLoading} alt="" /> :
              <img className="mr-2" height="14" src={IconRenew} alt="" />
          }
          Rafraichir données carte
        </Button>
      </div>
    )
  } else if(cardDetailData?.status === 'LOCK'){
    return(
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setBlockCardModal(true)}>
          <img className="mr-2" height="14" src={IconHand} alt="" />
          Débloquer la carte
        </Button>
        { cardDetailData?.activated == false ?
          <Button btnType="outline" onClick={() => setActivateCardModal(true)}>
              <img className="mr-2" height="14" src={IconHand} alt="" />
              Activer la carte
            </Button> : ''
        }
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <img className="mr-2" height="14" src={IconWarning} alt="" />
          Perte / Vol / Destruction
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {
            refreshCardLoading ?
              <img className="mr-2" height="25" src={IconLoading} alt="" /> :
              <img className="mr-2" height="14" src={IconRenew} alt="" />
          }
          Rafraichir données carte
        </Button>
      </div>
    )
  } else if(cardDetailData?.status === 'STOLEN'){
    return(
      <div className="o-information-detail__actions mt-3">
      <Button btnType="outline" onClick={() => setLostCardModal(true)}>
        <img className="mr-2" height="14" src={IconWarning} alt="" />
        Perte / Vol / Destruction
      </Button>
      <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
      {
        refreshCardLoading ?
          <img className="mr-2" height="25" src={IconLoading} alt="" /> :
          <img className="mr-2" height="14" src={IconRenew} alt="" />
      }
      Rafraichir données carte
      </Button>
      </div>
    )
  } else if(cardDetailData?.status === 'LOST'){
    return(
      <div className="o-information-detail__actions mt-3">
      <Button btnType="outline" onClick={() => setLostCardModal(true)}>
        <img className="mr-2" height="14" src={IconWarning} alt="" />
        Perte / Vol / Destruction
      </Button>
      <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
        {
          refreshCardLoading ?
            <img className="mr-2" height="25" src={IconLoading} alt="" /> :
            <img className="mr-2" height="14" src={IconRenew} alt="" />
        }
        Rafraichir données carte
      </Button>
      </div>
    )
  } else {
    return '';
  }

}

export default CreditCardActions;
