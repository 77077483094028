import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';
import axios from 'axios';
var qs = require('qs');

export async function updateCardLimts(userId, walletId, cardId, data) {

  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_limits.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId), data, queryParams);
    if (res.status === 200) {
      toast('Mise à jour des limites de carte enregistrée !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    return 'fail';
    CheckAuth(error);
  }
}
