import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { addClientNote } from '../../../../api/client/updateClient';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationAddNote = ({ addNoteOpen, setAddNoteOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const updateHandler = async (id, data) => {
    setLoading(true);
      let status = await addClientNote(id, data);
    setLoading(false);
    setAddNoteOpen(false);
    if(status === 'success'){
      refreshData();
    }
  }

  const AddNoteInitialValues = (data) => {
    return {
      note: data ? data.note : '',
    }
  };
  const AddNoteSchema = Yup.object().shape({
    note: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={addNoteOpen} toggle={setAddNoteOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={AddNoteInitialValues(userData)}
      validationSchema={AddNoteSchema}
      onSubmit={(values) => {
        console.log('Ajout commentaire client');
        updateHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setAddNoteOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Ajout d'un commentaire sur le client</div>
          <Field
            name="note"
            components="textarea"
            className="mt-3"
            label="Commentaire"
            placeholder="Commentaire *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setAddNoteOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationAddNote;
