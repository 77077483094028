import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../input';
import { Formik, Form, Field } from 'formik';
import Modal from '../modal';
import Button from '../button';
import IconLoading from '../../assets/icons/loading.svg';
import { cardDigitalizationDetailDeleteReq } from '../../api/client/card/carddigitalizations';
import { CardDigitalizationDeleteReasons } from '../../modules/common.utils';
import Dropdown from '../dropdown';

const ConfirmationDeleteCardDigitalization = ({ cardId, cardDigitalizationId, deleteCardDigitalizationDetailModal, setDeleteCardDigitalizationDetailModal, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const deleteCardDigitalizationHandler = async (data) => {
    setLoading(true);
    cardDigitalizationDetailDeleteReq(cardId, cardDigitalizationId , {
      reason : data.reason.value,
    }).then((res) => {
      if (res !== 'fail') {
        refreshData();
        close();
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const close = () => {
    setDeleteCardDigitalizationDetailModal(false);
  }

  const DeleteCardDigitalizationInitialValues = {
  };
  const DeleteCardDigitalizationSchema = Yup.object().shape({
    reason: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={deleteCardDigitalizationDetailModal} toggle={setDeleteCardDigitalizationDetailModal}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteCardDigitalizationInitialValues}
      validationSchema={DeleteCardDigitalizationSchema}
      onSubmit={(values) => {
        deleteCardDigitalizationHandler(values);
      }}
    >
      <Form>
        <div>
          <div className="d-flex flex-column">
            <div className="o-title text-center mb-4">Veuillez confirmer la suppression de la digitalization de la carte.</div>
            <Field
              name="reason"
              className="mt-2"
              label="Raison *"
              placeholder="Raison *"
              component={Dropdown}
              options={CardDigitalizationDeleteReasons}
            />
            <Button
              className="mb-2"
              type="submit"
            >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
            <Button
              btnType="outline"
              type="button"
              onClick={close}>Cancel</Button>
          </div>
        </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteCardDigitalization;
