import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../contexts/permissionContext';
import './sidebar.css';

import Logo from '../logo';
import IconCross from '../../assets/icons/cross.svg';
import {
  IconPermission,
  IconGroup,
  IconUser,
  IconDocument,
  IconPackage,
  IconTypes,
  IconLogout,
  IconClient,
  IconMeter,
  IconChartBars,
} from '../../assets/icons/icons-sidebar.jsx';


const Sidebar = (props) => {
  const { permContext } = useContext(PermissionsContext);
  const { t } = useTranslation();
  const activeStyle = { backgroundColor: 'var(--primary-dark)', color: 'var(--white)' };
  return (
    <div className={`o-sidebar ${props.className}`}>
      <div>
        <div className="o-sidebar-header">
          <div>{permContext?.market}</div>
          <Logo type="white"/>
          <img
            onClick={() => {
              props.setToggleSidebar(false);
            }}
            className="o-sidebar-close"
            src={IconCross}
            alt=""
          />
        </div>
        {permContext?.adminRead && (
          <NavLink
            exact
            className="o-sidebar-item"
            to="/dashboard/status"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconPermission />
            <div>{t('sidebar:status')}</div>
          </NavLink>
        )}
        {permContext?.adminRead && (
          <NavLink
            exact
            className="o-sidebar-item"
            to="/dashboard/admin"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconPermission />
            <div>{t('sidebar:admin')}</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/client"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconClient />
            <div> {t('sidebar:client')}</div>
          </NavLink>
        )}
        <div className="ml-2">
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/onboarding"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(true);
              }}
            >
              <div>&nbsp;-&nbsp;ONBOARDING</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/onboarding_finalized"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(false);
              }}
            >
              <div>&nbsp;-&nbsp;ONBOARDING_FINALIZED</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/onboarding_waiting_payin"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(true);
              }}
            >
              <div>&nbsp;-&nbsp;ONBOARDING_WAITING_PAYIN</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/kyc_reviewing"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(false);
              }}
            >
              <div>&nbsp;-&nbsp;KYC_REVIEWING</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/kyc_review_ask_more"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(false);
              }}
            >
              <div>&nbsp;-&nbsp;KYC_REVIEW_ASK_MORE</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/validated"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(false);
              }}
            >
              <div>&nbsp;-&nbsp;VALIDATED</div>
            </NavLink>
          )}
          {permContext?.clientRead && (
            <NavLink
              className="o-sidebar-item"
              to="/dashboard/clientbystatus/kyc_review_refused"
              activeStyle={activeStyle}
              onClick={() => {
                props.setToggleSidebar(false);
              }}
            >
              <div>&nbsp;-&nbsp;KYC_REVIEW_REFUSED</div>
            </NavLink>
          )}
        </div>
        {permContext?.clientRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/client_search"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconClient />
            <div>Recherche Client</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/client_by_code_promo"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconClient />
            <div>Clients Code Promo</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/supervision"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconMeter />
            <div>Surveillance</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/admin/waitinglist"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconClient />
            <div>Liste d'attente</div>
          </NavLink>
        )}
        {permContext?.reportRead && (
          <NavLink
            className="o-sidebar-item"
            to="/dashboard/reports/reports"
            activeStyle={activeStyle}
            onClick={() => {
              props.setToggleSidebar(false);
            }}
          >
            <IconChartBars />
            <div>Rapports</div>
          </NavLink>
        )}
      </div>
      <div className="o-sidebar-bottom">
        <NavLink
          exact
          className="o-sidebar-item"
          to="/"
          onClick={() => {
            localStorage.clear();
          }}
        >
          <IconLogout />
          <div>{t('global:logout')}</div>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
