import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { unlockPinReq } from '../../../../../api/client/card/forcePin';

const ConfirmationUnlockPinCard = ({ userID, card, unlockPinCardModal, setUnlockPinCardModal }) => {

  const [loading, setLoading] = useState(false);

  const unlockPinHandler = async () => {
    setLoading(true);
    let status = await unlockPinReq(userID, card.wallet_id, card.cardId);
    setLoading(false);
    if (status === 'success') {
      close();
    }
  }

  const close = () => {
    setUnlockPinCardModal(false);
  }

  return (
    <Modal isOpen={unlockPinCardModal} toggle={setUnlockPinCardModal}>
      <div>
        <div className="d-flex flex-column ">
        <div className="o-title text-center mb-4">Veuillez confirmer le déblocage de la carte.</div>
          <Button
            className="mb-2 mt-2"
            onClick={unlockPinHandler}
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirmer'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationUnlockPinCard;
