import { REDIRECT_URL, CLIENT_ID } from '../../utils/constants-env.js';
import { getJwtToken } from '../../api/auth/index';
import { localStorageKeys } from '../../api/constants.js';

export const startOnlyOneLogin = () => {
    //console.log('startOnlyOneLogin')
    // Google Sign in Javascript reference: https://developers.google.com/identity/gsi/web/reference/js-reference
    window.onGoogleLibraryLoad = () => {
      window.google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: signInCallbackOnlyOne,
      cancel_on_tap_outside: true,
      auto_select: false,
      ux_mode: 'popup',
      });

      window.google.accounts.id.renderButton(document.getElementById("signinButtonOnlyone"), {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        shape: 'rectangular',
      });
    };
  };

export const signInCallbackOnlyOne = (authResult) => {
  console.log(authResult);
  if (authResult['code']) {
    // Ancienne methode d'authentification Google Signin
    // Hide the sign-in button now that the user is authorized, for example:
    //$('#signinButtonOnlyone').attr('style', 'display: none');
    getJwtToken(authResult['code'], REDIRECT_URL).then((tokenId) => {
      //alert(tokenId);
      document.getElementById('signinButtonOnlyone').style.display = 'block';
      document.getElementById('loader').style.display = 'none';
      if (tokenId) {
        localStorage.setItem(localStorageKeys.onlyone_token, tokenId);
        window.location.href = '/dashboard/clientbystatus/onboarding_finalized';
      } else {
        alert('Pas de JwtTokenId obtenu')
        localStorage.removeItem(localStorageKeys.onlyone_token);
        localStorage.removeItem(localStorageKeys.permissions);
      }
    });
  } else if (authResult['credential']) {
    document.getElementById('signinButtonOnlyone').style.display = 'none';
    document.getElementById('loader').style.display = 'flex';
    console.log(authResult['credential']);
    var tokenId = authResult['credential'];
    localStorage.setItem(localStorageKeys.onlyone_token, tokenId);
    window.location.href = '/dashboard/clientbystatus/onboarding_finalized';
  } else {
    document.getElementById('signinButtonOnlyone').style.display = 'block';
    document.getElementById('loader').style.display = 'none';
    alert('Problème d\'authentification, pas de code en retour')
  }
};

export const signinOnlyone = () => {
  document.getElementById('signinButtonOnlyone').style.display = 'none';
  document.getElementById('loader').style.display = 'flex';
  window.auth2.grantOfflineAccess().then(signInCallbackOnlyOne);
};
