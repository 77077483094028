import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updateClientKycAccept } from '../../../../api/client/updateClient';
import IconLoading from '../../../../assets/icons/loading.svg';
import IconCheck from '../../../../assets/icons/check.svg';
import IconCross from '../../../../assets/icons/cross.svg';

const ConfirmationKycAccept = ({ kycAcceptOpen, setKycAcceptOpen, userData, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const [responseText, setResponseText] = useState('');

  const KycAcceptClientHandler = async () => {
    setLoading(true);
    let status = await updateClientKycAccept(userData.userId);
    status ? setResponseText(status) : setResponseText('fail');
    setLoading(false);
    if(status){
      refreshData();
    }
  }

  const close = () => {
    setKycAcceptOpen(false);
    setResponseText('');
  }

  const ShowResponseText = () => (
    <div style={{textAlign: 'center', margin: "20px auto 30px auto"}}>
      {responseText} <br />
      {
      responseText === 'success' ?
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCheck} alt="" /> :
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCross} alt="" />
      }
    </div>
  );


  return (
    <Modal isOpen={kycAcceptOpen} toggle={setKycAcceptOpen}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Accepter le KYC du client ?</div>
          {
          loading ?
            <img className="d-flex mx-auto" style={{ margin: "20px auto 30px auto" }} src={IconLoading} height="25" alt="" /> :
            responseText.length ? <ShowResponseText /> : ''
          }
          {
            !responseText.length ?
            <Button
              className="mb-2 mt-2"
              onClick={KycAcceptClientHandler}
              type="submit"
            >Confirmer</Button> :
            ''
          }

          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Retour</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationKycAccept;
