import React, {useState} from 'react';
import * as Yup from 'yup';
import { ONLYONE_WEB_APP_BASE_URL } from '../../../../../utils/constants-env.js';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientAskUrlKycLivenessWithSmsReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationAskKycLivenessWithSMS = ({ askKycLivenessWithSMSOpen, setAskKycLivenessWithSMSOpen, user, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();

  const askKycLivenessUrlHandler = async (id) => {
    setLoading(true);
    let responseData = await clientAskUrlKycLivenessWithSmsReq(id);
    setLoading(false);
    console.log(responseData);
    if (responseData) {
      refreshData();
      setDetailData(responseData);
    }
  }

  const AskKycLivenessWithSMSInitialValues = {
    //redirect_url : ONLYONE_WEB_APP_BASE_URL + '/kycliveness/success',
  };
  const AskKycLivenessWithSMSSchema = Yup.object().shape({
    //redirect_url: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={askKycLivenessWithSMSOpen} toggle={setAskKycLivenessWithSMSOpen}>
    { detailData ? (
      <div>
        <div className="o-information-detail__item mb-2">
          <div>URL KYC Liveness</div>
          <div className="ml-2">Un SMS contenant l'URL de KYCLiveness a été envoyé !</div>
        </div>
        <Button
          btnType="outline"
          type="button"
          onClick={() => {
            setDetailData(null);
            setAskKycLivenessWithSMSOpen(false);
          }
        }
        >Fermer</Button>
      </div>
): (<Formik
      enableReinitialize={true}
      initialValues={AskKycLivenessWithSMSInitialValues}
      validationSchema={AskKycLivenessWithSMSSchema}
      onSubmit={(values) => {
        console.log('Récupération d\'une URL de KycLiveness avec envoi d\'un SMS');
        askKycLivenessUrlHandler(user.userId);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Confirmez-vous que vous voulez envoyer un SMS <br/> avec une nouvelle URL de KYCLiveness<br/>
          au numéro {user?.mobile}
          </div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setAskKycLivenessWithSMSOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
  )}


    </Modal>
  );
};

export default ConfirmationAskKycLivenessWithSMS;
