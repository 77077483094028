import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { deleteStatementReq } from '../../../../../api/client/statements';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationDeleteStatement = ({ statementId, deleteStatementOpen, setDeleteStatementOpen, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id) => {
    setLoading(true);
    let status = await deleteStatementReq(id);
    setLoading(false);
    setDeleteStatementOpen(false);
    if (status === 'success') {
      refreshData();
    }
  }

  const DeleteStatementInitialValues = {
  };
  const DeleteStatementSchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={deleteStatementOpen} toggle={setDeleteStatementOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteStatementInitialValues}
      validationSchema={DeleteStatementSchema}
      onSubmit={(values) => {
        console.log('Delete statement submit');
        deleteHandler(statementId);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer la suppression du relevé de compte sélectionné ({statementId})</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setDeleteStatementOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteStatement;
