import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import { addInvitationCodeReq } from '../../../../../api/invitationcode/invitationcode';
import { InvitationCodeSchema, InvitationCodeInitialValues } from '../invitationcode.utils';
import { YesNo } from '../../../../common.utils'
import Dropdown from '../../../../../components/dropdown';

const AddInvitationCode = ({ addModal, setAddModal, currentItem, refreshData }) => {
  const { t } = useTranslation();
  const [currentInvitationCode, setCurrentInvitationCode] = useState();

  useEffect(() => {
    if (currentItem) {
      setCurrentInvitationCode(
        {
          invitation_code: currentItem.invitation_code,
          execution_date: currentItem.execution_date,
      });
    }
  }, [currentItem]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentInvitationCode ? currentInvitationCode : InvitationCodeInitialValues}
        validationSchema={InvitationCodeSchema}
        onSubmit={(values) => {
          let data = {
            invitation_code: values.invitation_code,
            single_use: values.single_use.value,
          }
          addInvitationCodeReq(data).then((res) => {
            if (res === 'success') {
              refreshData();
              setAddModal(false);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-title text-center">
              {currentInvitationCode ? "Modification Code d'Invitation" : "Nouveau Code d'Invitation"}
            </div>
            <Field
              name="invitation_code"
              className="mt-5"
              label="Code d'invitation"
              placeholder="Code *"
              component={InputFormik}
              disabled={currentInvitationCode}
            />
            <Field
              name="single_use"
              label="Usage unique"
              placeholder="Usage unique *"
              options={YesNo}
              component={Dropdown}
            />
            <div className="d-flex flex-column mt-5">
              <Button className="mb-2" type="submit">
                {currentInvitationCode ? t('global:validate') : t('global:add')}
              </Button>
              <Button
                btnType="outline"
                type="button"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddInvitationCode;
