import moment from 'moment';

function renderMandatesArray(array){
  return array.map((item, index) => <div>{ item }</div>);
}

export const tableMandateHeader = ['Beneficiary ID', 'Nom', 'Mandats','Mandats Bannis','Statut'];

export const renderMandateTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.beneficiaryId,
      beneficiaryID: item.beneficiaryId,
      name: item.name,
      mandats: item.sddCoreKnownUniqueMandateReference ? renderMandatesArray(item.sddCoreKnownUniqueMandateReference) : '-',
      blacklist: item.sddCoreBlacklist ? renderMandatesArray(item.sddCoreBlacklist) : '-',
      status: item.status,
    }),
  );
  return data;
};
