import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './header.css';
import IconHamburger from '../../assets/icons/hamburger.svg';
import Input from '../../components/input';
import Button from '../../components/button';
import SidebarContext from '../../contexts/sidebarContext';

function Header({
  HeaderIcon,
  headerTitle,
  searchInput,
  setSearchInput,
  setAddModal,
  setCurrentItem,
  setSelectedID,
  isAdd,
  disabledSearch,
  rightFragment,
}) {
  const { t } = useTranslation();
  const { setToggleSidebar } = useContext(SidebarContext);
  return (
    <div className="d-flex o-header">
      <div className="">
        {/*only visible in mobile view*/}
        <img
          className="o-icon-hamburger mb-4"
          src={IconHamburger}
          alt="hamburger-menu"
          onClick={() => {
            setToggleSidebar(true);
          }}
        />
        <div className="o-icon-header">
          <HeaderIcon className=" mb-4" style={{ color: 'var(--primary)' }} />
        </div>

        <h1 className="o-title  mb-4">{headerTitle}</h1>
        {!disabledSearch && (
          <div className="o-header__search">
            <Input
              label='Filtre'
              placeholder='Filtre'
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              multiline={false}
            />
          </div>
        )}
      </div>
      {isAdd && (
        <Button
          className="mb-4"
          onClick={() => {
            setAddModal(true);
            setCurrentItem && setCurrentItem(null);
            setSelectedID && setSelectedID(null);
          }}
        >
          +&nbsp;{t('global:add')}
        </Button>
      )}
      {rightFragment}
    </div>
  );
}

export default Header;
