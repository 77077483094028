import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './gae_service.css';
// import Button from '../button';
// import IconHand from '../../assets/icons/hand.svg';
// import IconCalc from '../../assets/icons/calculator.svg';
// import IconWarning from '../../assets/icons/triangle.svg';
// import IconRenew from '../../assets/icons/renew.svg';
// import IconEdit from '../../assets/icons/edit.svg';
// import IconDollarRenew from '../../assets/icons/dollar-renew.svg';
// import IconDoc from '../../assets/icons/doc.svg';
import IconLoading from '../../assets/icons/loading.svg';

import { getGaeServiceInfos } from '../../api/status';


function GaeServiceStatus({
  serviceId,
  imageUrl,
  ...props}) {

  const [loadingImage, setLoadingImage] = useState(false);
  const [serviceOK, setServiceOK] = useState();
  const [serviceData, setServiceData] = useState();

  useEffect(() => {
    setLoadingImage(true);
    getGaeServiceInfos(serviceId)
      .then((res) => {
        /*Render and set data of the list*/
        console.log(res);
        setServiceData(res);
        setServiceOK(true)
      })
      .catch((error) => {
        // console.log(error);
        setServiceOK(false)
      })
      .finally(() => {
        setLoadingImage(false);
      });

  }, []);

  return(
    <div>
    <div className="m-3 o-gae-service-card">
      <div className="m-3">
        <img className="d-flex mx-auto o-gae-service-card-img" width="60" src={imageUrl} alt="" />
        <div className="o-gae-service-card__name">Service {serviceId}</div>
      </div>
      <hr />
      <div className="d-flex mx-auto">
      {
        loadingImage ? <img className="d-flex mx-auto" width="40" src={IconLoading} alt="" /> :
         serviceOK ? <div className="o-green mx-auto">{serviceData?.app_version}</div> : <div className="o-red mx-auto">NOK</div>
      }
      </div>
      <hr />
      <div className="d-flex">
        <div className="mt-2 mx-auto">
          {serviceData?.versions?.cerebro !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Cerebro</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.cerebro}</div>
          </div>
          )}
          {serviceData?.versions?.friday !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Friday</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.friday}</div>
          </div>
          )}
          {serviceData?.versions?.jarvis !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Jarvis</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.jarvis}</div>
          </div>
          )}
          {serviceData?.versions?.notif_center !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Notif&nbsp;Center</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.notif_center}</div>
          </div>
          )}
          {serviceData?.versions?.sauron !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Sauron</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.sauron}</div>
          </div>
          )}
          {serviceData?.versions?.scrooge !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Scrooge</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.scrooge}</div>
          </div>
          )}
          {serviceData?.versions?.shared_model !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Shared&nbsp;Model</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.shared_model}</div>
          </div>
          )}
          {serviceData?.versions?.shared_spring_utils !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Shared&nbsp;Spring&nbsp;Utils</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.shared_spring_utils}</div>
          </div>
          )}
          {serviceData?.versions?.smaug !== '-' && (
          <div>
            <div className="o-gae-service-card__label"><b>Smaug</b></div>
            <div className="o-gae-service-card__number">{serviceData?.versions?.smaug}</div>
          </div>
          )}
        </div>
      </div>
    </div>
    </div>
  )

}

export default GaeServiceStatus;
