import { backendApiEndpoint } from '../constants.js';
import { CheckAuth,  instanceBackendApi } from '../index';
import { toast } from 'react-toastify';
import axios from 'axios';
var qs = require('qs');

export async function LockUnlockAccount(clientID, dataVal) {

try {
  let data = qs.stringify({
    'client_status': dataVal
  });

  const res = await instanceBackendApi.post(backendApiEndpoint.client_update_status.replace('{{userId}}', clientID), data);
  if (res.status === 200) {
    toast('Blocage avec succès');
    return 'success';
  } else {
    toast.error('Une erreur est survenue!');
    return 'fail';
  }
} catch (error) {
  toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
  CheckAuth(error);
}

}
