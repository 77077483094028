import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from '../../../../components/header';
import { InputFormik } from '../../../../components/input';
import Dropdown from '../../../../components/dropdown';
import Button from '../../../../components/button';
import IconLoading from '../../../../assets/icons/loading.svg';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import { walletBalanceHistory } from '../../../../api/client/wallet/wallet';

import {  Chart as ChartJS,  CategoryScale,  LinearScale,  PointElement,  LineElement,
  BarElement,  Title,  Tooltip,  Legend } from 'chart.js';
import { Line, Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,
  BarElement, Title, Tooltip, Legend);

const emptyData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      borderWidth: 1,
    },
  ],
};

const optionsHistoryBalance = {
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = ' ';

                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
    }
};

const balanceHistoryData = (res) => {
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.date)
  );
  let currentBalances = [];
  res.map((item) =>
    currentBalances.push(item.current_balance)
  );
  let authorizedBalances = [];
  res.map((item) =>
    authorizedBalances.push(item.authorized_balance)
  );
  let currentAuthorization = [];
  res.map((item) =>
    currentAuthorization.push(item.current_authorizations)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: 'Balance en €',
      data: currentBalances,
      fill: false,
      tension: 0.2,
      backgroundColor: '#00BD86',
      borderColor: '#00BD86',
      borderWidth: 1,
    },
    {
      label: 'Authorized Balance en €',
      data: authorizedBalances,
      fill: false,
      tension: 0.2,
      backgroundColor: '#4af2a1',
      borderColor: '#4af2a1',
      borderWidth: 1,
    },
    {
      label: 'Authorizations en €',
      data: currentAuthorization,
      fill: false,
      tension: 0.2,
      backgroundColor: '#0400ff',
      borderColor: '#0400ff',
      borderWidth: 1,
    },
  ],
  };
  return result;
};


const WalletBalanceHistory = () => {
  const { userId, walletId } = useParams();
  const [tableHistoryData, setTableHistoryData] = useState(emptyData);
  const [historyLoading, setHistoryLoading] = useState(false);

  useEffect(() => {
    //searchBalanceHistory();
  }, []);

  const searchBalanceHistory = async (values) => {
    setHistoryLoading(true);
    walletBalanceHistory(userId, walletId, values)
        .then((res) => {
          //console.log(res);
          setTableHistoryData(balanceHistoryData(res));
        })
        .finally(() => setHistoryLoading(false));
  }

  const FilterInitialValues = {
    startDate: moment(Date.now()).add(-2, 'M').format('YYYY-MM-DD'),
    endDate: moment(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
  };
  const FilterSchema = Yup.object().shape({
  });

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${userId}`}>Détail Client</a> <ArrowRight />
        <a href={`/dashboard/client/${userId}/wallet/${walletId}`}>Détail Wallet</a> <ArrowRight />
        Historique de Balance
      </Fragment>
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={FilterInitialValues}
        validationSchema={FilterSchema}
        onSubmit={(values) => {
          searchBalanceHistory(values)
        }}
      >
        <Form className="form-inline mt-5 ml-5">
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <Field
                    name="startDate"
                    className="mb-2 ml-5"
                    label='Début (YYYY-MM-DD)'
                    placeholder='Début (YYYY-MM-DD)'
                    component={InputFormik}
                  />
              </div>
              <div className="col-sm">
                <Field
                      name="endDate"
                      className="mb-2 ml-5"
                      label='Fin (YYYY-MM-DD)'
                      placeholder='Fin (YYYY-MM-DD)'
                      component={InputFormik}
                    />
              </div>
              <div className="col-sm">
                  <Button
                    className="mb-2 ml-5"
                    type="submit"
                  >{historyLoading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch/>
      <div className="d-flex o-content justify-content-center">
        <div>
        {
          historyLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Line width={1600} height={800} data={tableHistoryData} options={optionsHistoryBalance}/>
        }
        </div>
      </div>
    </div>
  );
};

export default WalletBalanceHistory;
