import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { GroupSchema, GroupInitialValues } from '../group.utils';
import { getGroupDetailReq } from '../../../../api/group/groups';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../assets/icons/loading.svg';

const ShowGroup = ({ showModal, setShowModal, selectedID }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [permissionDrp, setPermissionDrp] = useState();
  const [status, setStatus] = useState('loading');
  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      let options = [];
      getGroupDetailReq(selectedID)
        .then((res) => {
          res.permissions &&
            res.permissions.map((item) => options.push({ value: item.code, label: item.name }));
          setInitialValue({
            name: res.name,
            description: res.description,
            level: res.level,
          });
          setPermissionDrp(options);
        })
        .finally(() => {
          setStatus('done');
        });
    }
  }, [selectedID]);
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : GroupInitialValues}
        validationSchema={GroupSchema}
      >
        {() => (
          <Form>
            <div className="o-title text-center">{t('group:detail_group')}</div>
            {status === 'loading' ? (
              <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="name"
                  className="mt-5"
                  label={t('group:name')}
                  placeholder={t('group:name_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="description"
                  className="mt-5"
                  label={t('group:description')}
                  placeholder={t('group:description_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="level"
                  className="mt-5"
                  label={t('group:level')}
                  placeholder={t('group:level_placeholder') + ' *'}
                  component={InputFormik}
                  type="number"
                  onlyRead
                />
                <Field
                  name="permission"
                  label={t('group:permission') + ' *'}
                  placeholder={t('group:permission_placeholder') + ' *'}
                  options={permissionDrp}
                  value={permissionDrp}
                  component={Dropdown}
                  isMulti
                  onlyRead
                />
                <div className="d-flex flex-column mt-5">
                  <Button
                    btnType="outline"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ShowGroup;
