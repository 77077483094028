import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { ONLYONE_WEB_APP_BASE_URL } from '../../../../../utils/constants-env.js';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { deleteClientBillingHistoryReq } from '../../../../../api/client/billing';
import { clientWalletListReq } from '../../../../../api/client/wallet';
import IconLoading from '../../../../../assets/icons/loading.svg';
import Dropdown from '../../../../../components/dropdown';

const ConfirmationDeleteBillingHistory = ({ deleteBillingHistoryOpen, setDeleteBillingHistoryOpen, userId, userFeatureHistory, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];

  useEffect(() => {
    //for wallet dropdown
    clientWalletListReq(userId).then((res) => {
      /*Render and set data of the list*/
      res.map((item) => walletOptions.push({ label: item.walletId + ' - ' + item.name + ' - ' + item.authorized_balance + ' €', value: item.walletId }));
      setWalletListDrp(walletOptions);
    });
  }, []);

  const deleteBillingHistoryHandler = async (id) => {
    setLoading(true);
    let responseData = await deleteClientBillingHistoryReq(id, userFeatureHistory?.user_feature_history_id);
    setLoading(false);
    console.log(responseData);
    if (responseData === 'success') {
      refreshData();
      setDeleteBillingHistoryOpen(false);
    }
  }

  const DeleteBillingHistoryInitialValues = {
  };
  const DeleteBillingHistorySchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={deleteBillingHistoryOpen} toggle={setDeleteBillingHistoryOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteBillingHistoryInitialValues}
      validationSchema={DeleteBillingHistorySchema}
      onSubmit={(values) => {
        deleteBillingHistoryHandler(userId);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Confirmez vous la suppression de cette ligne d'élément de facturation</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setDeleteBillingHistoryOpen(false);
            }}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteBillingHistory;
