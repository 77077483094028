import moment from 'moment';

function renderKycLivenessArray(array){
  return array.map((item, index) => <div>{ item }</div>);
}

export const tableKycLivenessHeader = ['Identification ID', 'URL KYC','KYC Status', 'Score','Nom détecté','Date de naissance', 'Date de création'];

export const renderKycLivenessTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.identificationid,
      identificationID: item.identificationid,
      identification_url : item.identification_url?item.identification_url : "-",
      kyc_status: item.kyc_status?item.kyc_status:'-',
      score: item.score ? item.score : '0',
      name: (item.first_name?item.first_name:'') + ' - ' + (item.last_name?item.last_name:''),
      birth_date: item.birth_date?item.birth_date:'-',
      createdDate: item.created ? moment(item.created).format('DD/MM/YYYY HH:mm') : '-',
    }),
  );
  return data;
};
