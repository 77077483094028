import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import IconLoading from '../../../../assets/icons/loading.svg';
import documentImage from '../../../../assets/img/document-image.png';
import { downloadDocument } from '../../../../api/client/downloadDocument';
import {
  clientKycLivenessListReq, clientAskUrlKycLivenessReq, downloadKycLivenessReportReq, generateAndDownloadKycLivenessReportReq
} from '../../../../api/client/kycliveness';

const ShowDocument = ({ userId, document, showModal, setShowModal }) => {

  const [loading, setLoading] = useState(false);
  const [binaryImg, setBinaryImg] = useState('');

  const close = () => {
    setShowModal(false);
  }

  const downloadKycLivenessReport = async (userId, kycDocument) => {
    setLoading(true);
    if (userId && kycDocument?.bucketFileName) {
      const res = await downloadKycLivenessReportReq(userId, kycDocument.documentId);
    } else if (userId && kycDocument?.documentId) {
      const res = await generateAndDownloadKycLivenessReportReq(userId, kycDocument?.documentId);
    }
    setLoading(false);
  }

  const getDocumentHandler = async () => {
    setLoading(true);
    console.log('document =', document);
    if (document?.type?.trzDocumentType == "26") {
      downloadKycLivenessReport(userId, document);
      setShowModal(false);
    } else {
      const data = await downloadDocument(userId, document.documentId);
      if (document.fileName.endsWith('pdf')) {
        console.log('first pdf');
        const file = new Blob([data], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileUrl;
        setShowModal(false);
      } else if (document.fileName.toLowerCase().endsWith('jpg') || document.fileName.toLowerCase().endsWith('jpeg') || document.fileName.toLowerCase().endsWith('png') || document.type?.level === 'SELFIE') {
        console.log('image');
        const file = new Blob([data], { type: "image/jpg", type: "image/png"});
        const fileUrl = URL.createObjectURL(file);
        setBinaryImg(fileUrl);
        setLoading(false);
      } else {
        console.log('default pdf');
        const file = new Blob([data], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileUrl;
        setShowModal(false);
      }
    }


  }

  useEffect(() => {
    getDocumentHandler();
  }, []);


  return (
    <Modal isOpen={showModal} toggle={setShowModal} style={{ padding: '0 0', width: 'auto'}}>
      {
        loading ? <img className="d-flex mx-auto" src={IconLoading} alt="" /> :
        <img width="600" src={binaryImg} />
      }
    </Modal>
  );
};

export default ShowDocument;
