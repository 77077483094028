import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateCardOptions } from '../../../../../api/client/card/cardoptions';
import { YesNoInteger } from '../../../../common.utils';
import Dropdown from '../../../../../components/dropdown';

const ConfirmationUpdateCardOptions = ({ userID, card, updateOptionsModal, setUpdateOptionsModal, refreshData }) => {

  const [loading, setLoading] = useState(false);
  const [cardOptions, setCardOptions] = useState();

  const updateCardOptionsHandler = async (values) => {
    setLoading(true);
    console.log('card.cardOptions : ' + card.cardOptions);
    let dataPost = {
      optionAtm: values.optionAtm.value,
      optionForeign: values.optionForeign.value,
      optionOnline: values.optionOnline.value,
      optionNfc:values.optionNfc.value,
    }

    let status = await updateCardOptions(userID, card.wallet_id, card.cardId, dataPost);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setUpdateOptionsModal(false);
  }

  const UpdateOptionsInitialValues = {
    optionAtm: card?.cardOptions?.optionAtm ? YesNoInteger[0] : YesNoInteger[1],
    optionForeign: card?.cardOptions?.optionForeign ? YesNoInteger[0] : YesNoInteger[1],
    optionOnline: card?.cardOptions?.optionOnline ? YesNoInteger[0] : YesNoInteger[1],
    optionNfc: card?.cardOptions?.optionNfc ? YesNoInteger[0] : YesNoInteger[1],
  };
  const UpdateOptionsSchema = Yup.object().shape({
    optionAtm: Yup.object().required('Requis'),
    optionForeign: Yup.object().required('Requis'),
    optionOnline: Yup.object().required('Requis'),
    optionNfc: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updateOptionsModal} toggle={setUpdateOptionsModal}>
    <Formik
      enableReinitialize={true}
      initialValues={cardOptions ? cardOptions : UpdateOptionsInitialValues}
      validationSchema={UpdateOptionsSchema}
      onSubmit={(values) => {
        console.log('Update card options' + cardOptions);
        updateCardOptionsHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification des options de paiement de la carte </div>
          <Field
            name="optionAtm"
            className="mt-5"
            label="Retrait ATM *"
            placeholder="Retrait ATM *"
            component={Dropdown}
            options={YesNoInteger}
          />
          <Field
            name="optionForeign"
            className="mt-5"
            label="Paiement à l'étranger *"
            placeholder="Paiement à l'étranger *"
            component={Dropdown}
            options={YesNoInteger}
          />
          <Field
            name="optionOnline"
            className="mt-5"
            label="Paiement en ligne *"
            placeholder="Paiement en ligne *"
            component={Dropdown}
            options={YesNoInteger}
          />
          <Field
            name="optionNfc"
            className="mt-5"
            label="Paiement NFC *"
            placeholder="Paiement NFC *"
            component={Dropdown}
            options={YesNoInteger}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCardOptions;
