import { endpoint, backendApiEndpoint } from '../constants.js';
import { CheckAuth, instance, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function reportOverview() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.report_overview);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function reportIncomes(data) {
  try {
    let queryParams = { params: data};
    const res = await instanceBackendApi.get(backendApiEndpoint.report_incomes, queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function reportIncomesGroupByBillingProduct(data) {
  try {
    let queryParams = { params: data};
    const res = await instanceBackendApi.get(backendApiEndpoint.report_incomes_by_types, queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function reportValidatedUsers(data) {
  try {
    let queryParams = { params: data};
    const res = await instanceBackendApi.get(backendApiEndpoint.report_validatedusers, queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function reportHistoryUsersStatusHistory(data) {
  try {
    let queryParams = { params: data};
    const res = await instanceBackendApi.get(backendApiEndpoint.report_userstatus_history, queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
