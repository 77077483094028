import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconPermission } from '../../../assets/icons/icons-sidebar';

const DashboardHome = () => {
  return (
    <div>
      Bienvenue sur la BackOffice de OnlyOne
    </div>
  );
};

export default DashboardHome;
