import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { uploadCardFilesReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../assets/icons/loading.svg';
import IconDelete from '../../../../../assets/icons/delete.svg';

const ConfirmationUploadIsicCardFiles = ({ uploadIsicCardFilesOpen, setUploadIsicCardFilesOpen, userId, isicCard, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedProof, setSelectedProof] = useState(null);

  const submitUploadISicCardFiles = async () => {
    setLoading(true);
      let status = await uploadCardFilesReq(userId, isicCard.cardid, selectedPhoto, selectedProof);
    setLoading(false);
    setUploadIsicCardFilesOpen(false);
    if(status === 'success'){
      refreshData();
    }
  }

  const displaySelectedPhoto = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <img src={IconDelete} height="20" onClick={() => setSelectedPhoto(null)} alt="" />
      </div>
    )
  }

  const displaySelectedProof = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <img src={IconDelete} height="20" onClick={() => setSelectedProof(null)} alt="" />
      </div>
    )
  }

  const UploadIsicCardFilesInitialValues = () => {
    return {
    }
  };
  const UploadIsicCardFilesSchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={uploadIsicCardFilesOpen} toggle={setUploadIsicCardFilesOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UploadIsicCardFilesInitialValues()}
      validationSchema={UploadIsicCardFilesSchema}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Upload des fichiers photo et/ou preuve de scolarité</div>
          <div style={{textAlign: 'center', marginTop: '45px', marginBottom: '15px', position: 'relative'}}>
            <a style={{color: '#00DB9B', textDecoration: 'underlined'}}>
              {selectedPhoto?.name ? displaySelectedPhoto(selectedPhoto.name) : '+ Add Photo'}
            </a>
            <input name="photo" type="file" className="custom-file-input"
              onChange={e => setSelectedPhoto(e.target.files[0])}
            />
          </div>
          <div style={{textAlign: 'center', marginTop: '45px', marginBottom: '45px', position: 'relative'}}>
            <a style={{color: '#00DB9B', textDecoration: 'underlined'}}>
              {selectedProof?.name ? displaySelectedProof(selectedProof.name) : '+ Add Proof'}
            </a>
            <input name="proof" type="file" className="custom-file-input"
              onChange={e => setSelectedProof(e.target.files[0])}
            />
          </div>
          <Button
            className="mb-2"
            onClick={submitUploadISicCardFiles}
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUploadIsicCardFilesOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUploadIsicCardFiles;
