import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { createTransferReq } from '../../../../api/transfers/transfers';
import { clientWalletListReq } from '../../../../api/client/wallet';
import IconLoading from '../../../../assets/icons/loading.svg';
import Dropdown from '../../../../components/dropdown';

const ConfirmationCreateTransfer = ({ createTransferOpen, setCreateTransferOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];

  useEffect(() => {
    //for wallet dropdown
    if (createTransferOpen) {
      clientWalletListReq(userId).then((res) => {
        /*Render and set data of the list*/
        res.map((item) => walletOptions.push({ label: item.walletId + ' - ' + item.name, value: item.walletId }));
        setWalletListDrp(walletOptions);
      });
    }
  }, [createTransferOpen]);

  const createTransferHandler = async (userId, data) => {
    setLoading(true);
    data['user_id'] = userId;
    data['walletId'] = data.walletId?.value;
    data['beneficiaryWalletId'] = data.beneficiaryWalletId?.value;
    let status = await createTransferReq(data);
    setLoading(false);
    if(status === 'success'){
      setCreateTransferOpen(false);
      refreshData();
    }
  }

  const CreateTransferInitialValues = {
  };
  const CreateTransferSchema = Yup.object().shape({
    walletId: Yup.object().required('Requis'),
    beneficiaryWalletId: Yup.object().required('Requis'),
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={createTransferOpen} toggle={setCreateTransferOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={CreateTransferInitialValues}
      validationSchema={CreateTransferSchema}
      onSubmit={(values) => {
        createTransferHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Saisir les informations du transfert entre wallet</div>
          <Field
            name="walletId"
            label="Wallet source"
            placeholder="Wallet source *"
            options={walletListDrp}
            component={Dropdown}
          />
          <Field
            name="beneficiaryWalletId"
            label="Wallet bénéficiaire"
            placeholder="Wallet bénéficiaire *"
            options={walletListDrp}
            component={Dropdown}
          />
          <Field
            name="label"
            className="mt-3"
            label="Motif"
            placeholder="Motif *"
            component={InputFormik}
          />
          <Field
            name="amount"
            className="mt-3"
            label="Montant du transfert (€)"
            placeholder="Montant du transfert en €"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setCreateTransferOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationCreateTransfer;
