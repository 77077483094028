import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updateClientEmail } from '../../../../api/client/updateClient';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationUpdateEmail = ({ updateEmailOpen, setUpdateEmailOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const updateHandler = async (id, confirmName) => {
    setLoading(true);
    let status = await updateClientEmail(id, confirmName);
    setLoading(false);
    setUpdateEmailOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  const UpdateEmailInitialValues = (data) => {
    return {
      oldEmail: data ? data.email : '',
      newEmail: '',
      confirmEmail: '',
    }
  };
  const UpdateEmailSchema = Yup.object().shape({
    oldEmail: Yup.string().email('Adresse email non valide').required('Requis'),
    newEmail: Yup.string().email('Adresse email non valide').required('Requis'),
    confirmEmail: Yup.string().email('Adresse email non valide').oneOf([Yup.ref('newEmail'), null], 'Les adresses email doivent être identiques').required('Email confirm is required'),
  });

  return (
    <Modal isOpen={updateEmailOpen} toggle={setUpdateEmailOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateEmailInitialValues(userData)}
      validationSchema={UpdateEmailSchema}
      onSubmit={(values) => {
        console.log('Delete client submit');
        updateHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setUpdateEmailOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer la modification de l'adresse email</div>
          <Field
            name="oldEmail"
            className="mt-3"
            label="Ancien email"
            placeholder="Ancien email *"
            component={InputFormik}
          />
          <Field
            name="newEmail"
            className="mt-3"
            label="Nouvel email"
            placeholder="Nouvel email *"
            component={InputFormik}
          />
          <Field
            name="confirmEmail"
            className="mt-3"
            label="Confirmation du nouvel email"
            placeholder="Confirmation du nouvel email *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUpdateEmailOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateEmail;
