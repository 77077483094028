import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Header from '../../../../components/header';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import IsicCard from '../../../../components/isic/isic_card';
import Logo from '../../../../components/logo';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import IconCancel from '../../../../assets/icons/cancel.png';
import IconHand from '../../../../assets/icons/hand.svg';
import IconCalc from '../../../../assets/icons/calculator.svg';
import IconWarning from '../../../../assets/icons/triangle.svg';
import IconRenew from '../../../../assets/icons/renew.svg';
import IconEdit from '../../../../assets/icons/edit.svg';
import IconDoc from '../../../../assets/icons/doc.svg';
import IconLoading from '../../../../assets/icons/loading.svg';

import {isicCardListReq, isicCardDetailReq} from '../../../../api/client/isic/isiccards'
import ConfirmationImportIsicCard from './modals/confirmationImportIsicCard';
import ConfirmationDeleteIsicCard from './modals/confirmationDeleteIsicCard';
import ConfirmationUploadIsicCardFiles from './modals/confirmationUploadIsicCardFiles';

import {
  tableIsicCardsHeader,
  renderIsicCardsTable,
} from './isic_cards.utils';

const ClientIsicCards = () => {
  const { userId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [isicCardTableData, setIsicCardTableData] = useState([]);
  const [allIsicCards, setAllIsicCards] = useState([]);
  const [allIsicCardStatus, setAllIsicCardStatus] = useState('loading');
  const [selectedIsicCard, setSelectedIsicCard] = useState();

  const [refreshIsicCardLoading, setRefreshIsicCardLoading] = useState(false);

  const [importIsicCardOpen, setImportIsicCardOpen] = useState(false);
  const [deleteIsicCardOpen, setDeleteIsicCardOpen] = useState(false);
  const [uploadIsicCardFilesOpen, setUploadIsicCardFilesOpen] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedTypeItem, setSelectedTypeItem] = useState();

  const refreshIsicCardList = () => {
    setAllIsicCardStatus('loading');
    isicCardListReq(userId).then((res) => {
      setAllIsicCards(res);
      setIsicCardTableData(renderIsicCardsTable(res));
    })
    .finally(() => {
      setSelectedID(null);
      setSelectedIsicCard(null);
      setAllIsicCardStatus('done');
    });
  };

  useEffect(() => {
    refreshIsicCardList();
  }, []);

  useEffect(() => {
    const isicCard = allIsicCards.find((x) => x.cardid === selectedID);
    setSelectedIsicCard(isicCard);
    setSelectedTypeItem('isic_card');
  }, [selectedID]);

  function HeaderTitle() {
    return (
      <Fragment>
        <a href="/dashboard/client">Liste Clients</a> <ArrowRight />{' '}
        <a href={`/dashboard/client/${userId}`}>Détail Client</a> <ArrowRight />
        Cartes ISIC
      </Fragment>
    );
  }

  const refreshIsicCardDetailInfos = async (isicCardId) => {
    setRefreshIsicCardLoading(true);
    isicCardDetailReq(userId, isicCardId).then((res) => {
      refreshIsicCardList();
    }).finally(() => {
      setRefreshIsicCardLoading(false);
    });
  }

  return (
    <div>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div className="o-information-detail__block">
            <div className="o-information-column">
                &nbsp;
            </div>
            <div className="o-information-detail__actions">
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => setImportIsicCardOpen(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Import ISIC Number
              </Button>
                <b>&nbsp;</b>
                <b>&nbsp;</b>
            </div>
          </div>
          <Table
            columns={tableIsicCardsHeader}
            tableData={isicCardTableData}
            status={allIsicCardStatus}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => 'Cartes ISIC'}
            contain
          />
        </div>
        <div className="o-viewer">
          {selectedID && selectedTypeItem === 'isic_card' && selectedIsicCard && (
            <div>
              <IsicCard userId={userId} isicCard={selectedIsicCard}/>
              <div>
                <div className="mt-3">
                  <b>Informations Cartes</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div><b>Card ID / ISIC Number</b></div>
                      <div>{selectedIsicCard?.cardid} / {selectedIsicCard?.isic_card_values?.isic_number}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Statut</b></div>
                      <div>{selectedIsicCard?.status}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>&nbsp;</b></div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div><b>Etablissement</b></div>
                      <div>{selectedIsicCard.school ? selectedIsicCard.school.name : selectedIsicCard.isic_user_values?.etablissement}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>Expiration date</b></div>
                      <div>{selectedIsicCard?.isic_card_values?.isic_stop_validity}</div>
                    </div>
                    <div className="mt-2">
                      <div><b>&nbsp;</b></div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <b>Actions</b>
                  <hr />
                  <Button btnType="outline" onClick={() => refreshIsicCardDetailInfos(selectedIsicCard?.cardid)}>
                    {
                      refreshIsicCardLoading ?
                        <img className="mr-2" src={IconLoading} height="25" alt="" /> :
                        <img className="mr-2" src={IconEdit} alt="" />
                    }
                    Rafraichir la carte
                  </Button>
                  <div><b>&nbsp;</b></div>
                  <Button btnType="outline" onClick={() => setDeleteIsicCardOpen(true)}>
                        <img className="mr-2" src={IconCancel} alt="" />
                    Supprimer la carte
                  </Button>
                  <div><b>&nbsp;</b></div>
                  { selectedIsicCard?.status === 'PENDING' || selectedIsicCard?.status === 'TO_ORDER' ?
                    <Button btnType="outline" onClick={() => setUploadIsicCardFilesOpen(true)}>
                        <img className="mr-2" src={IconEdit} alt="" />
                    Upload files
                    </Button> : ''
                  }
                </div>
              </div>
            </div>
          )}
          {!selectedTypeItem && <Logo className="o-viewer-logo"/>}
        </div>
      </div>

      <ConfirmationImportIsicCard
        importIsicCardOpen={importIsicCardOpen}
        setImportIsicCardOpen={setImportIsicCardOpen}
        userId={userId}
        refreshData={refreshIsicCardList}
      />
      {
        selectedIsicCard?.cardid ?
      <ConfirmationDeleteIsicCard
        deleteIsicCardOpen={deleteIsicCardOpen}
        setDeleteIsicCardOpen={setDeleteIsicCardOpen}
        userId={userId}
        isicCard={selectedIsicCard}
        refreshData={refreshIsicCardList}
      /> : ''
      }
      {
        selectedIsicCard?.cardid ?
      <ConfirmationUploadIsicCardFiles
        uploadIsicCardFilesOpen={uploadIsicCardFilesOpen}
        setUploadIsicCardFilesOpen={setUploadIsicCardFilesOpen}
        userId={userId}
        isicCard={selectedIsicCard}
        refreshData={refreshIsicCardList}
      /> : ''
      }
    </div>
  );
};

export default ClientIsicCards;
