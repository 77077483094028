import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientAclRole } from '../../../../api/client/updateClient';
import { aclRolesReq } from '../../../../api/config/config';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationUpdateAclRole = ({ updateAclRoleOpen, setUpdateAclRoleOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [aclRoleData, setAclRoleData] = useState([]);
  const [currentItem, setCurrentItem] = useState();

  const updateAclRoleHandler = async (id, values) => {
    setLoading(true);
    let reqParams = {"acl_role" : values.acl_role?.value};
    let status = await updateClientAclRole(id, reqParams);
    setLoading(false);
    setUpdateAclRoleOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  useEffect(() => {
    /*Get trustlevel list*/
    aclRolesReq()
      .then((res) => {
        let aclRoleValues = [];
        res.map((item) => aclRoleValues.push({ value: item, label: item}));
        setAclRoleData(aclRoleValues);
      });
  }, []);

  const UpdateAclRoleInitialValues = (data) => {
    return {
      acl_role: data ? { value: data.acl_role , label: data.acl_role } : {},
    }
  };
  const UpdateAclRoleSchema = Yup.object().shape({
    //trust_level: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateAclRoleOpen} toggle={setUpdateAclRoleOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateAclRoleInitialValues(userData)}
      validationSchema={UpdateAclRoleSchema}
      onSubmit={(values) => {
        updateAclRoleHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setUpdateAclRoleOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification du rôle du client</div>
          <Field
              name="acl_role"
              label=""
              placeholder="Rôle du client"
              component={Dropdown}
              options={aclRoleData}
            />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUpdateAclRoleOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateAclRole;
