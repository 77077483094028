import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import PermissionsContext from '../../../../contexts/permissionContext';

import { columns, renderTable } from './waitinglist.utils';
import { searchWaitingListPageReq } from '../../../../api/waitinglist/waitinglist';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { InputFormik } from '../../../../components/input';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { WaitingListTypes, WaitingListStatus } from '../../../common.utils';

import Logo from '../../../../components/logo';
import Header from '../../../../components/header';
import Table from '../../../../components/table';
import TablePage from '../../../../components/tablepage';
import { IconGroup } from '../../../../assets/icons/icons-sidebar';
import IconLoading from '../../../../assets/icons/loading.svg';
import IconEdit from '../../../../assets/icons/edit.svg';

import ConfirmationUpdateWaitingListStatus from './modals/confirmationUpdateWaitingListStatus'

const WaitingList = () => {
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allWaitingLists, setAllWaitingLists] = useState([]);
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [updateWaitingListStatusModal, setUpdateWaitingListStatusModal] = useState(false);
  const [pageMetaData, setPageMetaData] = useState({});
  const [tablePageMetaData, setTablePageMetaData] = useState({});
  const [formValues, setFormValues] = useState({status:"WAITING", waiting_type : "INVITATION_CODE"});

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    const waitingList = allWaitingLists.find((x) => x.email === selectedID);
    setSelectedItem(waitingList);
  }, [selectedID]);

  const searchWaitingListListPage = (data, pageNo, pageSize) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(data);
    pageNo = pageNo != null ? pageNo : pageMetaData.pageNumber;
    pageSize = pageSize != null ? pageSize : pageMetaData.pageSize;
    searchWaitingListPageReq(data, pageNo, pageSize)
      .then((res) => {
        setAllWaitingLists(res.content);
        setTableData(renderTable(res.content));
        setPageMetaData(res.pageable);
        setTablePageMetaData({count:res.totalElements});
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  }

  const refreshWaitingListListPage = (pageNo, pageSize) => {
    setSelectedItem(null);
    searchWaitingListListPage(formValues, pageNo, pageSize);
  }

  const convertFormValues = (values) => {
    return {
      status: values.status.value,
      waiting_type: values.waiting_type.value,
    };
  }

  const SearchWaitingListInitialValues = {
    status:WaitingListStatus[0],
    waiting_type:WaitingListTypes[0],
  };
  const SearchWaitingListSchema = Yup.object().shape({
  });

  return (
    <div>
    <div className="o-information-grid">
    <div className="mx-3 mb-3">
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={SearchWaitingListInitialValues}
          validationSchema={SearchWaitingListSchema}
          onSubmit={(values) => {
            searchWaitingListListPage(convertFormValues(values))
          }}
        >
          <Form className="form-inline mt-5 ml-5">
          <div className="container">
            <div className="row">
                <div className="col-sm">
                  <Field
                      name="waiting_type"
                      label=""
                      placeholder="Type de demande"
                      component={Dropdown}
                      options={WaitingListTypes}
                    />
                </div>
                <div className="col-sm">
                  <Field
                      name="status"
                      label=""
                      placeholder="Statut de la demande"
                      component={Dropdown}
                      options={WaitingListStatus}
                    />
                </div>
                <div className="col-sm">
                      <Button className="ml-5" type="submit"
                      >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
                </div>
            </div>
          </div>
          </Form>
        </Formik>
      </div>
      <Header
        headerTitle="Demande dans la liste d'attente"
        HeaderIcon={IconGroup}
        disabledSearch={true}
      />
      <div className="o-content">
        <TablePage
          columns={columns}
          tableData={tableData}
          pageMetaData={tablePageMetaData}
          loadTableData={refreshWaitingListListPage}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => 'Demandes d\'ouverture de compte'}
          contain
        />
      </div>
    </div>
    <div className="o-viewer">
      {selectedID && selectedItem && (
        <div>
          <div>
            <div className="d-flex">
              <div className="mt-2">
                <div className="mt-2">
                  <div><b>Date de la demande</b></div>
                  <div>{selectedItem?.created ? moment(selectedItem?.created).format('DD/MM/YYYY HH:mm') : ' - '}</div>
                </div>
                <div>
                  <div><b>Prospect</b></div>
                  <div>{selectedItem?.firstname} {selectedItem?.lastname} ({selectedItem?.email})</div>
                </div>
                { selectedItem?.company_name && (
                <div>
                  <div><b>Entreprise</b></div>
                  <div>{selectedItem?.company_name ? selectedItem?.company_name : ' - '}</div>
                </div>
                )}
                <div className="mt-2">
                  <div><b>Type de demande</b></div>
                  <div>{selectedItem?.waiting_type}</div>
                </div>
                <div className="mt-2">
                  <div><b>Statut</b></div>
                  <div>{selectedItem?.status}</div>
                </div>
                { selectedItem?.country && (
                <div className="mt-2">
                  <div><b>Pays de résidence demandé</b></div>
                  <div>{selectedItem?.country}</div>
                </div>
                )}
                { selectedItem?.birthday && (
                <div className="mt-2">
                  <div><b>Date de naissance</b></div>
                  <div>{selectedItem?.birthday ? moment(selectedItem?.birthday).format('DD/MM/YYYY') : ' - '}</div>
                </div>
                )}
                { selectedItem?.comment && (
                <div className="mt-2">
                  <div><b>Commentaire</b></div>
                  <div>
                  {selectedItem?.comment?.split(' # ').map((com) => (
                    <div>{com}</div>
                  ))}
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <b>Actions</b>
              <hr />
              <div className="o-information-detail__actions mt-3">
              <Button btnType="outline" onClick={() => setUpdateWaitingListStatusModal(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Modifier Statut
              </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!selectedID && <Logo className="o-viewer-logo"/>}
    </div>
    </div>
    {
    <ConfirmationUpdateWaitingListStatus
      item={selectedItem}
      updateWaitingListStatusModal={updateWaitingListStatusModal}
      setUpdateWaitingListStatusModal={setUpdateWaitingListStatusModal}
      refreshData={refreshWaitingListListPage}
    />
    }
    </div>
  );
};

export default WaitingList;
