import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { updateMembershipLimits } from '../../../../../api/client/clientmembership';

const ConfirmationUpdatePayoutLimits = ({ userID, membership, updatePayoutLimitsModal, setUpdatePayoutLimitsModal, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const updatePayoutLimitsHandler = async (data) => {
    setLoading(true);
    console.log('membership.limit_payout_amount_monthly : ' + membership.limit_payout_amount_monthly);
    let status = await updateMembershipLimits(userID, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setUpdatePayoutLimitsModal(false);
  }

  const UpdatePayoutLimitsInitialValues = {
    limit_payout_amount_daily: membership?.limit_payout_amount_daily != null ? membership.limit_payout_amount_daily : '2000',
    limit_payout_amount_monthly: membership?.limit_payout_amount_monthly != null ? membership.limit_payout_amount_monthly : '5000',
  };
  const UpdatePayoutLimitsSchema = Yup.object().shape({
    limit_payout_amount_daily: Yup.number().required('Requis').min(0).integer(),
    limit_payout_amount_monthly: Yup.number().required('Requis').min(0).integer(),
  });

  return (
    <Modal isOpen={updatePayoutLimitsModal} toggle={setUpdatePayoutLimitsModal}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdatePayoutLimitsInitialValues}
      validationSchema={UpdatePayoutLimitsSchema}
      onSubmit={(values) => {
        console.log('Update payout limits' + values);
        updatePayoutLimitsHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification des plafonds de virement externe </div>
          <Field
            name="limit_payout_amount_daily"
            className="mt-5"
            label="Limite Virement externe / Jour *"
            placeholder="Limite Virement externe / Jour *"
            component={InputFormik}
          />
          <Field
            name="limit_payout_amount_monthly"
            className="mt-5"
            label="Limite Virement externe / Mois *"
            placeholder="Limite Virement externe / Mois *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdatePayoutLimits;
