import moment from 'moment';

export const tableIsicCardsHeader = ['IDs', 'Nom', 'Statut','Etablissement scolaire', 'Date expiration'];

export const renderIsicCardsTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.cardid,
      ids: item.cardid + ' | ' + item.isic_card_values?.isic_number,
      name: item.isic_user_values?.firstname + ' | ' + item.isic_user_values?.lastname,
      status: item.status,
      school: item.school ? item.school.name : item.isic_user_values?.etablissement,
      expiration_date: item.expiration_date,
    }),
  );
  return data;
};
