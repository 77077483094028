import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deletePackageFeatureReq } from '../../../../api/packageFeature/deletePackageFeature';

const DeletePackageFeature = ({ deleteModal, setDeleteModal, selectedID }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">{t('feature:delete_feature')}</div>
          <p
            className="text-center mb-5"
            dangerouslySetInnerHTML={{ __html: t('feature:delete_feature_description') }}
          />
          <Button
            className="mb-2"
            type="submit"
            onClick={() => {
              deletePackageFeatureReq(selectedID).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.href = '/dashboard/admin/package-features';
                  }, 700);
                }
              });
            }}
          >
            {t('global:confirm')}
          </Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePackageFeature;
