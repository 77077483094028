import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deletePermissionReq } from '../../../../api/permission/permissions';
const DeletePermission = ({ deleteModal, setDeleteModal, currentItem }) => {
  const { t } = useTranslation();

  const deleteReq = () => {
    deletePermissionReq(currentItem).then((res) => {
      if (res === 'success') {
        setDeleteModal(false);
      }
    });
  };

  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div>
        <div className="d-flex flex-column">
          <div className="o-title text-center mb-4">{t('permission:delete_permission')}</div>
          <p
            className="text-center mb-5"
            dangerouslySetInnerHTML={{ __html: t('permission:delete_permission_description') }}
          />
          <Button className="mb-2" type="submit" onClick={deleteReq}>
            {t('global:confirm')}
          </Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermission;
