import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientBeneficiaryAddReq } from '../../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationAddBeneficiary = ({ addBeneficiaryOpen, setAddBeneficiaryOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const addHandler = async (id, data) => {
    setLoading(true);
    let status = await clientBeneficiaryAddReq(id, data);
    setLoading(false);
    console.log(status);
    if (status === 'success') {
      setAddBeneficiaryOpen(false)
      refreshData()
    }
  }

  const AddBeneficiaryInitialValues = {
  };
  const AddBeneficiarySchema = Yup.object().shape({
    name: Yup.string().required('Requis'),
    iban: Yup.string().required('Requis'),
    bic: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={addBeneficiaryOpen} toggle={setAddBeneficiaryOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={AddBeneficiaryInitialValues}
      validationSchema={AddBeneficiarySchema}
      onSubmit={(values) => {
        console.log('Ajout d\'un bénéficiaire');
        addHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer l'ajout du beneificiaire, veuillez saisir en majuscule le nom du client</div>
          <Field
            name="name"
            className="mt-5"
            label="Nom"
            placeholder="Nom *"
            component={InputFormik}
          />
          <Field
            name="iban"
            className="mt-5"
            label="IBAN"
            placeholder="IBAN *"
            component={InputFormik}
          />
          <Field
            name="bic"
            className="mt-5"
            label="BIC"
            placeholder="BIC *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setAddBeneficiaryOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationAddBeneficiary;
