import React, {useState} from 'react';
import * as Yup from 'yup';
import { ONLYONE_WEB_APP_BASE_URL } from '../../../../../utils/constants-env.js';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientAskUrlKycLivenessReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationAskKycLivenessURL = ({ askKycLivenessURLOpen, setAskKycLivenessURLOpen, userId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();

  const askKycLivenessUrlHandler = async (id, data) => {
    setLoading(true);
    let responseData = await clientAskUrlKycLivenessReq(id, data);
    setLoading(false);
    console.log(responseData);
    if (responseData) {
      refreshData();
      setDetailData(responseData);
    }
  }

  const AskKycLivenessURLInitialValues = {
    redirect_url : ONLYONE_WEB_APP_BASE_URL + '/kycliveness/success',
  };
  const AskKycLivenessURLSchema = Yup.object().shape({
    //redirect_url: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={askKycLivenessURLOpen} toggle={setAskKycLivenessURLOpen}>
    { detailData ? (
      <div>
      <div className="o-information-detail__item mb-2">
        <div>URL KYC Liveness</div>
        <div className="ml-2">{detailData?.identification_url ? detailData?.identification_url : '-'}</div>
      </div>
      <div className="o-information-detail__item mb-2">
        <div>URL de rediection à la fin du processus</div>
        <div className="ml-2">{detailData?.redirect_url ? detailData?.redirect_url : '-'}</div>
      </div>
      <Button
        btnType="outline"
        type="button"
        onClick={() => setAskKycLivenessURLOpen(false)}
      >Fermer</Button>
      </div>
): (<Formik
      enableReinitialize={true}
      initialValues={AskKycLivenessURLInitialValues}
      validationSchema={AskKycLivenessURLSchema}
      onSubmit={(values) => {
        console.log('Récupération d\'une URL de KycLiveness');
        askKycLivenessUrlHandler(userId, values);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Veuillez Saisir la potentielle URL de Redirection</div>
          <Field
            name="redirect_url"
            className="mt-5"
            label="URL de redirection"
            placeholder="URL de redirection"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setAskKycLivenessURLOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
  )}


    </Modal>
  );
};

export default ConfirmationAskKycLivenessURL;
