import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { isicCardDeleteReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationDeleteIsicCard = ({ deleteIsicCardOpen, setDeleteIsicCardOpen, userId, isicCard, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id, isicCardId) => {
    setLoading(true);
      let status = await isicCardDeleteReq(id, isicCardId);
    setLoading(false);
    setDeleteIsicCardOpen(false);
    if(status === 'success'){
      refreshData();
    }
  }

  const DeleteIsicCardInitialValues = () => {
    return {
    }
  };
  const DeleteIsicCardSchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={deleteIsicCardOpen} toggle={setDeleteIsicCardOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteIsicCardInitialValues()}
      validationSchema={DeleteIsicCardSchema}
      onSubmit={(values) => {
        deleteHandler(userId, isicCard.cardid).then((res) => {
          if (res === 'success') {
            setDeleteIsicCardOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Suppression de la carte ISIC </div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setDeleteIsicCardOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteIsicCard;
