import React, {useState} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deleteClientCodePromo } from '../../../../api/client/updateClient';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationDeleteCodePromo = ({ deleteCodePromoOpen, setDeleteCodePromoOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id) => {
    setLoading(true);
    let status = await deleteClientCodePromo(id);
    setLoading(false);
    setDeleteCodePromoOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  const DeleteCodePromoInitialValues = (data) => {
    return {
      code: data ? data.code_promo : '',
    }
  };
  const DeleteCodePromoSchema = Yup.object().shape({
    code: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={deleteCodePromoOpen} toggle={setDeleteCodePromoOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteCodePromoInitialValues(userData)}
      validationSchema={DeleteCodePromoSchema}
      onSubmit={(values) => {
        console.log('Mise à jour du code promo');
        deleteHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setDeleteCodePromoOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Confirmation de la suppression du code promotionnel du client</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setDeleteCodePromoOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteCodePromo;
