import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { deleteAssignedPackageFeatureReq } from '../../../../../api/assignedPackageFeature/deleteAssignedPackageFeature';

const DeleteAssignedPackageFeature = ({
  deleteModal,
  setDeleteModal,
  selectedID,
  features,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">{t('type:delete_type_assigned')}</div>
          <p
            className="text-center mb-5"
            dangerouslySetInnerHTML={{ __html: t('type:delete_type_description_assigned') }}
          />
          <Button
            className="mb-2"
            type="submit"
            onClick={() => {
              const result = features.filter((item) => item.featureId === selectedID);
              const res = result[0];
              deleteAssignedPackageFeatureReq({
                featureId: res.featureId,
                membershipTypeId: id,
                type: res.type,
              }).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.reload();
                  }, 700);
                }
              });
            }}
          >
            {t('global:confirm')}
          </Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAssignedPackageFeature;
