import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { columnsAssignedTable, renderAssignedTable } from './assignedPackageFeature.utils';
import { ArrowLeft } from '../../../assets/icons/icons-arrow';
import './packageType.css';
import { getAuthReq } from '../../../api/auth/index';
import Header from '../../../components/header';
import Table from '../../../components/table';
import { IconTypes } from '../../../assets/icons/icons-sidebar';
import {
  ACL_READ_MEMBERSHIP_TYPE,
  ACL_UPDATE_MEMBERSHIP_TYPE,
  ACL_DELETE_MEMBERSHIP_TYPE,
} from '../../../utils/constants';
import Button from '../../../components/button';
import { InputFormik } from '../../../components/input';
import { Formik, Form, Field } from 'formik';
import { PackageTypeSchema, YesNo } from './packageType.utils';
import { detailPackageTypeReq } from '../../../api/packageType/detailPackageType';
import Dropdown from '../../../components/dropdown';
import IconLoading from '../../../assets/icons/loading.svg';
import AddAssignedPackageFeature from './modals/assignedFeature/addAssignedFeature';
import ShowAssignedPackageFeature from './modals/assignedFeature/showAssinedFeature';
import DeleteAssignedPackageFeature from './modals/assignedFeature/deleteAssignedFeature';

const PackageTypeDetail = () => {
  const { t } = useTranslation();
  let { id } = useParams();

  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [isAdd, setIsAdd] = useState();
  const [initialValue, setInitialValue] = useState();
  const [status, setStatus] = useState('loading');
  const [features, setFeatures] = useState();

  useEffect(() => {
    let isEdit, isDelete, isRead;
    getAuthReq()
      .then((res) => {
        isRead = res.permissions.some((item) => item.code === ACL_READ_MEMBERSHIP_TYPE);
        if (!isRead) window.location.href = '/dashboard';
        isEdit = res.permissions.some((item) => item.code === ACL_UPDATE_MEMBERSHIP_TYPE);
        setIsAdd(isEdit);
        isDelete = res.permissions.some((item) => item.code === ACL_DELETE_MEMBERSHIP_TYPE);
      })
      .finally(() => {
        setStatus('loading');
        detailPackageTypeReq(id)
          .then((res) => {
            setInitialValue({
              activated: res.activated ? YesNo[0] : YesNo[1],
              description: res.description,
              minAdult: res.minAdult,
              minChild: res.minChild,
              name: res.name,
              numberOfChildAllowed: res.numberOfChildAllowed,
              numberOfWalletAllowed: res.numberOfWalletAllowed,
              numberOfWithdrawalAllowed: res.numberOfWithdrawalAllowed,
              order: res.order,
              price: res.price,
              summary: res.summary,
              type: { label: res.type, value: res.type },
            });
            const sortedbyOrderFeatures = res.features.sort((a, b) => (a.order < b.order ? 1 : -1));
            const sortedFeatures = sortedbyOrderFeatures.sort((a, b) =>
              a.featureDomain > b.featureDomain ? 1 : -1,
            );
            setFeatures(sortedFeatures);
            setTableData(
              renderAssignedTable(
                res.features,
                setAddModal,
                setDeleteModal,
                setSelectedID,
                isEdit,
                isDelete,
              ),
            );
          })
          .finally(() => {
            setDataStatus('done');
            setStatus('done');
          });
      });
  }, [id]);

  const AssignedTableHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="o-title  mb-4">Fonctionnalités Forfaits attribuées</h1>
        {isAdd && (
          <Button
            className="mb-4"
            onClick={() => {
              setAddModal(true);
              setSelectedID && setSelectedID(null);
            }}
          >
            +&nbsp;{t('global:add')}
          </Button>
        )}
      </div>
    );
  };
  return (
    <div>
      <Header
        headerTitle={t('sidebar:types')}
        HeaderIcon={IconTypes}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setSelectedID={setSelectedID}
        isAdd={false}
        disabledSearch
      />

      <div className="o-content">
        <div
          className="o-breadcrumb"
          onClick={() => {
            window.location.replace('/dashboard/package-types');
          }}
        >
          <ArrowLeft className="mr-1" />
          Retour
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValue}
          validationSchema={PackageTypeSchema}
        >
          {() => (
            <Form>
              {status === 'loading' ? (
                <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
              ) : (
                <div className="form-detail">
                  <div>
                    <Field
                      name="name"
                      className="mt-5"
                      label={t('type:name')}
                      placeholder={t('type:name_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="type"
                      label={t('type:type')}
                      placeholder={t('type:type_placeholder') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="order"
                      className="mt-5"
                      label={t('type:order')}
                      placeholder={t('type:order_placeholder') + ' *'}
                      component={InputFormik}
                      type="number"
                      onlyRead
                    />
                    <Field
                      name="activated"
                      label={t('type:activated')}
                      placeholder={t('type:activated_placeholder') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="price"
                      className="mt-5"
                      label={t('type:price')}
                      placeholder={t('type:price_placeholder') + ' *'}
                      component={InputFormik}
                      type="number"
                      onlyRead
                    />
                    <Field
                      name="description"
                      className="mt-5"
                      label={t('type:description')}
                      placeholder={t('type:description_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                  </div>
                  <div>
                    <Field
                      name="summary"
                      className="mt-5"
                      label={t('type:summary')}
                      placeholder={t('type:summary_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="minAdult"
                      className="mt-5"
                      label={t('type:minAdults')}
                      placeholder={t('type:minAdults_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="minChild"
                      className="mt-5"
                      label={t('type:minChildren')}
                      placeholder={t('type:minChildren_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="numberOfChildAllowed"
                      className="mt-5"
                      label={t('type:maxNbChildren')}
                      placeholder={t('type:maxNbChildren_placeholder')}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="numberOfWalletAllowed"
                      className="mt-5"
                      label={t('type:maxNbWallets')}
                      placeholder={t('type:maxNbWallets_placeholder')}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="numberOfWithdrawalAllowed"
                      className="mt-5"
                      label={t('type:maxNbWithdrawals')}
                      placeholder={t('type:maxNbWithdrawals_placeholder')}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        <Table
          columns={columnsAssignedTable}
          tableData={tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          contain
          ContainHeader={AssignedTableHeader}
        />
        {/*Add and edit assigned package feature*/}
        {addModal && (
          <AddAssignedPackageFeature
            addModal={addModal}
            setAddModal={setAddModal}
            selectedID={selectedID}
            features={features}
            id={id}
          />
        )}
        {/*show assigned package feature*/}
        {showModal && (
          <ShowAssignedPackageFeature
            showModal={showModal}
            setShowModal={setShowModal}
            selectedID={selectedID}
            features={features}
          />
        )}
        {deleteModal && (
          <DeleteAssignedPackageFeature
            selectedID={selectedID}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            features={features}
            id={id}
          />
        )}
      </div>
    </div>
  );
};

export default PackageTypeDetail;
