import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
//import { setKYCReview } from '../../../../api/client/setKYCReview';
import { clientReviewKycLivenessReq } from '../../../../api/client/kycliveness';
import IconLoading from '../../../../assets/icons/loading.svg';
import IconCheck from '../../../../assets/icons/check.svg';
import IconCross from '../../../../assets/icons/cross.svg';

// Ancien système de KYC Review
const ConfirmationSubmitKYCReview = ({ setKycReviewOpen, setSetKycReviewOpen, userId }) => {

  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');

  const close = () => {
    setSetKycReviewOpen(false);
  }

  const setKYCReviewRequest = async () => {
    setLoading(true);
    let data = {old_kyc_review : 'true'};
    const res = await clientReviewKycLivenessReq(userId, true);
    setResponseText(res);
    setLoading(false);
  }

  const ShowResponseText = () => (
    <div style={{textAlign: 'center', margin: "20px auto 30px auto"}}>
      {responseText} <br />
      {
      responseText === 'success' ?
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCheck} alt="" /> :
        <img className="d-flex mx-auto" style={{marginTop: "20px"}} src={IconCross} alt="" />
      }
    </div>
  );

  return (
    <Modal isOpen={setKycReviewOpen} toggle={setSetKycReviewOpen}>
      <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Déclencher Review KYC</div>
          {
          loading ?
            <img className="d-flex mx-auto" style={{ margin: "20px auto 30px auto" }} src={IconLoading} height="25" alt="" /> :
            responseText.length ? <ShowResponseText /> : ''
          }
          {
            !responseText.length ?
            <Button
              className="mb-2 mt-2"
              onClick={setKYCReviewRequest}
              type="submit"
            >Confirmer</Button> :
            ''
          }

          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Retour</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationSubmitKYCReview;
