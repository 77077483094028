import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientBeneficiaryDeleteReq } from '../../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../../assets/icons/loading.svg';

const ConfirmationDeleteBeneficiary = ({ deleteBeneficiaryOpen, setDeleteBeneficiaryOpen, userId, beneficiaryId, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id, beneficiaryId) => {
    setLoading(true);
    let status = await clientBeneficiaryDeleteReq(id, beneficiaryId);
    setLoading(false);
    setDeleteBeneficiaryOpen(false)
    if (status === 'success') {
      refreshData()
    }
  }

  const DeleteBeneficiaryInitialValues = {
  };
  const DeleteBeneficiarySchema = Yup.object().shape({
  });

  return (
    <Modal isOpen={deleteBeneficiaryOpen} toggle={setDeleteBeneficiaryOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={DeleteBeneficiaryInitialValues}
      validationSchema={DeleteBeneficiarySchema}
      onSubmit={(values) => {
        console.log('Delete client submit');
        deleteHandler(userId, beneficiaryId);
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Pour confirmer la suppression du beneficiaire</div>
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setDeleteBeneficiaryOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteBeneficiary;
