import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from '../../../components/header';
import { InputFormik } from '../../../components/input';
import Dropdown from '../../../components/dropdown';
import Button from '../../../components/button';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconLoading from '../../../assets/icons/loading.svg';
import {
  reportIncomes, reportIncomesGroupByBillingProduct,
  reportValidatedUsers, reportHistoryUsersStatusHistory
} from '../../../api/reports/reports';

import {  Chart as ChartJS,  CategoryScale,  LinearScale,  PointElement,  LineElement,
  BarElement,  Title,  Tooltip,  Legend } from 'chart.js';
import { Line, Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,
  BarElement, Title, Tooltip, Legend);
export const GroupDateType = [
  { label: "DAY", value: "DAY" },
  { label: "WEEK", value: "WEEK" },
  { label: "MONTH", value: "MONTH" },
  { label: "QUARTER", value: "QUARTER" },
  { label: "YEAR", value: "YEAR" },
];

const incomesData = (res) => {
  // Labels de l'abscisse
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.incomeDate)
  );
  // données du CA TTC
  let dataCA = [];
  res.map((item) =>
    dataCA.push(item.amount)
  );
  // données du Facturation TTC
  let dataBilling = [];
  res.map((item) =>
    dataBilling.push(item.credit ? item.credit : 0)
  );
  // données du Remboursement TTC
  let dataRefund = [];
  res.map((item) =>
    dataRefund.push(item.debit ? item.debit : 0)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: 'CA en € TTC',
      data: dataCA,
      fill: true,
      tension: 0.2,
      backgroundColor: '#00BD86',
      borderColor: '#00BD86',
      borderWidth: 1,
      hidden: true,
    },
    {
      label: 'Facturation en € TTC',
      data: dataBilling,
      fill: true,
      tension: 0.2,
      backgroundColor: '#a4e2a9',
      borderColor: '#a4e2a9',
      borderWidth: 1,
    },
    {
      label: 'Remboursement en € TTC',
      data: dataRefund,
      fill: true,
      tension: 0.2,
      backgroundColor: '#FF5858',
      borderColor: '#FF5858',
      borderWidth: 1,
    },
  ],
  };
  return result;
};

const incomesDataByBillingProduct = (res) => {
  // Labels de l'abscisse
  let dataLabels = new Set();
  res.map((item) =>
    dataLabels.add(item.incomeDate)
  );

  // Liste des produits facturés
  let billingProducts = new Set();
  res.map((item) =>
    billingProducts.add(item.billingProduct ? item.billingProduct : 'OTHER')
  );

  // Données
  let datas = [];
  res.map((item) =>
    datas.push(item.amount ? item.amount : 0)
  );
  let dataSets = [];

  let colorSet = ['#a4e2a9', '#8bda8f', '#6ed175', '#54c95d', '#00BD86', '#007739','#005237'];
  let indexColor = 0;
  billingProducts.forEach((billingProduct) => {
    const backgroundColor = colorSet[indexColor++];

    const dataBillingProductByDate = new Map();
    res.map(item => {
      if(item.billingProduct === undefined && billingProduct === 'OTHER') {
        dataBillingProductByDate.set(item.incomeDate, item.amount ? item.amount : 0);
      } else if (item.billingProduct === billingProduct) {
        dataBillingProductByDate.set(item.incomeDate, item.amount ? item.amount : 0);
      }
    })

    let dataBillingProduct = [];
    dataLabels.forEach((dataLabel, i) => {
      let dataLabelValue = dataBillingProductByDate.get(dataLabel);
      dataBillingProduct.push(dataLabelValue ? dataLabelValue : 0)
    });
    let dataSet = {
        label: billingProduct,
        data: dataBillingProduct,
        fill: true,
        tension: 0.2,
        backgroundColor: backgroundColor,
        borderColor: '#00BD86',
        borderWidth: 1,
        stack: 'stack_0',
      };
      dataSets.push(dataSet);
  });

  let result = {
    labels: [...dataLabels],
    datasets: dataSets,
  };
  return result;
};

const validatedUsersData = (res) => {
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.date ? item.date : item.groupDate)
  );
  let dataUsersValidated = [];
  res.map((item) =>
    dataUsersValidated.push(item.total_count ? item.total_count : item.amount)
  );
  let dataUsersActifs = [];
  res.map((item) =>
    dataUsersActifs.push(item.count_validated)
  );
  let dataUsersChurn = [];
  res.map((item) =>
    dataUsersChurn.push(item.count_churn)
  );
  let result = {
    labels: dataLabels,
    datasets: [
      {
        label: 'Clients Validés Perdus',
        data: dataUsersChurn,
        fill: false,
        tension: 0.2,
        backgroundColor: '#FF9A9A33',
        borderColor: '#FF5858',
        borderWidth: 1,
      },
      {
        label: 'Clients Validés encore Actif',
        data: dataUsersActifs,
        fill: false,
        tension: 0.2,
        backgroundColor: '#B3F4E133',
        borderColor: '#00BD86',
        borderWidth: 1,
      },
      {
        label: 'Clients Validés Total',
        data: dataUsersValidated,
        fill: false,
        tension: 0.2,
        backgroundColor: '#00880033',
        borderColor: '#008800',
        borderWidth: 1, hidden: true,
      },
    ],
  };
  return result;
};

const validatedUsersDataForStack = (res) => {
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.date)
  );
  let dataUsersValidated = [];
  res.map((item) =>
    dataUsersValidated.push(item.total_count)
  );
  let dataUsersActifs = [];
  res.map((item) =>
    dataUsersActifs.push(item.count_validated)
  );
  let dataUsersChurn = [];
  res.map((item) =>
    dataUsersChurn.push(item.count_churn)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: 'Clients Validés Perdus',
      data: dataUsersChurn,
      fill: true,
      tension: 0.2,
      backgroundColor: '#FF5858',
      borderColor: '#FF5858',
      borderWidth: 1,
      stack: 'Stack 0',
    },
    {
      label: 'Clients Validés encore Actif',
      data: dataUsersActifs,
      fill: true,
      tension: 0.2,
      backgroundColor: '#00BD86',
      borderColor: '#00BD86',
      borderWidth: 1,
      stack: 'Stack 0',
    },
    // {
    //   label: 'Clients Validés Total',
    //   data: dataUsersValidated,
    //   fill: false,
    //   tension: 0.2,
    //   backgroundColor: '#008800',
    //   borderColor: '#008800',
    //   borderWidth: 1,
    //   stack: 'Stack 1',
    // },
  ],
  };
  return result;
};
const historyStatusUsersDataForStack = (res) => {
  //{"kyc_reviewing":45,"canceled":11,"kyc_review_ask_more":2,"onboarding_waiting_payin":7,"validated":34,"disable":0,"onboarding":453,"kyc_review_refused":10,"none":110,"kyc_review_ask_more_warning":1,"onboarding_finalized":175}
  let dataLabels = [];
  res.map((item) =>
    dataLabels.push(item.date)
  );
  let dataUsersHistoryValidated = [];
  res.map((item) =>
    dataUsersHistoryValidated.push(item.status_count.validated)
  );
  let dataUsersHistoryOnboarding = [];
  res.map((item) =>
    dataUsersHistoryOnboarding.push(item.status_count.onboarding)
  );
  let dataUsersHistoryOnboardingFinalized = [];
  res.map((item) =>
    dataUsersHistoryOnboardingFinalized.push(item.status_count.onboarding_finalized)
  );
  let dataUsersHistoryOnboardingWaitPayin = [];
  res.map((item) =>
    dataUsersHistoryOnboardingWaitPayin.push(item.status_count.onboarding_waiting_payin)
  );
  let dataUsersHistoryKycReviewing = [];
  res.map((item) =>
    dataUsersHistoryKycReviewing.push(item.status_count.kyc_reviewing)
  );
  let dataUsersHistoryKycReviewAskMore = [];
  res.map((item) =>
    dataUsersHistoryKycReviewAskMore.push(item.status_count.kyc_review_ask_more)
  );
  let dataUsersHistoryKycReviewRefused = [];
  res.map((item) =>
    dataUsersHistoryKycReviewRefused.push(item.status_count.kyc_review_refused)
  );
  let dataUsersHistoryCanceled = [];
  res.map((item) =>
    dataUsersHistoryCanceled.push(item.status_count.canceled)
  );
  let result = {
  labels: dataLabels,
  datasets: [
    {
      label: 'CANCELED', data: dataUsersHistoryCanceled,
      fill: true, tension: 0.2, backgroundColor: '#21252a', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0', hidden: true,
    },
    {
      label: 'KYC_REVIEW_REFUSED', data: dataUsersHistoryKycReviewRefused,
      fill: true, tension: 0.2, backgroundColor: '#ff0000', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'ONBOARDING', data: dataUsersHistoryOnboarding,
      fill: true, tension: 0.2, backgroundColor: '#a4e2a9', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'ONBOARDING_FINALIZED', data: dataUsersHistoryOnboardingFinalized,
      fill: true, tension: 0.2, backgroundColor: '#8bda8f', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'ONBOARDING_WAITING_PAYIN', data: dataUsersHistoryOnboardingWaitPayin,
      fill: true, tension: 0.2, backgroundColor: '#6ed175', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'KYC_REVIEWING', data: dataUsersHistoryKycReviewing,
      fill: true, tension: 0.2, backgroundColor: '#54c95d', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'KYC_REVIEW_ASK_MORE', data: dataUsersHistoryKycReviewAskMore,
      fill: true, tension: 0.2, backgroundColor: '#F8932A', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
    {
      label: 'VALIDATED', data: dataUsersHistoryValidated,
      fill: true, tension: 0.2, backgroundColor: '#00BD86', borderColor: '#FFFFFF', borderWidth: 1, stack: 'Stack 0',
    },
  ],
  };
  return result;
};

const emptyData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      borderWidth: 1,
    },
  ],
};

const optionsIncomes = {
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = ' ';

                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
    }
};

const optionsValidatedUsers = {
    plugins: {
      title: {
        display: true,
        text: 'Répartition des clients en fonction de leur date de validation',
      },
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label = ' ';

                  if (context.parsed.y !== null) {
                      label += context.parsed.y + ' Clients';
                  }
                  return label;
              }
          }
      }
    }
};

const optionsValidatedUsersForStack = {
  plugins: {
    title: {
      display: true,
      text: 'Répartition des clients en fonction de leur date de validation',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const optionsHistoryUsersForStack = {
  plugins: {
    title: {
      display: true,
      text: 'Historique du nombre de client en fonction de leur statut',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const BusinessReports = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [incomesChartData, setIncomesChartData] = useState(emptyData);
  const [incomesChartLoading, setIncomesChartLoading] = useState(false);
  const [incomesChartBillingProductData, setIncomesChartBillingProductData] = useState(emptyData);
  const [incomesChartBillingProductLoading, setIncomesChartBillingProductLoading] = useState(false);
  const [validatedUsersChartData, setValidatedUsersChartData] = useState(emptyData);
  const [validatedUsersChartDataForStack, setValidatedUsersChartDataForStack] = useState(emptyData);
  const [validatedUsersChartLoading, setValidatedUsersChartLoading] = useState(false);
  const [historyUsersChartDataForStack, setHistoryUsersChartDataForStack] = useState(emptyData);
  const [historyUsersChartLoading, setHistoryUsersChartLoading] = useState(false);

  const FilterInitialValues = {
    startDate: '',
    endDate: '',
    filter: GroupDateType[2],
  };
  const FilterSchema = Yup.object().shape({
  });

  useEffect(() => {
    searchHandler({});
  }, []);

  const searchHandler = async (values) => {
    let data = {
      startDate: values.startDate ? values.startDate : '' ,
      endDate: values.endDate ? values.endDate : '' ,
      filter: values.filter?.value ? values.filter?.value : 'MONTH' ,
    }
    setSearchLoading(true);
    setValidatedUsersChartLoading(true);
    reportValidatedUsers(data)
        .then((res) => {
          //console.log(res);
          setValidatedUsersChartData(validatedUsersData(res));
          setValidatedUsersChartDataForStack(validatedUsersDataForStack(res));
        })
        .finally(() => setValidatedUsersChartLoading(false));

    setIncomesChartLoading(true);
    reportIncomes(data)
        .then((res) => {
          //console.log(res);
          setIncomesChartData(incomesData(res));
        })
        .finally(() => setIncomesChartLoading(false));

    setIncomesChartBillingProductLoading(true);
    reportIncomesGroupByBillingProduct(data)
        .then((res) => {
          setIncomesChartBillingProductData(incomesDataByBillingProduct(res));
        })
        .finally(() => setIncomesChartBillingProductLoading(false));

    setHistoryUsersChartLoading(true);
    reportHistoryUsersStatusHistory(data)
        .then((res) => {
          //console.log(res);
          setHistoryUsersChartDataForStack(historyStatusUsersDataForStack(res));
        })
        .finally(() => setHistoryUsersChartLoading(false));

    setSearchLoading(false);
  }

  function HeaderTitle() {
    return (
      <Fragment>
        Business Reports
      </Fragment>
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={FilterInitialValues}
        validationSchema={FilterSchema}
        onSubmit={(values) => {
          searchHandler(values)
        }}
      >
        <Form className="form-inline mt-5 ml-5">
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <Field
                    name="startDate"
                    className="mb-2 ml-5"
                    label='Début (YYYY-MM-DD)'
                    placeholder='Début (YYYY-MM-DD)'
                    component={InputFormik}
                  />
              </div>
              <div className="col-sm">
                <Field
                      name="endDate"
                      className="mb-2 ml-5"
                      label='Fin (YYYY-MM-DD)'
                      placeholder='Fin (YYYY-MM-DD)'
                      component={InputFormik}
                    />
              </div>
              <div className="col-sm">
                <Field
                  name="filter"
                  label=""
                  placeholder="Filtre de groupement de date"
                  component={Dropdown}
                  options={GroupDateType}
                  width={800}
                />
              </div>
              <div className="col-sm">
                  <Button
                    className="mb-2 ml-5"
                    type="submit"
                  >{searchLoading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Rechercher'}</Button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <Header headerTitle={HeaderTitle()} HeaderIcon={IconClient} isAdd={false} disabledSearch/>
      <div className="d-flex o-content justify-content-center">
        <div>
        {
          validatedUsersChartLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Line width={600} height={400} data={validatedUsersChartData} options={optionsValidatedUsers}/>
        }
        </div>
        <div>
        {
          validatedUsersChartLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Bar width={600} height={400} data={validatedUsersChartDataForStack} options={optionsValidatedUsersForStack}/>
        }
        </div>
      </div>
      <div className="d-flex o-content justify-content-center">
        <div>
        {
          incomesChartLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Line width={600} height={400} data={incomesChartData} options={optionsIncomes}/>
        }
        </div>
        <div>
        {
          incomesChartBillingProductLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Bar width={600} height={400} data={incomesChartBillingProductData} options={optionsIncomes}/>
        }
        </div>
      </div>
      <div className="d-flex o-content justify-content-center">
        <div>
        {
          historyUsersChartLoading ? <img className="mr-2" src={IconLoading} height="25" alt="" /> :
          <Bar width={1200} height={400} data={historyUsersChartDataForStack} options={optionsHistoryUsersForStack}/>
        }
        </div>
      </div>
    </div>
  );
};

export default BusinessReports;
