import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientTrustLevel } from '../../../../api/client/updateClient';
import { trustLevelsReq } from '../../../../api/config/config';
import IconLoading from '../../../../assets/icons/loading.svg';

const ConfirmationUpdateTrustLevel = ({ updateTrustLevelOpen, setUpdateTrustLevelOpen, userData, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [trustLevelData, setTrustLevelData] = useState([]);
  const [currentItem, setCurrentItem] = useState();

  const updateTrustLevelHandler = async (id, values) => {
    setLoading(true);
    let reqParams = {"trust_level_id" : values.trust_level?.value};
    let status = await updateClientTrustLevel(id, reqParams);
    setLoading(false);
    setUpdateTrustLevelOpen(false)
    if(status === 'success'){
      refreshData();
    }
  }

  useEffect(() => {
    /*Get trustlevel list*/
    trustLevelsReq()
      .then((res) => {
        let trustLevelValues = [];
        res.map((item) => trustLevelValues.push({ value: item.trust_level_id, label: item.trust_level_id + '-' + item.description + ' (Alerte > ' + item.alert_payin_limit +'€)'}));
        setTrustLevelData(trustLevelValues);
      });




  }, []);

  const UpdateTrustLevelInitialValues = (data) => {
    return {
      trust_level: data ? { value: data.trust_level?.trust_level_id , label: data.trust_level?.description } : {},
    }
  };
  const UpdateTrustLevelSchema = Yup.object().shape({
    //trust_level: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateTrustLevelOpen} toggle={setUpdateTrustLevelOpen}>
    <Formik
      enableReinitialize={true}
      initialValues={UpdateTrustLevelInitialValues(userData)}
      validationSchema={UpdateTrustLevelSchema}
      onSubmit={(values) => {
        console.log('Mise à jour du code promo');
        updateTrustLevelHandler(userData.userId, values).then((res) => {
          if (res === 'success') {
            setUpdateTrustLevelOpen(false)
          }
        });
      }}
    >
      <Form>
        <div>
        <div className="d-flex flex-column ">
          <div className="o-title text-center mb-4">Modification du niveau de confiance du client</div>
          <Field
              name="trust_level"
              label=""
              placeholder="Niveau de confiance"
              component={Dropdown}
              options={trustLevelData}
            />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={() => setUpdateTrustLevelOpen(false)}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateTrustLevel;
