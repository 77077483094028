import React, {useState} from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../assets/icons/loading.svg';
import { activateCardReq } from '../../../../../api/client/card/card';

const ConfirmationActivateCard = ({ userID, card, activateCardModal, setActivateCardModal, refreshData }) => {

  const [loading, setLoading] = useState(false);

  const changeActivateHandler = async (data) => {
    setLoading(true);
    let status = await activateCardReq(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  }

  const close = () => {
    setActivateCardModal(false);
  }

  const ActivateCardInitialValues = {
  };
  const ActivateCardSchema = Yup.object().shape({
    public_token: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={activateCardModal} toggle={setActivateCardModal}>
    <Formik
      enableReinitialize={true}
      initialValues={ActivateCardInitialValues}
      validationSchema={ActivateCardSchema}
      onSubmit={(values) => {
        changeActivateHandler(values);
      }}
    >
      <Form>
      <div>
        <div className="d-flex flex-column ">
        <div className="o-title text-center mb-4">ATTENTION CETTE OPERATION EST IRREVERSIBLE</div>
        <div className="o-title text-center mb-4">Veuillez confirmer en saisissant le public token de la carte.</div>
          <Field
            name="public_token"
            className="mt-5"
            label="Public Token de la carte *"
            placeholder="Public Token de la carte *"
            component={InputFormik}
          />
          <Button
            className="mb-2"
            type="submit"
          >{loading ? <img className="d-flex mx-auto" src={IconLoading} height="25" alt="" /> : 'Confirm'}</Button>
          <Button
            btnType="outline"
            type="button"
            onClick={close}
          >Cancel</Button>
        </div>
      </div>
      </Form>
    </Formik>
    </Modal>
  );
};

export default ConfirmationActivateCard;
