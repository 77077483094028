import React, {  useEffect, useContext } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';
import Header from '../../../components/header';

import GaeServiceStatus from '../../../components/supervision/gae_service';

import './status.css';

import {
  IconMeter,
} from '../../../assets/icons/icons-sidebar.jsx';

const StatusCenter = () => {
  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {

  }, []);

  return (
<div>
  <Header
    headerTitle="Services Status"
    HeaderIcon={IconMeter}
    disabledSearch={true}
  />
   {permContext?.adminRead && (
     <div className="row mt-5 ml-5">
        <GaeServiceStatus serviceId="default" imageUrl="https://static.onlyonecard.io/images/backend/services/onlyone_120.png"/>
        <GaeServiceStatus serviceId="jarvis" imageUrl="https://static.onlyonecard.io/images/backend/services/jarvis_32.jpeg"/>
        <GaeServiceStatus serviceId="friday" imageUrl="https://static.onlyonecard.io/images/backend/services/friday_64.jpg"/>
        <GaeServiceStatus serviceId="cerebro" imageUrl="https://static.onlyonecard.io/images/backend/services/cerebro_64.jpeg"/>
        <GaeServiceStatus serviceId="notification-center" imageUrl="https://static.onlyonecard.io/images/backend/services/pow_120.png"/>
        <GaeServiceStatus serviceId="smaug" imageUrl="https://static.onlyonecard.io/images/backend/services/smaug_64.jpg"/>
        <GaeServiceStatus serviceId="sidekick" imageUrl="https://static.onlyonecard.io/images/backend/services/sidekick_64.png"/>
        <GaeServiceStatus serviceId="sauron" imageUrl="https://static.onlyonecard.io/images/backend/services/sauron_64.jpeg"/>
        <GaeServiceStatus serviceId="scrooge" imageUrl="https://static.onlyonecard.io/images/backend/services/scrooge_64.jpeg"/>
    </div>
  )}
</div>
  );
};

export default StatusCenter;
