import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { DocumentSchema, DocumentInitialValues, YesNo } from '../documenttype.utils';
import { detailDocumentReq } from '../../../../api/documenttype/documenttype';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../assets/icons/loading.svg';

const ShowDocument = ({ showModal, setShowModal, selectedID, config }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [status, setStatus] = useState('loading');
  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      detailDocumentReq(selectedID)
        .then((res) => {
          setInitialValue({
            name: res.name,
            activated: res.activated ? YesNo[0] : YesNo[1],
            rectoVerso: res.rectoVerso ? YesNo[0] : YesNo[1],
            order: res.order,
            level: { value: res.level, label: res.level },
            trzDocumentType:
              config.trzDocumentType &&
              res.trzDocumentType &&
              config.trzDocumentType.filter((trz) => trz.value === res.trzDocumentType)[0],
            vialinkDocumentType:
              config.vialinkDocumentType &&
              res.vialinkDocumentType &&
              config.vlDocumentType.filter((trz) => trz.value === res.vialinkDocumentType)[0],
          });
        })
        .finally(() => {
          setStatus('done');
        });
    }
  }, [selectedID]);
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : DocumentInitialValues}
        validationSchema={DocumentSchema}
      >
        {() => (
          <Form>
            <div className="o-title text-center">{t('document:detail_document')}</div>
            {status === 'loading' ? (
              <img className="d-flex mx-auto my-4" src={IconLoading} height="40" alt="" />
            ) : (
              <Fragment>
                <Field
                  name="name"
                  className="mt-5"
                  label={t('document:name')}
                  placeholder={t('document:name_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />

                <Field
                  name="activated"
                  label={t('document:activated')}
                  placeholder={t('document:activated') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <Field
                  name="order"
                  className="mt-5"
                  label={t('document:order')}
                  placeholder={t('document:order_placeholder') + ' *'}
                  component={InputFormik}
                  type="number"
                  onlyRead
                />
                <Field
                  name="level"
                  label={t('document:level')}
                  placeholder={t('document:level') + ' *'}
                  component={Dropdown}
                  type="number"
                  onlyRead
                />
                <Field
                  name="rectoVerso"
                  label={t('document:both_side')}
                  placeholder={t('document:both_side') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <Field
                  name="trzDocumentType"
                  label={t('document:treezor')}
                  placeholder={t('document:treezor') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <Field
                  name="vialinkDocumentType"
                  label={t('document:vialink')}
                  placeholder={t('document:vialink') + ' *'}
                  component={Dropdown}
                  onlyRead
                />
                <div className="d-flex flex-column mt-5">
                  <Button
                    btnType="outline"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ShowDocument;
